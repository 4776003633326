import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataTransferObject } from "../../core/models/data-transfer-object";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { LOCALIZATION_DICTIONARY } from "../../i18n/models/localization-dictionary";
import { Configurable, ConfigurableEnum, ConfigurationCategory } from "../../meta/decorators";
import { DynamicallyVisible } from "../../meta/decorators/dynamically-visible.decorator";
import { EditableType } from "../../meta/decorators/editable-type.decorator";
import { Serializable } from "../../meta/decorators/serializable.decorator";
import { EditorType } from "../../meta/models/editor-type";
import { PropertyCategory } from "../../meta/models/property-category";
import { SelectionOption } from "../../meta/models/selection";
import { ValidationContext } from "../../meta/models/validation-context";
import { CSS_SIZE, CSS_SIZE_REGEX, cssSizeParser } from "./component-size.constants";
import { isContainerWidget } from "./component-type.helper";

export const DEFAULT_COMPONENT_WIDTH = 250;
export const DEFAULT_COMPONENT_HEIGHT = 150;

export enum SizeType {
  Fill = "Fill",
  Hug = "Hug",
  Fixed = "Fixed"
}
@EditableType({ fullName: CSS_SIZE, title: "component-css-size" })
export class ComponentCssSize implements DataTransferObject {
  typeName: string = CSS_SIZE;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    1
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Width,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateWidth,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CssWidthTooltip
  })
  @DynamicallyVisible(selectWidthSizeType, [SizeType.Fixed])
  @Serializable()
  public width: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    3
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.Height,
    editorType: EditorType.TextBox,
    advancedMode: true,
    validationFunction: validateHeight,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CssHeightTooltip
  })
  @DynamicallyVisible(selectHeightSizeType, [SizeType.Fixed])
  @Serializable()
  public height: string;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    0
  )
  @ConfigurableEnum({
    enumSource: getSizeTypes,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.WidthType,
    advancedMode: true
  })
  @DynamicallyVisible(
    (context: any) => context.services.environmentSelector.selectTemplateBuilderMode(),
    [false]
  )
  @Serializable(SizeType.Fixed)
  widthType!: SizeType;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    2
  )
  @ConfigurableEnum({
    enumSource: getSizeTypes,
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HeightType,
    advancedMode: true
  })
  @DynamicallyVisible(
    (context: any) => context.services.environmentSelector.selectTemplateBuilderMode(),
    [false]
  )
  @Serializable(SizeType.Fixed)
  heightType!: SizeType;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    1
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.WidthFactor,
    editorType: EditorType.Number,
    advancedMode: true
  })
  @DynamicallyVisible(selectWidthSizeType, [SizeType.Fill])
  @Serializable(1)
  public widthFactor!: number;

  @ConfigurationCategory(
    PropertyCategory.Display,
    LOCALIZATION_DICTIONARY.propertySheet.SizeAndPosition,
    3
  )
  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.HeightFactor,
    editorType: EditorType.Number,
    advancedMode: true,
    tooltipKey: LOCALIZATION_DICTIONARY.propertySheet.CssHeightTooltip
  })
  @DynamicallyVisible(selectHeightSizeType, [SizeType.Fill])
  @Serializable(1)
  public heightFactor!: number;

  constructor(
    width: string = DEFAULT_COMPONENT_WIDTH.toString(),
    height: string = DEFAULT_COMPONENT_HEIGHT.toString()
  ) {
    this.width = width;
    this.height = height;
  }
}

export function validateHeight(height: string, _validationContext?: ValidationContext): boolean {
  return CSS_SIZE_REGEX.test(height) && cssSizeParser(height).value > 0;
}

export function validateWidth(width: string, _validationContext?: ValidationContext): boolean {
  return CSS_SIZE_REGEX.test(width) && cssSizeParser(width).value > 0;
}

export function getSizeTypes(context: DecoratorDelegateContext): SelectionOption[] {
  const keys = isContainerWidget(context.ownerInstance.type)
    ? Object.keys(SizeType)
    : Object.keys(SizeType).filter((x) => x !== SizeType.Hug);

  return keys.map((key) => ({
    key,
    title: context.services.localizationService.propertySheet[key]
  }));
}

export function selectWidthSizeType(context: DecoratorDelegateContext): Observable<SizeType> {
  return context.services.componentStateSelector
    .selectComponentStateById(context.ownerInstance.id)
    .pipe(map((component) => component!.view.size.widthType));
}

export function selectHeightSizeType(context: DecoratorDelegateContext): Observable<SizeType> {
  return context.services.componentStateSelector
    .selectComponentStateById(context.ownerInstance.id)
    .pipe(map((component) => component!.view.size.heightType));
}

import { Directive, ElementRef } from "@angular/core";
import { DraggedItemType } from "../../core/models/drag/dragged-item-type";
import { ComponentStateDto } from "../../elements/models/component-state";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { BaseTemplateDirective } from "./base-template.directive";

@Directive({
  selector: "[equipmentDrop]"
})
export class EquipmentDropDirective extends BaseTemplateDirective {
  constructor(protected element: ElementRef<HTMLElement>) {
    super();
  }

  init(componentState: ComponentStateDto, params: any): void {
    this.dragDropService = params.dragDropService;
    this.dropHelper = params.dropHelper;
    this.componentState = componentState;
    this.element.nativeElement.addEventListener("drop", () => this.onDrop());
    this.element.nativeElement.addEventListener("dragover", (event: DragEvent) =>
      this.onDragOver(event)
    );
  }

  onDrop(): void {
    if (this.isEquipmentData() && isDefined(this.componentState)) {
      this.dropHelper?.dropEquipmentOnPropertySheet(
        this.dragDropService?.target,
        this.componentState
      );
    }
  }

  private isEquipmentData(): boolean {
    const target = this.dragDropService?.target;
    return (
      isDefined(target) &&
      target.type === DraggedItemType.Equipment &&
      isNotDefined(target.item.property)
    );
  }

  onDragOver(event: DragEvent): void {
    if (isDefined(this.componentState)) {
      event.preventDefault();
    }
  }
}

import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import Highcharts from "highcharts";
import { sum, uniq } from "lodash";
import moment from "moment";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Dictionary } from "../../../ts-utils/models/dictionary.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID
} from "../../services/highcharts/base-highcharts-options.helper";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ChartComponent } from "../chart/chart.component";
import { Roles } from "./roles";
import { JackKnifeChartViewConfig } from "./view-config";

interface DowntimeRecord {
  causedDowntimeInHours: number;
  interventionsNumber: number;
}
const ACUTE_PLOT_LINE_ID = "acuteLine";
const CHRONIC_PLOT_LINE_ID = "chronicLine";

@Component({
  selector: "jack-knife-chart",
  templateUrl: "./jack-knife-chart.component.html",
  styleUrls: ["./jack-knife-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: JackKnifeChartComponent }]
})
@LayoutBuilder(
  ComponentCategory.Category,
  "JackKnifeChartComponent",
  "Trend_scatter",
  "abb-icon",
  LOCALIZATION_DICTIONARY.layoutEditor.JackKnifeChart
)
@MaxConnectors(2)
@ConnectorRoles(Roles)
@EditableWidget({
  fullName: "JackKnifeChartComponent",
  title: "jack-knife-chart"
})
export class JackKnifeChartComponent extends ChartComponent {
  public Highcharts = Highcharts;
  interpolatedViewConfig: JackKnifeChartViewConfig;

  @View(JackKnifeChartViewConfig)
  public get view(): JackKnifeChartViewConfig {
    return this.currentState.view as JackKnifeChartViewConfig;
  }

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    cdr: ChangeDetectorRef,
    private valueFormatter: ValueFormatterService,
    private filterFactory: FilterFactory
  ) {
    super(params, hostElementRef, cdr);
  }

  protected updateChartData(): void {
    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<JackKnifeChartViewConfig>(
        this.currentState,
        this.dataAccessor.getAllConnectors()
      );
    this.interpolatedViewConfig = interpolatedProperties.viewConfig;
    this.resetChartData();

    const downtimeTypeConnector = this.dataAccessor.getConnectorByRole(Roles.DowntimeType.name);
    const downtimeDurationConnector = this.dataAccessor.getConnectorByRole(
      Roles.DowntimeDuration.name
    );

    if (isDefined(downtimeTypeConnector) && isDefined(downtimeDurationConnector)) {
      const downtimeRecordDict = prepareDowntimeRecordDict(
        downtimeTypeConnector,
        downtimeDurationConnector
      );
      this.chartObject?.addSeries(
        {
          type: "scatter",
          data: getScatterDowntimeData(downtimeRecordDict)
        },
        true,
        false
      );

      const totalCausedDowntime = sum(
        Object.values(downtimeRecordDict).map((record) => record.causedDowntimeInHours)
      );
      const totalInterventionsNumber = sum(
        Object.values(downtimeRecordDict).map((record) => record.interventionsNumber)
      );
      const numberOfCauses = Object.values(downtimeRecordDict).length;
      const acuteLimit = totalCausedDowntime / totalInterventionsNumber;
      const chronicLimit = totalInterventionsNumber / numberOfCauses;

      this.addLimitPlotlines(acuteLimit, chronicLimit);
      const currentAvailabilityLimit = acuteLimit * chronicLimit;
      this.addAvailabilityLines(currentAvailabilityLimit, totalCausedDowntime, numberOfCauses);
    }
  }

  private addLimitPlotlines(acuteLimit: number, chronicLimit: number): void {
    this.chartObject?.yAxis[0].addPlotLine({
      value: acuteLimit,
      id: ACUTE_PLOT_LINE_ID,
      color: "black",
      width: 2
    });
    this.chartObject?.xAxis[0].addPlotLine({
      value: chronicLimit,
      id: CHRONIC_PLOT_LINE_ID,
      color: "black",
      width: 2
    });
  }

  private addAvailabilityLines(
    currentAvailabilityLimit: number,
    totalCausedDowntime: number,
    numberOfCauses: number
  ): void {
    const dataMin = this.chartObject?.xAxis[0].getExtremes().dataMin ?? 0;
    const dataMax = this.chartObject?.xAxis[0].getExtremes().dataMax ?? 0;
    this.addAvailabilitySeries(dataMin, dataMax, currentAvailabilityLimit, "gray");

    const totalHoursObserved = this.getFilterTimerangeInHours();
    const currentAvailability =
      (100 * (totalHoursObserved - totalCausedDowntime)) / totalHoursObserved;

    if (currentAvailability < this.view.targetAvailability) {
      const targetDowntimeHours = (1 - this.view.targetAvailability / 100) * totalHoursObserved;
      const targetAvailibilityLimit = targetDowntimeHours / numberOfCauses;
      this.addAvailabilitySeries(dataMin, dataMax, targetAvailibilityLimit, "green");
    }
  }

  private getFilterTimerangeInHours(): number {
    const filter = this.filterFactory.createFilterFromConfiguration(this.filter);
    if (isDefined(filter)) {
      const fromDate = moment(filter.timeRange.from);
      const toDate = moment(filter.timeRange.to);
      const duration = moment.duration(toDate.diff(fromDate));
      return duration.asHours();
    }
    return 0;
  }

  protected addAvailabilitySeries(
    dataMin: number,
    dataMax: number,
    availability: number,
    lineColor: string
  ): void {
    this.chartObject?.addSeries(
      {
        type: "line",
        data: [
          [dataMin, availability / dataMin],
          [dataMax, availability / dataMax]
        ],
        color: lineColor,
        marker: { enabled: false },
        enableMouseTracking: false
      },
      true,
      false
    );
  }

  protected setChartOptions(): void {
    const viewConfig = this.interpolatedViewConfig;
    const valueFormatter = this.valueFormatter;
    const options: Highcharts.Options = {
      chart: {
        type: "scatter"
      },
      title: {
        text: this.interpolatedViewConfig.title ?? ""
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        scatter: {
          tooltip: {
            headerFormat: "<b>{point.point.custom.stopCause}</b><br/>",
            pointFormatter: function () {
              return getScatterTooltip(this, viewConfig, valueFormatter);
            }
          }
        }
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          type: "logarithmic",
          gridLineWidth: 1,
          labels: {
            formatter: function () {
              return valueFormatter.formatValue(Number(this.value), viewConfig.displayFormat);
            }
          },
          title: {
            text: "No. of occurrences"
          }
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          type: "logarithmic",
          labels: {
            formatter: function () {
              return valueFormatter.formatValue(Number(this.value), viewConfig.displayFormat);
            }
          },
          title: {
            text: "Intensity per downtime (Hours)"
          }
        }
      ]
    };

    this.mergeChartOptions(options);
  }

  private resetChartData(): void {
    if (isDefined(this.chartObject)) {
      this.chartObject?.yAxis[0].removePlotLine(ACUTE_PLOT_LINE_ID);
      this.chartObject?.xAxis[0].removePlotLine(CHRONIC_PLOT_LINE_ID);
      this.chartObject.update(
        {
          series: []
        },
        true,
        true,
        false
      );
    }
  }
}

function getScatterTooltip(
  chartPoint: Highcharts.Point,
  viewConfig: JackKnifeChartViewConfig,
  valueFormatter: ValueFormatterService
): string {
  return `No. of stops: ${valueFormatter.formatValue(
    chartPoint.x,
    viewConfig.displayFormat
  )}<br/> Avg. downtime per stop: ${valueFormatter.formatValue(
    chartPoint.y ?? 0,
    viewConfig.displayFormat
  )} hours`;
}

function getScatterDowntimeData(
  downtimeRecordDict: Dictionary<DowntimeRecord>
): Highcharts.PointOptionsObject[] {
  return Object.keys(downtimeRecordDict).map((stopCause) => ({
    x: downtimeRecordDict[stopCause].interventionsNumber,
    y:
      downtimeRecordDict[stopCause].causedDowntimeInHours /
      downtimeRecordDict[stopCause].interventionsNumber,
    custom: { stopCause }
  }));
}

function prepareDowntimeRecordDict(
  downtimeTypeConnector: DataConnectorDto,
  downtimeDurationConnector: DataConnectorDto
): Dictionary<DowntimeRecord> {
  const dict: Dictionary<DowntimeRecord> = {};
  uniq<string>(downtimeTypeConnector.dataPoints?.map((point) => point.y)).forEach(
    (downtimeCategory) => {
      dict[downtimeCategory] = { interventionsNumber: 0, causedDowntimeInHours: 0 };
    }
  );
  (downtimeTypeConnector.dataPoints ?? []).forEach((point, index) => {
    const downtimeCategory = point.y;
    const downtimeDuration = downtimeDurationConnector.dataPoints[index]?.y ?? 0;
    dict[downtimeCategory] = {
      interventionsNumber: dict[downtimeCategory].interventionsNumber + 1,
      causedDowntimeInHours: dict[downtimeCategory].causedDowntimeInHours + downtimeDuration
    };
  });
  return dict;
}

import { CustomOption, QuillModules } from "ngx-quill";

const FONT_SIZE: string[] = ["9px", "11px", "13px", "16px", "19px", "21px"];
export const CSS_TOOLBAR_CONTAINER = "ql-toolbar";
export const CUSTOM_EDITOR_CONFIG: QuillModules = {
  toolbar: {
    container: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ size: FONT_SIZE }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ["link"]
    ]
  }
};
export const FONT_SIZE_OPTIONS: CustomOption[] = [
  {
    import: "attributors/style/size",
    whitelist: FONT_SIZE
  }
];

export const QUILL_TOOLTIPS: { [key: string]: string } = {
  bold: "Bold",
  italic: "Italic",
  underline: "Underline",
  strike: "Strikethrough",
  link: "Insert Link",
  ordered: "Ordered List",
  bullet: "Bulleted List",
  sub: "Subscript",
  super: "Superscript",
  size: "Font Size",
  align: "Alignment",
  color: "Text Color",
  background: "Background Color"
};

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { DynamicTemplateDirective } from "../../../dynamics/dynamic-template.directive";
import {
  EditorType,
  ObjectDescriptor,
  OfType,
  PropertyChangeHandler,
  PropertyInfo
} from "../../../meta";
import { assignDeep, isDefined, isEmpty } from "../../../ts-utils";
import { createChangeObject } from "../../helpers/create-change-object.helper";
import { overridePropertyByPath } from "../../helpers/override-property-by-path.helper";
import { BaseEditorComponentParams } from "../../models/base-editor-component-params";
import { DecoratorContext } from "../../models/decorator-context.type";
import { shouldDisplayProperty } from "../../services/editable-property.helper";
import { BaseEditorComponent } from "../base-editor.component";

@Component({
  selector: "object-editor",
  templateUrl: "nested-object-editor.component.html"
})
@OfType(EditorType.NestedObjectEditor)
export class NestedObjectEditorComponent
  extends BaseEditorComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild(DynamicTemplateDirective) dynamicChildren!: DynamicTemplateDirective;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  propertiesInfo: PropertyInfo<unknown>[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    protected params: BaseEditorComponentParams,
    protected hostElemet: ElementRef
  ) {
    super(hostElemet, params, changeDetectorRef);
  }

  ngAfterViewInit(): void {
    this.updatePropertiesInfo();
  }

  private updatePropertiesInfo(): void {
    this.propertiesInfo = this.typeProvider
      .getAllPropertyItemsDeep(this.typeDescriptor, this.value)
      .filter((info) => shouldDisplayProperty(info.descriptor, this.propertyInfo.descriptor));
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  onObjectPropertyChange(updatedPropertyInfo: PropertyInfo<unknown>, propIndex: number): void {
    if (updatedPropertyInfo == null) {
      return;
    }

    const { localPath, value: updatedPropValue } = updatedPropertyInfo;
    const propertyObserver = this.typeProvider.getPropertyObserverByPath(
      this.typeDescriptor,
      localPath
    );
    if (isDefined(propertyObserver)) {
      this.value = this.applyPropertyObserverChanges<unknown>(
        localPath,
        propertyObserver,
        updatedPropValue,
        updatedPropertyInfo
      );
      this.updatePropertiesInfo();
    } else {
      this.value = this.applyChanges(localPath, updatedPropValue);
      this.propertiesInfo[propIndex].value = updatedPropertyInfo.value;
    }
    this.onValueChanged(this.value);
  }

  private applyPropertyObserverChanges<T>(
    originalPath: string[],
    propertyObserver: PropertyChangeHandler<unknown, unknown, T>,
    updatedPropValue: T,
    updatedPropertyInfo: PropertyInfo<T>
  ): unknown {
    const pathToChangedPropertyParent = originalPath.slice(0, -1);
    const context: DecoratorContext = {
      dataSourceDeserializer: this.dataSourceDeserializer,
      deserializer: this.statusDisplayTypeDeserializer
    };

    let changes: any;
    if (isEmpty(pathToChangedPropertyParent)) {
      const updatedValue = propertyObserver(context, this.value, updatedPropertyInfo);
      changes = assignDeep(this.value, updatedValue);
    } else {
      changes = overridePropertyByPath(
        pathToChangedPropertyParent,
        _cloneDeep(this.value),
        propertyObserver(context, updatedPropValue, updatedPropertyInfo)
      );
    }
    return changes;
  }

  refreshValue(value): void {
    super.refreshValue(value);
    this.updatePropertiesInfo();
  }

  private applyChanges(originalPath: string[], updatedPropValue: any): any {
    const changeObject = createChangeObject(originalPath, updatedPropValue);
    const newValue = assignDeep(this.value, changeObject);
    return newValue;
  }

  get objectInfo(): ObjectDescriptor<unknown> {
    return {
      type: this.typeDescriptor,
      value: this.value
    };
  }

  public trackByPropName(index: number, item: PropertyInfo<any>): string {
    return item.descriptor.name;
  }
}

import { DATA_CONNECTOR_DTO } from "../../elements/models/entity-type.constants";
import { LocalizationService } from "../../i18n/localization.service";
import { tableColumnLocalizationKey } from "../../i18n/models/localization-dictionary";
import { isNotDefined } from "../../ts-utils";
import { createDataSource } from "../helpers/data-source-creation.helper";
import { DataConnectorDto } from "../models/data-connector";
import { sortDataPoints } from "../models/data-point";
import { DataColumnType } from "../models/data-source/data-source-descriptors";
import { DataSourceType } from "../models/data-source/data-source.type";
import { TypedDataTable } from "../models/data-table-response";

export interface DataTableToConnectorStrategy {
  map(typedTable: TypedDataTable, type: DataSourceType): DataConnectorDto[];
}

export class ConnectorPerColumnValue implements DataTableToConnectorStrategy {
  constructor(private localizationService: LocalizationService) {}

  private parseX(x: string, asDate: boolean): any {
    return asDate ? new Date(x) : x;
  }

  map(typedTable: TypedDataTable, type: DataSourceType): DataConnectorDto[] {
    const isRow1Date =
      typedTable.schema.columns.length > 1 &&
      typedTable.schema.columns[1].type === DataColumnType.Date;
    const xLabel = typedTable.schema.columns.length > 1 ? typedTable.schema.columns[1].name : null;
    const resolvedConnectors = typedTable.table.reduce((connectors: DataConnectorDto[], row) => {
      const key = row[0]?.toString();
      const localizedTitle = this.localizationService.getWithFallback(
        tableColumnLocalizationKey(typedTable.schema.tableName, key),
        key
      );
      const connectorId = typedTable.queryId + typedTable.schema.tableName + key;
      let connector = connectors.find((connector) => connector.id === connectorId);
      if (isNotDefined(connector)) {
        connector = new DataConnectorDto({
          isDynamicallyCreated: true,
          id: connectorId
        });
        connector.isTimeSeries = isRow1Date;
        connector.title = localizedTitle;
        connector.dataSource = createDataSource(
          type,
          connector.dataSource ?? {},
          DATA_CONNECTOR_DTO
        );
        connector.properties = {
          keyLabel: typedTable.schema.columns[0].name,
          xLabel: xLabel,
          type:
            typedTable.schema.columns.find((column) => column.name === key)?.type ??
            DataColumnType.Unknown,
          aliasName: ""
        };
        connector.dataPoints = [];
        connector.role = "Value";
        connectors.push(connector);
      }

      const point =
        row.length > 3
          ? { x: this.parseX(row[1], isRow1Date), y: row.slice(2) }
          : row.length === 3
          ? { x: this.parseX(row[1], isRow1Date), y: row[2] }
          : { y: row[1] };
      connector.dataPoints.push(point);
      return connectors;
    }, []);

    const connectorsArray = Object.values(resolvedConnectors);
    connectorsArray.forEach(
      (connector) => (connector.dataPoints = connector.dataPoints.sort(sortDataPoints))
    );
    return connectorsArray;
  }
}

export class ConnectorPerColumn implements DataTableToConnectorStrategy {
  constructor(private localizationService: LocalizationService) {}

  map(typedTable: TypedDataTable, type: DataSourceType): DataConnectorDto[] {
    const indexedConnectors: DataConnectorDto[] = typedTable.schema.columns.map((column) => {
      const localizedTitle = this.localizationService.getWithFallback(
        tableColumnLocalizationKey(typedTable.schema.tableName, column.name),
        column.name
      );
      const connector = new DataConnectorDto({
        isDynamicallyCreated: true,
        id: typedTable.queryId + column.name
      });
      connector.isTimeSeries = false;
      connector.title = localizedTitle;
      connector.dataSource = createDataSource(type, connector.dataSource ?? {}, DATA_CONNECTOR_DTO);
      connector.properties = {
        type: column.type,
        aliasName: ""
      };
      connector.dataPoints = [];
      connector.role = "Value";
      return connector;
    });

    for (const row of typedTable.table) {
      row.forEach((cell, index) => indexedConnectors[index].dataPoints.push({ y: cell }));
    }
    return indexedConnectors;
  }
}

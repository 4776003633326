import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from "@angular/core";
import { Dispatcher } from "../../dispatcher";
import { EnvironmentSelector } from "../../environment/services/environment.selector";
import { createUpdatedComponentsInfo } from "../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../meta/models/entity";
import { UndoRedoService } from "../../shared/services/undo-redo.service";
import { isEmpty } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { keepInputUpdated } from "../helpers/inline-edit.helper";
import { CSS_INLINE_EDIT_ICON, DELETE_ICON, EDIT_ICON } from "../models/inline-mode-params";
import { CSS_TABLE_PAGINATOR_CONTAINER } from "../models/table/table.constants";
import { DataConnectorSelector } from "../services/entity-selectors/data-connector.selector";
import { InlineEditService } from "../services/inline-edit.service";
import { DataConnectorActions } from "../store/data-connector/data-connector.actions";
import { InlineEditDirective } from "./inline-edit.directive";

@Directive({
  selector: "[table-inline-edit]"
})
export class TableInlineEditDirective
  extends InlineEditDirective
  implements OnChanges, AfterViewInit
{
  @Input() editableText: string = "";
  @Input() connectorId: Maybe<string> = null;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected element: ElementRef,
    protected inlineEditService: InlineEditService,
    protected renderer: Renderer2,
    protected dispatcher: Dispatcher,
    protected undoRedoService: UndoRedoService,
    protected environmentSelector: EnvironmentSelector,
    public viewContainerRef: ViewContainerRef,
    private dataConnectorSelector: DataConnectorSelector
  ) {
    super(
      cdr,
      element,
      inlineEditService,
      renderer,
      dispatcher,
      undoRedoService,
      environmentSelector,
      viewContainerRef
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isDefined(changes["editableText"]) && isDefined(this.inlineComponentParams)) {
      keepInputUpdated(this.inlineComponentParams?.hostElement, this.editableText);
    }
  }

  ngAfterViewInit(): void {
    this.inlineComponentParams = this.inlineEditService.componentInlineInfo;
    if (this.inlineComponentParams) {
      super.initializeInlineEditing();
      this.configureColumnForTitleEdit();
      this.configureColumnForDeleting();
    }
  }

  protected initializeInlineEditing(): void {
    super.initializeInlineEditing();
    this.setupTableForAdvancedEditing();
  }

  private setupTableForAdvancedEditing(): void {
    if (!isEmpty(this.editableText)) {
      this.preserveOriginalColumnWidth();
      this.configureColumnForTitleEdit();
      this.configureColumnForDeleting();
    }
  }

  private preserveOriginalColumnWidth(): void {
    const columnHeader = this.element.nativeElement.closest("th");
    if (isDefined(columnHeader)) {
      this.renderer.setStyle(
        columnHeader,
        "max-width",
        `${columnHeader.getBoundingClientRect().width}px`
      );
      this.renderer.setStyle(
        columnHeader,
        "width",
        `${columnHeader.getBoundingClientRect().width}px`
      );
    }
  }

  private configureColumnForTitleEdit(): void {
    const editTextIcon: Maybe<HTMLElement> = this.appendIconForAdvancedEditing(EDIT_ICON);
    if (isDefined(editTextIcon)) {
      this.renderer.listen(editTextIcon, "click", (event) => {
        this.processColumnTitleEditing(event);
      });
    }
  }

  private processColumnTitleEditing(event: Event): void {
    event.stopPropagation();
    this.addOrRemoveElementSiblings(false);
    this.prepareInlineSimpleTextEditing(this.editableText, this.inlineComponentParams?.id);
  }

  private configureColumnForDeleting(): void {
    const deleteIcon: Maybe<HTMLElement> = this.appendIconForAdvancedEditing(DELETE_ICON);
    if (isDefined(deleteIcon)) {
      this.renderer.listen(deleteIcon, "click", (event) => {
        event.stopPropagation();
        this.deleteColumn();
      });
    }
  }

  private deleteColumn(): void {
    this.undoRedoService.createSnapshot({
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.inlineComponentParams?.id])
    });
    this.dispatcher.dispatch(
      DataConnectorActions.deleteOne({
        componentId: this.inlineComponentParams?.id,
        connector: this.dataConnectorSelector.getById(this.connectorId)
      })
    );
  }

  protected shouldLeaveInlineMode(target: HTMLElement): boolean {
    const tablePaginator: Maybe<Element> = target.closest("." + CSS_TABLE_PAGINATOR_CONTAINER);
    return super.shouldLeaveInlineMode(target) && isNotDefined(tablePaginator);
  }

  protected exitInlineMode(
    componentId: EntityId,
    shouldShowDraggableOverlay: boolean = true
  ): void {
    this.removeIconsFromAdvancedEditing();
    super.exitInlineMode(componentId, shouldShowDraggableOverlay);
  }

  private removeIconsFromAdvancedEditing(): void {
    const currentIcons: HTMLCollectionOf<Element> =
      this.inlineComponentParams?.hostElement.getElementsByClassName(CSS_INLINE_EDIT_ICON);
    if (isDefined(currentIcons) && currentIcons.length > 0) {
      (Array.from(currentIcons) as HTMLElement[]).forEach((icon) =>
        this.renderer.removeChild(this.inlineComponentParams?.hostElement, icon)
      );
    }
  }

  protected processInputFocusOut(
    componentId: EntityId,
    parentElement: HTMLElement,
    inputElement: HTMLInputElement,
    preserveInputOnUndoRedo: boolean
  ): void {
    super.processInputFocusOut(componentId, parentElement, inputElement, preserveInputOnUndoRedo);
    if (!preserveInputOnUndoRedo && isDefined(this.inlineEditService.componentInlineInfo)) {
      this.addOrRemoveElementSiblings(true);
      this.appendIconForAdvancedEditing(EDIT_ICON);
      this.appendIconForAdvancedEditing(DELETE_ICON);
    }
  }

  private appendIconForAdvancedEditing(icon: string): Maybe<HTMLElement> {
    const currentIcons = this.element.nativeElement.getElementsByClassName(`${icon}`);
    if (isEmpty(currentIcons) && isDefined(this.connectorId)) {
      const newIcon: HTMLElement = this.renderer.createElement("i");
      newIcon.classList.add("abb-icon", "abb-icon--small", `${icon}`, CSS_INLINE_EDIT_ICON);
      this.renderer.appendChild(this.element.nativeElement, newIcon);
      return newIcon;
    }
  }

  protected updateTargetTitle(componentId: EntityId, newValue: string): void {
    if (this.editableText !== newValue) {
      super.updateTargetTitle(componentId, newValue);
      this.dispatcher.dispatch(
        DataConnectorActions.updateOne({
          connectorUpdate: {
            id: this.connectorId,
            changes: { title: newValue }
          }
        })
      );
    }
  }
}

import { LocalizationService } from "../../i18n/localization.service";
import { ButtonConfig } from "../../shared/models/button/button-config";
import { ButtonStyle } from "../../shared/models/button/button-style";
import { isDefined, isEmptyOrNotDefined } from "../../ts-utils";
import { DynamicButtonType } from "../models/dynamic-button-type";
import { hideElement } from "./dom-element-visibility.helper";
import { LINK_BUTTON_CSS_CLASS } from "./get-dynamic-buttons.helper";

export const CONTAINER_CSS = "dynamic-buttons-container";
const CONFIG_BUTTON_CSS = "dynamic-button--config";
const RUNTIME_BUTTON_CSS = "dynamic-button--runtime";

// Needs manual DOM tinkering because Angular requires ViewContainerRef of a sibling element
// in order to instantiate a component dynamically. ViewContainerRef can only be obtained
// for static children of a component (or the component itself). Since BaseComponent does not
// have a template, it cannot have a static child unless one is defined in each derivate's template.
export function createDynamicButtons(
  dynamicButtonParams: ButtonConfig[],
  localizer: LocalizationService
): HTMLDivElement {
  const buttonContainer: HTMLDivElement = createButtonContainer();
  dynamicButtonParams
    .filter((buttonParams) => buttonParams.shouldShow())
    .map((buttonParams) => createButton(buttonParams, localizer.get(buttonParams.style.title)))
    .forEach((dynamicButton: HTMLSpanElement) => buttonContainer.appendChild(dynamicButton));
  return buttonContainer;
}

function createButtonContainer(): HTMLDivElement {
  const container: HTMLDivElement = document.createElement("div");
  container.classList.add(CONTAINER_CSS);
  return container;
}

function createButton(buttonParams: ButtonConfig, localizedTitle: string): HTMLSpanElement {
  const button: HTMLElement = createButtonBody(buttonParams, localizedTitle);
  const icon: HTMLElement = createIcon(buttonParams.style);
  button.appendChild(icon);
  if (hasDropdown(buttonParams)) {
    const dropdown = createDropdown(buttonParams.dropdownOptions);
    button.appendChild(dropdown);
  }
  return button;
}

function createButtonBody(buttonConfig: ButtonConfig, localizedTitle: string): HTMLElement {
  const buttonBody: HTMLElement = document.createElement(
    buttonConfig.style.buttonClass === LINK_BUTTON_CSS_CLASS ? "div" : "label"
  );
  // FIXME: title will not be changed if language is changed in runtime
  buttonBody.title = localizedTitle;
  buttonBody.addEventListener("click", (event) => {
    event.preventDefault();
    buttonConfig.clickFunction.execute();
  });
  const buttonBaseClass =
    buttonConfig.style.buttonType === DynamicButtonType.Config
      ? CONFIG_BUTTON_CSS
      : RUNTIME_BUTTON_CSS;
  buttonBody.classList.add(buttonBaseClass);

  if (isDefined(buttonConfig.pageUrl)) {
    (buttonBody as HTMLAnchorElement).href = buttonConfig.pageUrl;
    buttonBody.setAttribute("data-test", "linkButton");
  }

  if (!isEmptyOrNotDefined(buttonConfig.style.buttonClass)) {
    buttonBody.classList.add(buttonConfig.style.buttonClass);
  }

  return buttonBody;
}

function createIcon(buttonStyle: ButtonStyle): HTMLElement {
  const icon: HTMLElement = document.createElement("i");
  icon.classList.add(buttonStyle.iconClass, buttonStyle.icon);
  return icon;
}

function hasDropdown(buttonParams: ButtonConfig): boolean {
  return buttonParams.dropdownOptions.length > 0;
}

function createDropdown(dropdownOptionsParams: ButtonConfig[]): HTMLElement {
  const dropdown: HTMLElement = document.createElement("div");
  dropdown.classList.add("custom-button__dropdown");
  hideElement(dropdown);
  dropdownOptionsParams.map(createDropdownOption).forEach(dropdown.appendChild);
  return dropdown;
}

function createDropdownOption(optionParams: ButtonConfig): HTMLElement {
  const option: HTMLElement = document.createElement("div");
  option.innerText = optionParams.style.title;
  option.classList.add("custom-button-dropdown__option");
  option.addEventListener("mousedown", optionParams.clickFunction.execute);
  if (!optionParams.shouldShow) {
    hideElement(option);
  }
  return option;
}

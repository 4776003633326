import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { BaseComponent, ComponentConstructorParams, ConnectorRoles, View } from "../../../../../ui-core/src/lib/elements";
import { EditableType, LayoutBuilder } from "../../../../../ui-core/src/lib/meta";
import { RDS_LOCALIZATION_DICTIONARY } from "../../../assets/i18n/models/rds-localization-dictionary";
import { AzureQueryStringService } from "../../services/api/azure-query-string.service";
import { RdsComponentCategory } from "../rds-component-category";
import { CustomerBreadcrumbsViewConfig } from "./view-config";

@Component({
  selector: 'rds-customer-breadcrumbs',
  templateUrl: './customer-breadcrumbs.component.html',
  styleUrl: './customer-breadcrumbs.component.scss',
  providers: [
    {
      provide: BaseComponent,
      useExisting: CustomerBreadcrumbsComponent
    }
  ]
})
@ConnectorRoles()
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "CustomerBreadcrumbsComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.CustomerBreadcrumbs
)
@EditableType({ fullName: "CustomerBreadcrumbsComponent", title: "customer-breadcrumbs" })
export class CustomerBreadcrumbsComponent extends BaseComponent implements OnInit {
  protected plantName: string = "";
  protected motorName: string = "";

  @View(CustomerBreadcrumbsViewConfig)
  public get view() {
    return this.currentState.view as CustomerBreadcrumbsViewConfig;
  }

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    protected cdr: ChangeDetectorRef,
    private queryStringService: AzureQueryStringService
  ) {
    super(params, hostElementRef, cdr);
  }

  ngOnInit(): void {
    this.plantName = this.queryStringService.getPlantName();
    this.motorName = this.queryStringService.getMotorName();
  }
}

import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { ReportBrowserSelector } from "../../browsing/services/report-browser.selector";
import { WebServicesConfiguration } from "../../core/services/api.config";
import { FilterFactory } from "../../core/services/filter/filter-factory.service";
import { IDragDropService } from "../../core/services/i-drag-drop.service";
import { IDropHelper } from "../../core/services/i-drop-helper";
import { ComponentMetadataService } from "../../data-connectivity/services/component-metadata.service";
import { DataService } from "../../data-connectivity/services/data.service";
import { DataConnectorFactory } from "../../data-connectivity/services/deserializers/data-connector-factory.service";
import { DataSourceDeserializer } from "../../data-connectivity/services/deserializers/data-source.deserializer";
import { StatusDisplayTypeDeserializer } from "../../data-connectivity/services/deserializers/status-display-type.deserializer";
import { Dispatcher } from "../../dispatcher";
import { ComponentStateSelector } from "../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "../../elements/services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../../elements/services/entity-selectors/data-connector.selector";
import { LinkingWidgetService } from "../../elements/services/linking-widget.service";
import { LocalizationService } from "../../i18n/localization.service";
import { TypeProvider } from "../../meta/services/type-provider";
import { UndoRedoService } from "../../shared/services/undo-redo.service";
import { PropertySheetService } from "../services/property-sheet.service";

@Injectable()
export class BaseEditorComponentParams {
  constructor(
    public dragDropService: IDragDropService,
    public dropHelper: IDropHelper,
    public typeProvider: TypeProvider,
    public translationService: LocalizationService,
    public dispatcher: Dispatcher,
    public actions$: Actions,
    public componentSelector: ComponentStateSelector,
    public metadataService: ComponentMetadataService,
    public connectorViewSelector: DataConnectorViewSelector,
    public propertySheetService: PropertySheetService,
    public connectorFactory: DataConnectorFactory,
    public connectorSelector: DataConnectorSelector,
    public undoRedoService: UndoRedoService,
    public webConfigService: WebServicesConfiguration,
    public dataService: DataService,
    public reportBrowserSelector: ReportBrowserSelector,
    public linkingWidgetService: LinkingWidgetService,
    public dataSourceDeserializer: DataSourceDeserializer,
    public statusDisplayTypeDeserializer: StatusDisplayTypeDeserializer,
    public filterFactory: FilterFactory
  ) {}
}

import { combineLatest, Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { DecoratorDelegateContext } from "../../core/models/decorator-delegate-context";
import { getConnectorIdByViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { shouldShowDayStart } from "../../shared/helpers/time-filter.helper";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { isContainerWidget } from "../models/component-type.helper";
import { DisplayStrategies } from "../models/display-strategies/time-series-display-strategies";
import { selectDataConnectorById } from "../store/data-connector";
import { selectDataConnectorViewById } from "../store/data-connector-view/data-connector-view.selectors";

export function selectDisplayStrategyByComponentId(
  context: DecoratorDelegateContext
): Observable<string> {
  return context.services.componentStateSelector.selectDisplayStrategy(context.ownerInstance.id);
}

export function selectDisplayStrategyByConnectorViewId(
  context: DecoratorDelegateContext
): Observable<string> {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  const component = context.services.componentStateSelector.getComponentByConnectorId(connectorId);
  if (isNotDefined(component)) {
    return of("");
  }
  return context.services.componentStateSelector.selectDisplayStrategy(component.id);
}

export function selectComponentTypeByConnectorViewId(
  context: DecoratorDelegateContext
): Observable<string> {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  const component = context.services.componentStateSelector.getComponentByConnectorId(connectorId);
  if (isNotDefined(component)) {
    return of("");
  }
  return of(component.type);
}

export function selectScatterSeriesTypeByConnectorViewId(
  context: DecoratorDelegateContext
): Observable<string> {
  return context.store.select(selectDataConnectorViewById(context.ownerInstance.id)).pipe(
    filter((x) => isDefined(x)),
    map((connectorView) => connectorView?.scatterSeriesConfig.seriesType ?? "Default")
  );
}

export function filterOutEnumValues(enumObject: any, valuesToExclude: any[]): any[] {
  return Object.values(enumObject).filter((value) => !valuesToExclude.includes(value));
}

export function selectConnectorComputability(
  context: DecoratorDelegateContext
): Observable<boolean> {
  const connectorId = getConnectorIdByViewId(context.ownerInstance.id);
  return context.store.select(selectDataConnectorById(connectorId)).pipe(
    filter((x) => isDefined(x)),
    map((connector) => connector?.isComputing ?? false)
  );
}

export function getTemplateBuilderMode$(context: DecoratorDelegateContext): Observable<boolean> {
  return context.services.environmentSelector.selectTemplateBuilderMode();
}

export function selectHeaderVisibility(context: DecoratorDelegateContext): Observable<boolean> {
  return combineLatest([
    context.services.componentStateSelector.selectDisplayStrategy(context.ownerInstance.id),
    context.services.componentStateSelector.selectHeaderVisibility(context.ownerInstance.id)
  ]).pipe(
    map(
      ([displayStrategy, showHeader]) => displayStrategy === DisplayStrategies.Table && showHeader
    )
  );
}

export function selectFooterVisibility(context: DecoratorDelegateContext): Observable<boolean> {
  return combineLatest([
    context.services.componentStateSelector.selectDisplayStrategy(context.ownerInstance.id),
    context.services.componentStateSelector.selectFooterVisibility(context.ownerInstance.id)
  ]).pipe(
    map(
      ([displayStrategy, showFooter]) => displayStrategy === DisplayStrategies.Table && showFooter
    )
  );
}

export function getLiveMode$(context: DecoratorDelegateContext): Observable<boolean> {
  return context.services.filterSelector
    .selectGlobalTimeRange()
    .pipe(map((timeRange) => shouldShowDayStart(timeRange)));
}

export function selectTitleHorizontalAlignmentVisibility(
  context: DecoratorDelegateContext
): Observable<boolean> {
  return combineLatest([
    context.services.componentStateSelector.selectDisplayStrategy(context.ownerInstance.id),
    context.services.componentStateSelector.selectComponentStateById(context.ownerInstance.id),
    context.services.environmentSelector.selectTemplateBuilderMode()
  ]).pipe(
    map(
      ([displayStrategy, componentStateDto, templateBuilderMode]) =>
        displayStrategy === DisplayStrategies.Table ||
        isContainerWidget(componentStateDto?.type) ||
        templateBuilderMode
    )
  );
}

import { LayoutEditorLocalizationDictionary } from "ui-core";

export class RdsLayoutEditorLocalizationDictionary extends LayoutEditorLocalizationDictionary {
  AirgapProfileChart: string = "AirgapProfileChart";
  LoadSharingChart: string = "LoadSharingChart";
  PoleAlignmentChart: string = "PoleAlignmentChart";
  RotorShapeChart: string = "RotorShapeChart";
  PolarChart: string = "PolarChart";
  CustomerBreadcrumbs: string = "CustomerBreadcrumbs";
  StaticEccentricityChart: string = "StaticEccentricityChart";
  StatorWindingTemperaturChart: string = "StatorWindingTemperaturChart";
  StatusHeatMap: string = "StatusHeatMap";
  GradientChart: string = "GradientChart";
  WaterConductivityChart: string = "WaterConductivityChart";
  VibrationChart: string = "VibrationChart";
  ArrayChart: string = "ArrayChart";
  constructor() {
    super();
    Object.keys(this).forEach((key) => {
      const thisKey = key as keyof RdsLayoutEditorLocalizationDictionary;
      this[thisKey] = `LayoutEditor.${thisKey}`;
    });
  }
}


import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { cloneDeep as _cloneDeep, isString, transform } from "lodash";
import { isNullOrEmpty } from "projects/localization-util/src/util";
import { SERIES_TYPE_BAND } from "projects/ui-core/src/lib/data-connectivity/models/series-type.strategies";
import { DataConnectorViewModelDeserializer } from "projects/ui-core/src/lib/data-connectivity/services/deserializers/data-connector-vm.deserializer";
import { HISTORY_VIEW_CONSTANTS } from "projects/ui-core/src/lib/elements/components/history-view/history-view.constants";
import { SCATTER_CHART } from "projects/ui-core/src/lib/elements/models/element-type.constants";
import { EMPTY, Observable, Observer, forkJoin, of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import {
  AnalyticTypes,
  ComponentStateSelector,
  ConnectorsDictionaryIndexedById,
  CustomFilterValue,
  DataConnectorDto,
  DataSourceDto,
  DataTableResponse,
  Dictionary,
  ElementsDataService,
  EntityId,
  Equipment,
  EquipmentClassNames,
  EquipmentDataSourceDto,
  EquipmentProperty,
  ErrorCatchingActions,
  Filter,
  GenericDataSourceDescriptor,
  GenericDataSourceDto,
  LOCALIZATION_DICTIONARY,
  Maybe,
  OrderBy,
  OrderDirection,
  PeriodType,
  QueryFilter,
  ReportInfoSelector,
  SignalDataSourceDto,
  TabularDataSourceDto,
  TimeSeriesDataPoint,
  first,
  isDefined,
  isEmpty,
  isEmptyOrNotDefined,
  isGeneric,
  isNotDefined,
  isSignalBased,
  isTabular,
  setDataStatusFromPoints,
  sortDataPoints
} from "ui-core";
import { createConnectorsDictionaryWithEmptyDataPoints } from "../../../../ui-core/src/lib/elements/helpers/connectors.helper";
import { DataStatus } from "../../../../ui-core/src/lib/elements/models/data-status";
import { RequestScope } from "../../../../ui-core/src/lib/elements/models/request-scope";
import { EquipmentRequestDto } from "../models/api/equipment-request";
import { ClientDto, QueryDto } from "../models/api/query";
import { AzureServiceParams } from "../models/azure-service-params";
import { FLEET_OVERVIEW_REPORT_ID } from "../models/constants/predefined-reports";
import { DEFAULT_ARRAY_CUSTOM_FILTER_KEY } from "../models/default-custom-filters";
import { RdsEquipment } from "../models/equipment/rds-equipment";
import { RdsEquipmentPropertyResponse } from "../models/equipment/rds-equipment-property-response";
import { RdsEquipmentResponse } from "../models/equipment/rds-equipment-response";
import {
  DAY_PTYPE,
  HALF_HOUR_PTYPE,
  HOUR_PTYPE,
  MINUTE_PTYPE,
  PRI_PTYPE,
  QUARTER_HOUR_PTYPE,
  WEEK_PTYPE
} from "../models/period-types";
import { RdsSignalDragInfo } from "../models/rds-signal-drag-info.model";
import { TrendResultDto } from "../types";
import { AzureEquipmentConverter } from "./api/azure-equipment.converter";
import { AzureQueryStringService } from "./api/azure-query-string.service";
import { GenericQueriesService } from "./api/generic-queries.service";
import { PlantTimeConverter } from "./api/plant-time.converter";
import { QueryService } from "./api/query.service";
import { RdsOverridableParamKeys } from "./core/query-param-key.converter";

const NONE_VALUE = "None";

@Injectable()
export class AzureService extends ElementsDataService {
  private kmTrendConnectors: ConnectorsDictionaryIndexedById = {};
  private azureEquipmentConverter = new AzureEquipmentConverter();

  constructor(
    params: AzureServiceParams,
    private dataConnectorViewModelService: DataConnectorViewModelDeserializer,
    private genericQueriesService: GenericQueriesService,
    private timeConverter: PlantTimeConverter,
    private componentStateSelector: ComponentStateSelector,
    private reportInfoSelector: ReportInfoSelector,
    private queryService: QueryService,
    private store$: Store
  ) {
    super(params);
  }

  protected get queryStringService(): AzureQueryStringService {
    return this._queryStringService as AzureQueryStringService;
  }

  // GetData
  protected getData(
    filter: QueryFilter,
    dataConnectors: Dictionary<DataConnectorDto[]>,
    requestScope: RequestScope
  ): Observable<ConnectorsDictionaryIndexedById> {
    if (requestScope === RequestScope.Incremental) {
      throw new Error("Incremental updates are not supported!");
    }

    dataConnectors = Object.keys(dataConnectors).reduce(
      (acc: Dictionary<DataConnectorDto[]>, componentId) => {
        const filteredComponentConnectors = dataConnectors[componentId].filter((connector) =>
          isSignalBased(connector.dataSource)
        );
        if (filteredComponentConnectors.length > 0) {
          acc[componentId] = filteredComponentConnectors;
        }
        return acc;
      },
      {}
    );

    if (!dataConnectors || (Array.isArray(dataConnectors) && dataConnectors.length === 0)) {
      const connectorsWithData: ConnectorsDictionaryIndexedById =
        createConnectorsDictionaryWithEmptyDataPoints(
          dataConnectors,
          DataStatus.NoDataReceived,
          false
        );
      return of(connectorsWithData);
    }

    const allResponseObservables = this.sendAndMapOtherRequests(filter, dataConnectors);
    return allResponseObservables;
  }

  private sendAndMapOtherRequests(
    filter: QueryFilter,
    splitOtherConnectors: Dictionary<DataConnectorDto[]>
  ): Observable<Dictionary<DataConnectorDto>> {
    const dataConnectors = Object.keys(splitOtherConnectors).reduce(
      (acc: Dictionary<DataConnectorDto[]>, componentId) => {
        const filteredComponentConnectors = splitOtherConnectors[componentId].filter(
          (connector) => !Object.keys(this.kmTrendConnectors).includes(connector.id.toString())
        );
        if (filteredComponentConnectors.length > 0) {
          acc[componentId] = filteredComponentConnectors;
        }
        return acc;
      },
      {}
    );

    if (Object.values(dataConnectors).flat().length === 0) {
      return of({ ...this.kmTrendConnectors });
    }

    const otherConnectors = this._sendRequest(filter, dataConnectors).pipe(
      map((response) => {
        const dataPointDictionary = this.convertRawResponseToDataPointDictionary(
          filter.customFilters,
          response,
          dataConnectors
        );
        return Object.assign({}, this.kmTrendConnectors, dataPointDictionary);
      })
    );

    return otherConnectors;
  }

  private _sendRequest(
    filter: QueryFilter,
    connectors: Dictionary<DataConnectorDto[]>
  ): Observable<TrendResultDto[]> {
    const queryStringPlant = this.queryStringService.getPlantName();

    if (isNullOrEmpty(queryStringPlant)) {
      return this.sendMultiPlantRequest(filter, connectors);
    } else {
      return this.sendRequestForPlant(queryStringPlant, filter, connectors);
    }
  }

  private sendMultiPlantRequest(
    filter: QueryFilter,
    connectors: Dictionary<DataConnectorDto[]>
  ): Observable<TrendResultDto[]> {
    const groupedConnectors = this.groupConnectorsByPlant(connectors);

    const requests = Object.keys(groupedConnectors).map((plant) => {
      const plantConnectors = groupedConnectors[plant];
      return this.sendRequestForPlant(plant, filter, plantConnectors);
    });

    return forkJoin(requests).pipe(map((value) => value.flat()));
  }

  private groupConnectorsByPlant(
    connectors: Dictionary<DataConnectorDto[]>
  ): Dictionary<Dictionary<DataConnectorDto[]>> {
    const result: Dictionary<Dictionary<DataConnectorDto[]>> = {};

    Object.keys(connectors).forEach((key) => {
      const widget = connectors[key];
      const dataSource = widget[0].dataSource as SignalDataSourceDto;
      const signalId = dataSource.signal.id! as string;
      const plantName = signalId.split(".")[1];
      if (!result[plantName]) {
        result[plantName] = {};
      }
      result[plantName][key] = widget;
    });

    return result;
  }

  private sendRequestForPlant(
    plant: string,
    filter: QueryFilter,
    connectors: Dictionary<DataConnectorDto[]>
  ): Observable<TrendResultDto[]> {
    const rootEquipment: string = this.queryStringService.getMotorName() || "all";

    const frequency: string = this.queryParamsResolver.getGlobalPeriodType();
    let errorFound: boolean = false;

    const fillGapsInData = filter.id === HISTORY_VIEW_CONSTANTS.FILTER_ID;

    const request: QueryDto<ClientDto> = {
      Frequency: frequency,
      From: this.timeConverter.removeLocalTimeBias(filter.timeRange.from),
      To: this.timeConverter.removeLocalTimeBias(filter.timeRange.to),
      FillGaps: fillGapsInData,
      Clients: Object.keys(connectors).reduce((acc: any[], componentId) => {
        const clients = connectors[componentId].map((connector) => {
          if (!connector.id) {
            console.error("ClientId is required when calling Serving/Data endpoint");
            errorFound = true;
            return;
          }

          const dataSource = connector.dataSource as SignalDataSourceDto;

          if (!isDefined(dataSource.signal.id)) {
            console.error("Data source signal id not defined!");
            errorFound = true;
            return;
          }

          let clientMotorName;

          const signalParts = dataSource.signal.id.toString().split(".");
          clientMotorName = signalParts[2];

          const inheritedParams = this.getInheritedParams(componentId, connector);
          const lastValue = inheritedParams.Aggregation === "Latest";
          if (lastValue) {
            inheritedParams.Aggregation = null;
          }

          const client: any = {
            ClientId: connector.id,
            Motor: rootEquipment === "all" ? clientMotorName : "",
            Signal: dataSource.signal.id,
            FileType: connector.properties["fileType"],
            Forecast: dataSource.forecast,
            Anomalies: dataSource.anomalies,
            LastValue: lastValue,
            DataPointCounts: connector.numberOfRequestedDataPoints,
            ...inheritedParams
          };

          Object.keys(client).forEach((key) => client[key] === "" && delete client[key]);

          return client;
        });
        acc = acc.concat(clients);
        return acc;
      }, [])
    };

    if (errorFound) {
      return of([]);
    }

    Object.keys(request).forEach((key) => request[key] === "" && delete request[key]);

    return this.queryService.sendQuery({
      plant,
      rootEquipment,
      body: request
    });
  }

  private getInheritedParams(
    componentId: EntityId,
    connector: DataConnectorDto
  ): Record<RdsOverridableParamKeys, string> {
    const inheritedParams = this.queryParamsResolver.inheritParameters(componentId, connector);
    return Object.keys(inheritedParams).reduce((acc, param) => {
      const queryParamName = this.queryParamKeyConverter.convert(param);
      if (!isEmptyOrNotDefined(queryParamName)) {
        acc[queryParamName] = inheritedParams[param];
      }

      if (inheritedParams[param] === NONE_VALUE) {
        acc[queryParamName] = undefined;
      }
      return acc;
    }, {} as Record<RdsOverridableParamKeys, string>);
  }

  convertRawResponseToDataPointDictionary(
    queriedFilters: Dictionary<CustomFilterValue>,
    trendResults: TrendResultDto[],
    filteredConnectors: Dictionary<DataConnectorDto[]>
  ): ConnectorsDictionaryIndexedById {
    if (!trendResults || !Array.isArray(trendResults) || trendResults.length === 0) {
      const connectorsWithData: ConnectorsDictionaryIndexedById =
        createConnectorsDictionaryWithEmptyDataPoints(
          filteredConnectors,
          DataStatus.RequestFailed,
          false
        );
      return connectorsWithData;
    }

    const connectorsWithData: ConnectorsDictionaryIndexedById = {};
    for (const trendResult of trendResults) {
      const correspondingConnector = Object.values(filteredConnectors)
        .flat()
        .find((conn) => conn.id === trendResult.ClientId);

      const component = this.componentStateSelector.getComponentByConnectorId(
        correspondingConnector?.id as EntityId
      );

      const dataSourceId = (correspondingConnector?.dataSource as SignalDataSourceDto).signal.id;
      let dataConnector = new DataConnectorDto({
        dataSource: new SignalDataSourceDto({ signal: { id: dataSourceId } })
      });
      Object.assign(dataConnector, correspondingConnector);
      if (
        (dataConnector.dataSource as SignalDataSourceDto)?.signal.name === "" &&
        trendResult.Config
      ) {
        dataConnector = _cloneDeep(dataConnector);
        (dataConnector.dataSource as SignalDataSourceDto).signal.name =
          trendResult.Config.internalTag;
      }
      connectorsWithData[dataConnector.id] = dataConnector;

      if (trendResult.DataPoints === null) {
        dataConnector.dataPoints = [];
        continue;
      }

      //Transform dataPoints
      dataConnector.dataPoints = this._convertDataPoints(trendResult.DataPoints).sort(
        sortDataPoints
      );

      const numDPs = dataConnector.dataPoints?.length || 0;

      if (component?.type === SCATTER_CHART && numDPs > 1000) {
        dataConnector.dataPoints = dataConnector.dataPoints?.slice(numDPs - 1000, numDPs);
      }

      if (dataConnector.analytics === undefined) {
        dataConnector.analytics = {};
      }

      if (trendResult.Analytics && trendResult.Analytics[AnalyticTypes.Forecast]) {
        const forecasts = trendResult.Analytics[AnalyticTypes.Forecast];
        dataConnector.analytics = {
          ...dataConnector.analytics,
          [AnalyticTypes.Forecast]: forecasts.map((forecast) => this._convertDataPoints(forecast))
        };
      } else {
        dataConnector.analytics = {
          ...dataConnector.analytics,
          [AnalyticTypes.Forecast]: []
        };
      }

      if (trendResult.Analytics && trendResult.Analytics[AnalyticTypes.Anomaly]) {
        const anomalies = trendResult.Analytics[AnalyticTypes.Anomaly];
        dataConnector.analytics = {
          ...dataConnector.analytics,
          [AnalyticTypes.Anomaly]: anomalies.map((anomalies) => this._convertDataPoints(anomalies))
        };
      } else {
        dataConnector.analytics = {
          ...dataConnector.analytics,
          [AnalyticTypes.Anomaly]: []
        };
      }

      let connectorCustomFilters = {};

      if (isDefined(dataConnector.filterId)) {
        const connectorFilter = this.filterSelector.getById(dataConnector.filterId);
        if (isDefined(connectorFilter)) {
          connectorCustomFilters = connectorFilter?.customFilters;
        }
      }

      // Get arrayAggregationParameter
      const arrayFilter = this.queryParamsResolver.resolveConnectorFilter(
        connectorCustomFilters,
        queriedFilters,
        (filters: Dictionary<CustomFilterValue>) => filters[DEFAULT_ARRAY_CUSTOM_FILTER_KEY]
      );
      if (isDefined(arrayFilter) && !isNaN(Number(arrayFilter))) {
        dataConnector.dataPoints = dataConnector.dataPoints.map((dp) => {
          return {
            ...dp,
            y: dp.y[arrayFilter]
          };
        });
      }
      setDataStatusFromPoints(dataConnector);
      dataConnector.isIncrementalUpdate = false;

      const viewDataConnect = this.dataConnectorViewModelService.convert(
        correspondingConnector as DataConnectorDto
      );

      if (viewDataConnect.view.timeSeriesConfig.seriesType === SERIES_TYPE_BAND) {
        dataConnector.dataPoints.forEach((dp) => {
          // we are using bands only for motor status, if it was stoppped or tripped, show band
          if (dp.y === 2 || dp.y === 3 || dp.y === -1) {
            dp.y = 1;
          } else {
            dp.y = 0;
          }
        });
      }

      if (trendResult.Config) {
        // Update properties limits
        dataConnector.properties = Object.assign({}, dataConnector.properties, trendResult.Config, {
          defaultTitle: trendResult.Config.name || trendResult.Config.internalTag,
          description: `plcTag: ${trendResult.Config.plcTag}`,
          hiLimit:
            typeof trendResult.Config.highLimit === "number"
              ? trendResult.Config.highLimit
              : parseFloat(trendResult.Config.highLimit),
          hiHiLimit:
            typeof trendResult.Config.highHighLimit === "number"
              ? trendResult.Config.highHighLimit
              : parseFloat(trendResult.Config.highHighLimit),
          loLimit:
            typeof trendResult.Config.lowLimit === "number"
              ? trendResult.Config.lowLimit
              : parseFloat(trendResult.Config.lowLimit),
          loLoLimit:
            typeof trendResult.Config.lowLowLimit === "number"
              ? trendResult.Config.lowLowLimit
              : parseFloat(trendResult.Config.lowLowLimit)
        });
      }

      if (!isEmpty(trendResult.DataPointsPTypes) && dataConnector) {
        const pType = (first(trendResult.DataPointsPTypes) as any)?.PType ?? "";
        (dataConnector.properties as any) = { ...dataConnector.properties, pType };
      }
    }

    return connectorsWithData;
  }

  private _convertDataPoints(inputDataPoints: any[]): TimeSeriesDataPoint[] {
    return inputDataPoints.map<TimeSeriesDataPoint>((dataPoint) => {
      const convertedDataPoint: TimeSeriesDataPoint = {
        x: new Date(this.timeConverter.addLocalTimeBias(dataPoint.timeStamp)),
        y: dataPoint.value
      };
      return convertedDataPoint;
    });
  }

  // Equipment
  public getFullEquipmentTree(): Observable<Equipment> {
    return this.getEquipmentTree(this._queryStringService.getRootPath());
  }

  public getEquipmentTree(path: string): Observable<Equipment> {
    if (isNotDefined(path) || path === "" || path === "empty") {
      return of({} as Equipment);
    }

    return this.httpService
      .get({
        url: this.addCustomerAndRootEquipmentToUrl(this.apiConfig.equipmentUrl + "/equipment-tree"),
        failureDefault: []
      })
      .pipe(
        map(
          (equipmentRaw: RdsEquipment[]) =>
            this.azureEquipmentConverter.toEquipments(equipmentRaw)[0]
        )
      );
  }

  public getAllEquipmentClasses(): Observable<EquipmentClassNames[]> {
    return of([]);
  }

  public getManyEquipment(
    equipmentQueries: EquipmentDataSourceDto[],
    _pageRootPath: string
  ): Observable<DataConnectorDto[][]> {
    if (equipmentQueries.length === 0) {
      return of([]);
    }

    return this.sendEquipmentRequest(equipmentQueries).pipe(
      filter((equipmentResponse: RdsEquipmentResponse) => !!equipmentResponse),
      map((equipmentResponse: RdsEquipmentResponse) =>
        this.azureEquipmentConverter.toEquipments2(equipmentResponse.equipmentLists)
      ),
      map((equipmentList: Equipment[]) => equipmentList.map((e) => [e])),
      map((equipmentMatrix: Equipment[][]) =>
        this.equipmentToDataConnectorsConverter.convert(equipmentMatrix, equipmentQueries)
      )
    );
  }

  private sendEquipmentRequest(
    equipmentQueries: EquipmentDataSourceDto[]
  ): Observable<RdsEquipmentResponse> {
    const rootPath = this.runtimeSettingsSelector.getCurrentRootPath();
    const reportId = this.reportInfoSelector.getReportId();

    if (isNotDefined(rootPath) || rootPath === "" || rootPath === "empty") {
      if (reportId == FLEET_OVERVIEW_REPORT_ID) {
        return this.sendMultipleCustomerEquipmentRequest(equipmentQueries);
      }
      return of({} as RdsEquipmentResponse);
    }

    const request: EquipmentRequestDto = {
      RootPath: rootPath,
      PageRootPath: "",
      PageRootId: null,
      Queries: equipmentQueries.map((e) => {
        return {
          Path: e.path,
          Property: e.property,
          SearchDepth: e.searchDepth,
          Class: e.class[0]
        };
      })
    };

    return this.httpService.post({
      url: this.addCustomerAndRootEquipmentToUrl(this.apiConfig.equipmentUrl),
      body: JSON.stringify(request),
      failureDefault: {}
    });
  }

  private sendMultipleCustomerEquipmentRequest(
    equipmentQueries: EquipmentDataSourceDto[]
  ): Observable<RdsEquipmentResponse> {
    const queriesByRootEquipment: { [key: string]: EquipmentDataSourceDto[] } = {};

    equipmentQueries.forEach((query) => {
      const pathSegments = query.path.split("/");
      // FIXME Add a helper service for handling path manipulation
      const rootEquipmentPath = pathSegments[0] + "/" + pathSegments[1] + "/" + pathSegments[2];

      if (queriesByRootEquipment[rootEquipmentPath] === undefined) {
        queriesByRootEquipment[rootEquipmentPath] = [];
      }

      queriesByRootEquipment[rootEquipmentPath].push({
        ...query,
        path: query.path.substring(rootEquipmentPath.length + 1),
        signal: {
          ...query.signal
        }
      });
    });

    const requests = Object.entries(queriesByRootEquipment).map(
      ([rootEquipmentPath, rootEquipmentQueries]) => {
        const request: EquipmentRequestDto = {
          RootPath: rootEquipmentPath,
          PageRootPath: "",
          PageRootId: null,
          Queries: rootEquipmentQueries.map((e) => {
            return {
              Path: e.path,
              Property: e.property,
              SearchDepth: e.searchDepth,
              Class: e.class[0]
            };
          })
        };

        return this.httpService.post({
          url: this.addCustomerAndRootEquipmentToUrl(this.apiConfig.equipmentUrl),
          body: JSON.stringify(request),
          failureDefault: {}
        }) as Observable<RdsEquipmentResponse>;
      }
    );

    return forkJoin(requests).pipe(
      map((responses) => {
        const result: RdsEquipmentResponse = {
          rootId: "",
          equipmentLists: []
        };
        responses.forEach((response) => {
          response.equipmentLists.forEach((equipment) => {
            result.equipmentLists.push(equipment);
          });
        });
        return result;
      })
    );
  }

  // Properties
  public getEquipmentProperties(
    rootPath?: string,
    recursive?: boolean
  ): Observable<EquipmentProperty[]> {
    let relativePath: string = "";
    let queryString: string = "";
    if (typeof rootPath !== "undefined" && rootPath !== null) {
      if (!rootPath.includes("/")) {
        queryString = "?id=" + rootPath;
      } else if (rootPath.split("/").length > 3) {
        const splittedPath = rootPath.split("/");
        relativePath = splittedPath.slice(3).join("/");
        queryString = "?relativePath=" + relativePath;
      }
    }
    let recursiveSearch = false;
    if (typeof recursive !== "undefined" && recursive !== null) {
      recursiveSearch = recursive;
    }

    queryString += `${
      queryString !== "" ? "&recursive=" + recursiveSearch : "?recursive=" + recursiveSearch
    }`;
    return this.httpService
      .get({
        url: this.addCustomerAndRootEquipmentToUrl(
          this.apiConfig.equipmentPropertiesUrl + queryString,
          rootPath
        ),
        failureDefault: []
      })
      .pipe(
        map((response: RdsEquipmentPropertyResponse[]) =>
          response.map((eqProperty: RdsEquipmentPropertyResponse) =>
            this.azureEquipmentConverter.toEquipmentProperty(eqProperty.properties)
          )
        )
      );
  }

  // Signals
  public getFilteredSignalData(
    search: string,
    _caseSensitive: boolean
  ): Observable<RdsSignalDragInfo[]> {
    const rootPathBase = this.runtimeSettingsSelector.getCurrentRootPath();

    return (
      this.httpService
        // FIXME Change parameters to HttpParams
        .get({
          url: `${this.addCustomerAndRootEquipmentToUrl(
            this.apiConfig.logItemsServiceUrl,
            rootPathBase
          )}?name=${search}`
        })
        .pipe(
          map((kmLogs: any) => {
            return kmLogs.map((kmLog: any) => {
              const dataSource: SignalDataSourceDto = new SignalDataSourceDto({
                signal: {
                  id: kmLog.id,
                  name: kmLog.properties.internalTag
                }
              });
              return new RdsSignalDragInfo(dataSource, kmLog.properties);
            });
          }),
          catchError((error) => {
            this.dispatcher.dispatch(
              ErrorCatchingActions.catchError({
                messageToDisplay: error,
                error: error,
                autoClose: true
              })
            );
            return EMPTY;
          })
        )
    );
  }

  public getPeriodTypes(): Observable<PeriodType[]> {
    return of([
      {
        key: PRI_PTYPE,
        title: PRI_PTYPE
      },
      {
        key: MINUTE_PTYPE,
        title: MINUTE_PTYPE
      },
      {
        key: QUARTER_HOUR_PTYPE,
        title: QUARTER_HOUR_PTYPE
      },
      {
        key: HALF_HOUR_PTYPE,
        title: HALF_HOUR_PTYPE
      },
      {
        key: HOUR_PTYPE,
        title: HOUR_PTYPE
      },
      {
        key: DAY_PTYPE,
        title: DAY_PTYPE
      },
      {
        key: WEEK_PTYPE,
        title: WEEK_PTYPE
      }
    ]);
  }

  // GenericDataSource
  public getGenericDataSourceDescriptors(): Observable<GenericDataSourceDescriptor[]> {
    return of(GenericQueriesService.getGenericDataSourceDescriptors());
  }

  public getContinuousLookupConnectors(
    queries: Dictionary<DataSourceDto>,
    filters: Dictionary<Filter>,
    requestScope: RequestScope
  ): Observable<Dictionary<DataConnectorDto[]>> {
    // Group queries by their queryType (entity of GenericDataSourceDescriptor)
    // This part aggregates queries so for each endpoint only 1 API request is send
    // (without this part separate requests are send for each UI Component)
    const genericQueries: Dictionary<GenericDataSourceDto> = Object.keys(queries).reduce(
      (acc: Dictionary<GenericDataSourceDto>, key: string) => {
        let queryDto = _cloneDeep(queries[key]);
        if (isTabular(queryDto)) {
          queryDto = buildQueryDto(queryDto as TabularDataSourceDto);
        }
        if (isGeneric(queryDto)) {
          acc[key] = queryDto;
        }
        return acc;
      },
      {}
    );
    const queryTypes = transform(
      genericQueries,
      (result: Dictionary<Dictionary<GenericDataSourceDto>>, genericQuery, id) => {
        result[genericQuery.entity] = result[genericQuery.entity] || {};
        result[genericQuery.entity][id] = genericQuery;
      }
    );

    // Send requests in batches prepared in previous step
    const results = Object.keys(queryTypes).map((queryType) => {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const processingFunction = (
        response: DataTableResponse,
        queries: Dictionary<GenericDataSourceDto>
      ) => this.mapDataTablesToConnectors(response, queries);

      return this.genericQueriesService.getData(
        filters,
        queryTypes[queryType],
        queryType,
        processingFunction
      );
    });

    return forkJoin(results).pipe(
      map((allResults: Dictionary<DataConnectorDto[]>[]) => Object.assign({}, ...allResults))
    );
  }

  public addCustomerToUrl(url: string): string {
    const rootPath: string = this._queryStringService.getRootPath();
    const customer: string = rootPath?.split("/")[0];
    if (customer) {
      url = url.replace("{customer}", customer);
    }
    return url;
  }

  public addCustomerAndRootEquipmentToUrl(url: string, rootPath: string = ""): string {
    if (typeof rootPath === "undefined" || rootPath === null || rootPath === "") {
      rootPath = this._queryStringService.getRootPath();
    }
    const splittedPath: string[] = rootPath?.split("/");
    const customer: string = splittedPath[0];
    const rootEquipment: string = splittedPath.length > 2 ? splittedPath[2] : "all";
    if (customer) {
      url = url.replace("{customer}", customer);
    }
    if (rootEquipment) {
      url = url.replace("{rootEquipment}", rootEquipment);
    }
    return url;
  }

  public saveImage(image: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append("file", image, image.name);
    return this.httpService.postWithAutoOptions({
      url: this.addCustomerToUrl(this.apiConfig.imageUploadUrl),
      body: formData
    });
  }

  public getImage(imageUrl: Maybe<string>): Observable<string> {
    if (imageUrl == null || imageUrl === "") {
      return of("");
    }
    imageUrl = this.addCustomerToUrl(imageUrl);
    return this.httpService.get({ url: imageUrl, options: { responseType: "blob" } }).pipe(
      switchMap((blob) => {
        return new Observable<string>((observer: Observer<string>) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            const base64Result = reader.result;
            if (isString(base64Result)) {
              observer.next(base64Result);
            } else {
              observer.next("");
            }
            observer.complete();
          };
        });
      }),
      catchError((error) => {
        this.dispatcher.dispatch(
          ErrorCatchingActions.catchError({
            messageToDisplay: this.localizationService.get(
              LOCALIZATION_DICTIONARY.snackBarMessages.GettingImageError
            ),
            error: error,
            autoClose: true
          })
        );
        return of("");
      })
    );
  }
}

function buildQueryDto(dataSource: TabularDataSourceDto): TabularDataSourceDto {
  if (isNotDefined(dataSource.orderBy.direction)) {
    (dataSource.orderBy as OrderBy).direction = OrderDirection.Ascending;
  }
  if (isNotDefined(dataSource.orderBy.columnName)) {
    (dataSource.orderBy as OrderBy).columnName = dataSource.columns[0];
  }
  return dataSource;
}

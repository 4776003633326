import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit
} from "@angular/core";
import { DataConnectorDto } from "../../../data-connectivity";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { LayoutBuilder } from "../../../meta/decorators";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { NumberOfDataPointsToRequest } from "../../../meta/decorators/number-of-data-points-to-request.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { first, isDefined, Maybe } from "../../../ts-utils";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { View } from "../../decorators/view.decorator";
import { openLink } from "../../helpers/link-resolution.helper";
import { DataStatus } from "../../models/data-status";
import { SingleValueDisplayStrategy } from "../../models/display-strategies/single-value-display-strategies";
import { SINGLE_VALUE } from "../../models/element-type.constants";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { Roles } from "./roles";
import { SingleValueViewConfig } from "./view-config";

@Component({
  selector: "c-single-value",
  templateUrl: "./single-value.component.html",
  styleUrls: ["./single-value.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: SingleValueComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-bullet-chart",
  "dashboard-widgets",
  SingleValueDisplayStrategy.BulletChart,
  LOCALIZATION_DICTIONARY.propertySheet.Display_BulletChart
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-dial-gauge",
  "dashboard-widgets",
  SingleValueDisplayStrategy.DialGauge,
  LOCALIZATION_DICTIONARY.propertySheet.Display_DialGauge
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-Dial-Gauge",
  "dashboard-icon",
  SingleValueDisplayStrategy.MultiDialGauge,
  LOCALIZATION_DICTIONARY.propertySheet.Display_MultiDialGauge
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-solid-gauge",
  "dashboard-widgets",
  SingleValueDisplayStrategy.SolidGauge,
  LOCALIZATION_DICTIONARY.propertySheet.Display_SolidGauge
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-horizontal-gauge",
  "dashboard-widgets",
  SingleValueDisplayStrategy.HorizontalGauge,
  LOCALIZATION_DICTIONARY.propertySheet.Display_HorizontalGauge
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-vertical-gauge",
  "dashboard-widgets",
  SingleValueDisplayStrategy.VerticalGauge,
  LOCALIZATION_DICTIONARY.propertySheet.Display_VerticalGauge
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-value",
  "dashboard-widgets",
  SingleValueDisplayStrategy.Value,
  LOCALIZATION_DICTIONARY.propertySheet.Display_Value
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-value-in-circle",
  "dashboard-widgets",
  SingleValueDisplayStrategy.CircleValue,
  LOCALIZATION_DICTIONARY.propertySheet.Display_CircleValue
)
@LayoutBuilder(
  ComponentCategory.SingleValue,
  SINGLE_VALUE,
  "icon-value-and-trend",
  "dashboard-widgets",
  SingleValueDisplayStrategy.TrendValue,
  LOCALIZATION_DICTIONARY.propertySheet.Display_TrendValue
)
@NumberOfDataPointsToRequest(calculateNumberOfDataPointsToRequest)
@ConnectorRoles(Roles)
@EditableWidget({ fullName: SINGLE_VALUE, title: "single-value" })
export class SingleValueComponent extends BaseComponent implements OnInit {
  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef<any>,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }

  @View(SingleValueViewConfig)
  public get view(): SingleValueViewConfig {
    return this.currentState.view as SingleValueViewConfig;
  }

  public strategyEnum = SingleValueDisplayStrategy;

  public simpleView: SingleValueViewConfig;

  public valueDC: Maybe<DataConnectorDto>;

  public dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[] = [];

  protected updateDisplay(callerInfo?: string): void {
    super.updateDisplay(callerInfo);

    const evaluated = this.dynamicDefaultsEvaluator.collectAndEvaluate(
      this.view,
      this.dataAccessor
    );

    const interpolatedProperties =
      this.propertyInterpolationService.collectInterpolatedProperties<SingleValueViewConfig>(
        { ...this.currentState, view: evaluated.viewConfig },
        evaluated.connectorDescriptors
      );
    this.simpleView = interpolatedProperties.viewConfig;

    this.dataConnectorDescriptors = interpolatedProperties.connectorDescriptors.filter(
      (conDesc) => isDefined(conDesc?.connector) && conDesc.connector.role === Roles.Value.name
    );

    this.valueDC = first(this.dataConnectorDescriptors)?.connector;

    this.cdr.markForCheck();
  }

  protected onDataStatusChange(dataStatus: DataStatus): void {
    super.onDataStatusChange(dataStatus);
    this.cdr.markForCheck();
  }

  resolveLink(): void {
    openLink(this);
  }
}

export function calculateNumberOfDataPointsToRequest(strategy: string): number {
  if (strategy === SingleValueDisplayStrategy.TrendValue) {
    return 2;
  } else {
    return 1;
  }
}

import { construct } from "../../../core/services/construct.helper";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { Configurable, EditableType, EditorType, Serializable } from "../../../meta";
import { BaseViewConfigDto, ComponentCssSize } from "../../models";

const TYPE_NAME = "JackKnifeChartViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class JackKnifeChartViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  @Configurable({
    displayName: LOCALIZATION_DICTIONARY.propertySheet.TargetAvailability,
    editorType: EditorType.Number,
    validationFunction: validateTargetAvailability
  })
  @Serializable(95)
  targetAvailability!: number;

  constructor(viewConfigDto: Partial<JackKnifeChartViewConfig> = {}) {
    super();
    const defaultOverrides: Partial<JackKnifeChartViewConfig> = {
      size: getDefaultSize()
    };
    construct(this, viewConfigDto, TYPE_NAME, defaultOverrides);
  }
}

export function validateTargetAvailability(value: number): boolean {
  return value <= 100 && value >= 0;
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("500", "200");
}

import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import { Store, StoreModule } from "@ngrx/store";
import { DropHelper } from "../elements/services/drop.helper";
import { I18NModule } from "../i18n/i18n.module";
import { MaterialModule } from "../material/material.module";
import { AppInitializationServices, initApplication } from "./app-initialization";
import { SafePipe } from "./pipes/safe.pipe";
import { WebServicesConfiguration } from "./services/api.config";
import { ChartRedrawInitiatorService } from "./services/chart-redraw-initiator.service";
import { setDependencyInjector } from "./services/dependency-injector";
import { ErrorLogger } from "./services/error-logger";
import { FilterFactory } from "./services/filter/filter-factory.service";
import { GlobalErrorHandler } from "./services/global-error-handler";
import { HttpService } from "./services/http.service";
import { IDropHelper } from "./services/i-drop-helper";
import { ErrorCatchingEffects } from "./store/error-catching/error-catching.effects";
import { ErrorHandlingEffects } from "./store/error-handling/error-handling.effects";

const providers = [
  WebServicesConfiguration,
  HttpService,
  ErrorLogger,
  FilterFactory,
  ChartRedrawInitiatorService
];

@NgModule({
  declarations: [SafePipe],
  exports: [SafePipe],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    EffectsModule.forFeature([ErrorCatchingEffects, ErrorHandlingEffects]),
    FormsModule,
    MaterialModule,
    StoreModule,
    I18NModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: IDropHelper, useClass: DropHelper },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [Store, AppInitializationServices]
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    core: CoreModule,
    injector: Injector
  ) {
    if (core) {
      throw new Error(`You can't instantiate more than one Core modules`);
    }
    setDependencyInjector(injector);
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [providers]
    };
  }
}

import { Dictionary } from "../models";

export function mapDictionary<TSource, TTarget>(
  dict: Dictionary<TSource>,
  mapper: (item: TSource, key: string) => TTarget
): Dictionary<TTarget> {
  return Object.keys(dict).reduce((acc: Dictionary<TTarget>, key: string) => {
    acc[key] = mapper(dict[key], key);
    return acc;
  }, {});
}

export function filterDictionary<T>(
  dict: Dictionary<T>,
  predicate: (item: T, key: string) => boolean
): Dictionary<T> {
  return Object.keys(dict).reduce((acc: Dictionary<T>, key: string) => {
    if (predicate(dict[key], key)) {
      acc[key] = dict[key];
    }
    return acc;
  }, {});
}

export function splitDictionaryByCondition<T>(
  dict: Dictionary<T>,
  predicate: (item: T, key: string) => boolean
): [Dictionary<T>, Dictionary<T>] {
  return Object.keys(dict).reduce(
    (acc: [Dictionary<T>, Dictionary<T>], key: string) => {
      if (predicate(dict[key], key)) {
        acc[0][key] = dict[key];
      } else {
        acc[1][key] = dict[key];
      }
      return acc;
    },
    [{}, {}]
  );
}

export function toDictionary<TSource, TValue>(
  iterable: Iterable<TSource>,
  keyGetter: (source: TSource) => string | number,
  valueGetter: (source: TSource) => TValue
): Dictionary<TValue> {
  if (iterable == null) {
    return {};
  }
  const res: Dictionary<TValue> = {};
  for (const entry of iterable) {
    res[keyGetter(entry)] = valueGetter(entry);
  }
  return res;
}

export function flattenArrayDict<T>(dict: Dictionary<T[]>): T[] {
  return Object.values(dict).reduce((acc: T[], entry: T[]) => acc.concat(entry), []);
}

export function isEmptyDict(dictionary: Dictionary<any>): boolean {
  return Object.keys(dictionary).length === 0;
}

export function isNotEmptyDict(dictionary: Dictionary<any>): boolean {
  return Object.keys(dictionary).length > 0;
}

import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { GlobalTimeRangeConfigurationDto } from "../../../core/models/filter/global-time-range-configuration";
import { TimeRange } from "../../../core/models/time-range";
import { TimeRangeConfigurationDto } from "../../../core/models/time-range-configuration";
import { TimeInfoActions } from "../../../environment/store/time-info/time-info.actions";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { SelectionOption } from "../../../meta/models/selection";
import { BaseEditorComponent } from "../../../property-sheet/components/base-editor.component";
import { focusItemInput } from "../../../property-sheet/helpers/input-editor-helper";
import { BaseEditorComponentParams } from "../../../property-sheet/models/base-editor-component-params";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { resolveLiveModeFilter } from "../../helpers/live-mode-time-unit.helper";
import { getTimePresetOptions } from "../../helpers/time-filter.helper";
import {
  isEndDateTimeChanged,
  isStartDateTimeChanged,
  isTimeAmountChanged,
  isTimePresetChanged,
  isTimeUnitChanged
} from "../../helpers/time-range-editor.helper";
import { LiveModeFilter } from "../../models/live-mode-filter";
import { StandardPresets, TimePresetType } from "../../models/time-preset-filter";
import { TimePresetFilterComponent } from "../time-preset-filter/time-preset-filter.component";

@Component({
  selector: "time-range-editor",
  templateUrl: "time-range-editor.component.html",
  styleUrls: ["./time-range-editor.component.scss"]
})
@OfType(EditorType.TimeRangeEditor)
export class TimeRangeEditorComponent extends BaseEditorComponent implements OnInit {
  originalTimeRange: TimeRange = new TimeRange(new Date(), new Date());
  liveModeFilter: LiveModeFilter = new LiveModeFilter();
  timePreset: TimePresetType = StandardPresets.Last4hours;
  presetOptions: SelectionOption[] = [];
  private lastChangedElement: Maybe<ElementRef>;

  @ViewChild(TimePresetFilterComponent) timePresetFilterComponent!: TimePresetFilterComponent;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected params: BaseEditorComponentParams,
    protected hostElemet: ElementRef
  ) {
    super(hostElemet, params, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.presetOptions = getTimePresetOptions(this.translationService);
    this.timePreset = this.value.timePreset;
    const timeRangeConfig: TimeRangeConfigurationDto =
      this.filterFactory.getTimeRangeForStandardPreset(this.timePreset) ?? this.value;

    this.initCustomTimeRange(timeRangeConfig);
    this.initLiveModeFilter(timeRangeConfig);
  }

  private initCustomTimeRange(globalTimeRange: GlobalTimeRangeConfigurationDto): void {
    const timeRange: Maybe<TimeRange> =
      this.filterFactory.createStandaloneFilterTimeRange(globalTimeRange);
    if (isDefined(timeRange)) {
      this.originalTimeRange = timeRange;
    }
  }

  private initLiveModeFilter(filterConfiguration: GlobalTimeRangeConfigurationDto): void {
    this.liveModeFilter =
      this.filterFactory.createStandaloneLiveModeFilter(filterConfiguration) ??
      resolveLiveModeFilter(this.originalTimeRange);
  }

  refreshValue(value: GlobalTimeRangeConfigurationDto): void {
    super.refreshValue(value);
    let liveModeFilter = this.filterFactory.createStandaloneLiveModeFilter(value);
    const originalTimeRange = this.filterFactory.createStandaloneFilterTimeRange(value);
    this.lastChangedElement = this.resolveLastChangedElement(
      liveModeFilter,
      originalTimeRange,
      value.timePreset
    );
    this.timePreset = value.timePreset;

    if (isNotDefined(liveModeFilter) && isDefined(originalTimeRange)) {
      liveModeFilter = resolveLiveModeFilter(originalTimeRange);
    }
    this.liveModeFilter = liveModeFilter;
    this.originalTimeRange = originalTimeRange;
  }

  private resolveLastChangedElement(
    liveModeFilter: Maybe<LiveModeFilter>,
    originalTimeRange: Maybe<TimeRange>,
    timePreset: TimePresetType
  ): Maybe<ElementRef> {
    if (isTimePresetChanged(this.timePreset, timePreset)) {
      return this.timePresetFilterComponent.selectorComponent.getSelectElement();
    }
    if (isTimeAmountChanged(this.liveModeFilter, liveModeFilter)) {
      return this.timePresetFilterComponent.liveModeFilterComponent?.getTimeAmountInput();
    }
    if (isTimeUnitChanged(this.liveModeFilter, liveModeFilter)) {
      return this.timePresetFilterComponent.liveModeFilterComponent?.getSelectElement();
    }
    if (isStartDateTimeChanged(this.originalTimeRange, originalTimeRange)) {
      return this.timePresetFilterComponent.historicRangeComponent?.getStartDateTimeInput();
    }
    if (isEndDateTimeChanged(this.originalTimeRange, originalTimeRange)) {
      return this.timePresetFilterComponent.historicRangeComponent?.getEndDateTimeInput();
    }
    return null;
  }

  changeTimePreset(newTimePreset: TimePresetType): void {
    this.timePreset = newTimePreset;
    this.applyTimeRangeChanges();
  }

  updateTimePreset(newTimePreset: TimePresetType): void {
    this.timePreset = newTimePreset;
    this.dispatcher.dispatch(TimeInfoActions.updateCurrentTime({ now: new Date() }));
    this.applyTimeRangeChanges();
  }

  changeLiveModeFilter(liveModeFilter: LiveModeFilter): void {
    this.liveModeFilter = liveModeFilter;
    this.applyTimeRangeChanges();
  }

  onChangeCustomTimeRange(newTimeRange: Maybe<TimeRange>): void {
    if (isDefined(newTimeRange)) {
      this.originalTimeRange = newTimeRange;
      this.applyTimeRangeChanges();
    }
  }

  applyTimeRangeChanges(): void {
    const newTimeRangeConfiguration: GlobalTimeRangeConfigurationDto =
      this.filterFactory.computeTimeRangeByMode(
        this.timePreset,
        this.liveModeFilter,
        this.originalTimeRange
      );
    this.onValueChanged(newTimeRangeConfiguration);
  }

  focus(): void {
    focusItemInput(this.lastChangedElement?.nativeElement);
  }
}

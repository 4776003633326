<div class="app-body" [ngClass]="{ 'app-body--full-height': headerHidden }">
  <sidebar
    (toggleReportBrowser)="toggleReportBrowserSidebar()"
    [templateBuilderMode]="templateBuilderMode"
    *ngIf="(sidebarService.componentToShow | async) !== null"
    [ngClass]="{ 'element--hidden': !isSidebarOpened }"
  >
    <ng-content></ng-content>
  </sidebar>
  <div class="app-body__container">
    <runtime-filter-toolbar *ngIf="isFilterToolbarOpened"></runtime-filter-toolbar>
    <div [ngClass]="contentWrapperClass">
      <div
        class="app-body__content"
        [ngClass]="{ 'app-body__content--background-white': templateBuilderMode }"
        [ngStyle]="{
          'padding-left.px': pagePadding,
          'padding-top.px': pagePadding
        }"
      >
        <router-outlet (activate)="onRouterActivated($event)"></router-outlet>
      </div>
      <property-sheet *ngIf="isPropertySheetOpened"></property-sheet>
    </div>
  </div>
</div>

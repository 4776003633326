import { DataConnectorRoles } from "../../../data-connectivity/models";

export const Roles: DataConnectorRoles = {
  Value: {
    name: "Value",
    isDefaultRole: true
  },
  DowntimeDuration: {
    name: "Downtime Duration",
    isDefaultRole: false
  },
  DowntimeType: {
    name: "Downtime Type",
    isDefaultRole: false
  }
};

import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import Highcharts, { Point } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { DataPoint } from "../../../data-connectivity/models";
import { DataConnectorDto } from "../../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../../data-connectivity/models/data-connector-view";
import { DateFormatterService } from "../../../environment/services/date-formatter.service";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { NumberOfDataPointsToRequest } from "../../../meta/decorators/number-of-data-points-to-request.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { insertItemsIntoGroups, sortGroups } from "../../../property-sheet/helpers/groups.helper";
import { ConnectorGroupDto } from "../../../shared/models/connector-group";
import { last } from "../../../ts-utils/helpers/array.helper";
import { isEmpty } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { sortDataConnectorDescriptors } from "../../helpers/connectors.helper";
import { CLUSTER_CHART } from "../../models/help-constants";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { getPointValue } from "../../services/cluster-chart.helper";
import { DataConnectorViewSelector } from "../../services/entity-selectors/data-connector-view.selector";
import {
  PRIMARY_X_AXIS_ID,
  PRIMARY_Y_AXIS_ID
} from "../../services/highcharts/base-highcharts-options.helper";
import { truncateAndFormatLabel } from "../../services/highcharts/label-format.helper";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { ChartComponent } from "../chart/chart.component";
import { ClusterDisplayStrategies } from "./cluster-display-strategies";
import { Roles } from "./roles";
import { ClusterChartViewConfig } from "./view-config";

const NO_GROUP_SERIES_NAME = "No category";

@Component({
  selector: "c-cluster-chart",
  templateUrl: "./cluster-chart.component.html",
  styleUrls: ["./cluster-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: ClusterChartComponent }]
})
@LayoutBuilder(
  ComponentCategory.Category,
  "ClusterChartComponent",
  "icon-cluster-chart",
  "dashboard-widgets",
  ClusterDisplayStrategies.ClusteredBarChart,
  LOCALIZATION_DICTIONARY.layoutEditor.ClusterChart,
  CLUSTER_CHART
)
@ConnectorRoles(Roles)
@NumberOfDataPointsToRequest(calculateNumberOfDataPointsToRequest)
@MaxConnectors(20)
@EditableWidget({ fullName: "ClusterChartComponent", title: "cluster-chart" })
export class ClusterChartComponent extends ChartComponent {
  public Highcharts = Highcharts;
  viewConfig!: ClusterChartViewConfig;
  groups: ConnectorGroupDto[] = [];

  @View(ClusterChartViewConfig)
  public get view(): ClusterChartViewConfig {
    return this.currentState.view as ClusterChartViewConfig;
  }

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef,
    cdr: ChangeDetectorRef,
    public dateFormatterService: DateFormatterService,
    public connectorViewSelector: DataConnectorViewSelector,
    public valueFormatter: ValueFormatterService
  ) {
    super(params, hostElementRef, cdr);
  }

  protected updateChartData(): void {
    const interpolatedProperties =
      this.propertyInterpolationService.prepareAndInterpolateProperties<ClusterChartViewConfig>(
        this.currentState,
        this.dataAccessor.getAllConnectors()
      );
    this.viewConfig = interpolatedProperties.viewConfig;

    const connectorDescriptors: DataConnectorDescriptor[] =
      interpolatedProperties.connectorDescriptors
        .map((descriptor) =>
          isDefined(descriptor.connector) &&
          isDefined(descriptor.connector.dataPoints) &&
          isDefined(descriptor.connectorView)
            ? { connector: descriptor.connector, connectorView: descriptor.connectorView }
            : null
        )
        .filter(isDefined);

    this.resetChartData();
    if (isEmpty(connectorDescriptors) || isNotDefined(this.chartObject)) {
      return;
    }

    if (this.view.displayStrategy === ClusterDisplayStrategies.ClusteredMultiPointBarChart) {
      this.setMultipointData(connectorDescriptors);
    } else {
      this.setSinglePointData(connectorDescriptors);
    }
  }

  private setGroups(descriptors: DataConnectorDescriptor[]): void {
    this.groups = [];
    const connectors = descriptors.map((connectorDescriptor) => connectorDescriptor.connector);
    const filledGroups = insertItemsIntoGroups(
      this.viewConfig.groups,
      connectors ?? [],
      this.connectorViewSelector
    );
    this.groups = sortGroups(filledGroups);
  }

  private resetChartData(): void {
    if (isDefined(this.chartObject)) {
      this.chartObject.xAxis[0].setCategories([], false);
      this.chartObject.update(
        {
          series: []
        },
        true,
        true,
        false
      );
    }
  }

  getChartAxisNames(): string[] {
    return this.viewConfig.groupNames;
  }

  protected setChartOptions(): void {
    const component = this;
    //TODO: add Highcharts configuration and data from class members to options
    const options: Highcharts.Options = {
      chart: {
        zooming: { type: "xy" },
        polar: this.viewConfig.displayStrategy === ClusterDisplayStrategies.ClusteredRadialBarChart,
        inverted:
          this.viewConfig.displayStrategy === ClusterDisplayStrategies.ClusteredRadialBarChart
      },
      title: {
        text: this.viewConfig.title ?? ""
      },
      plotOptions: {
        series: {
          stacking:
            this.viewConfig.stacked ||
            this.viewConfig.displayStrategy === ClusterDisplayStrategies.ClusteredMultiPointBarChart
              ? "normal"
              : (false as any),
          dataLabels: {
            padding: 0,
            inside: true,
            rotation: 270,
            align: "center",
            verticalAlign: "middle",
            allowOverlap: true,
            style: { textOutline: "none" },
            overflow: "allow",
            formatter: function () {
              return component.valueFormatter.formatValue(
                this.y,
                component.viewConfig.displayFormat
              );
            }
          },
          dataGrouping: {
            enabled:
              this.viewConfig.displayStrategy !==
              ClusterDisplayStrategies.ClusteredMultiPointBarChart
          }
        },
        spline: { marker: { symbol: "circle" }, connectNulls: true }
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          title: {
            text: this.viewConfig.xAxisTitle
          },
          labels: {
            useHTML: true,
            formatter: truncateAndFormatLabel,
            style: { color: this.view.textColor }
          }
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          title: {
            text: this.viewConfig.yAxisTitle
          },
          labels: {
            useHTML: true,
            formatter: function () {
              return component.valueFormatter.formatValue(
                Number(this.value),
                component.viewConfig.displayFormat
              );
            },
            style: { color: this.view.textColor }
          }
        }
      ],
      tooltip: {
        shared: true,
        pointFormatter: function () {
          return component.getTooltip(this, component);
        }
      },

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal"
              },
              pane: {
                size: "70%"
              }
            }
          }
        ]
      }
    };

    this.mergeChartOptions(options);
  }

  private getTooltip(point: Point, component: ClusterChartComponent): string {
    const result = `<span style='color:${point.color}'>${point.series.name}:<b>
    ${component.valueFormatter.formatValue(point.y, component.viewConfig.displayFormat)}</b></br>`;
    return result;
  }

  private setSinglePointData(connectorDescriptors: DataConnectorDescriptor[]): void {
    this.setGroups(connectorDescriptors);
    const categories = this.getChartAxisNames();
    if (isDefined(this.chartObject)) {
      this.chartObject.xAxis[0].setCategories(categories, false);
      this.groups.forEach((group: ConnectorGroupDto) => {
        const groupDCsValues: number[] = [];
        group.items.forEach((groupItem: DataConnectorDto) => {
          if (isDefined(groupItem.dataPoints)) {
            const pointValue = getPointValue(last(groupItem.dataPoints));
            const value = isNotDefined(pointValue) ? 0 : Number(pointValue.toFixed(2));
            groupDCsValues.push(value);
          }
        });

        if (isEmpty(groupDCsValues)) {
          return;
        }

        this.chartObject?.addSeries(
          {
            name: group.id === "" ? NO_GROUP_SERIES_NAME : group.name,
            type: "column",
            data: groupDCsValues
          },
          true,
          false
        );
      });
    }
  }

  private setMultipointData(connectorDescriptors: DataConnectorDescriptor[]): void {
    sortDataConnectorDescriptors(connectorDescriptors);

    const labels = [
      ...new Set(
        connectorDescriptors
          .map((desc) =>
            (desc.connector.dataPoints ?? []).map((point) => this.getMultipointLabel(desc, point))
          )
          .flat()
      )
    ];
    connectorDescriptors.map((descriptor, index) => {
      const connector = descriptor.connector;
      const connectorView: Maybe<DataConnectorViewDto> = descriptor.connectorView;
      const connectorColor = connectorView.color;
      const seriesColor =
        isDefined(connectorColor) && !isEmpty(connectorColor)
          ? connectorColor
          : this.colorService.getSeriesColorAtIndex(index);
      const data = new Array(labels.length ?? 0).fill(null);
      connector.dataPoints?.forEach((dataPoint) => {
        const dataPointLabel = this.getMultipointLabel(descriptor, dataPoint);
        data[labels.indexOf(dataPointLabel)] = dataPoint.evaluatedValue ?? dataPoint.y;
      });
      const options = {
        name: connector.title,
        type: connectorView?.isLine ? "spline" : "column",
        grouping: false,
        data,
        color: seriesColor,
        dataLabels: {
          enabled: connectorView?.showDataLabel
        },
        zIndex: connectorView?.isLine ? 1 : 0
      } as Highcharts.SeriesOptionsType;

      this.chartObject?.addSeries(options, true, false);
    });
    this.chartObject.xAxis[0].setCategories(labels, false);
  }

  private getMultipointLabel(desc: DataConnectorDescriptor, dp: DataPoint): string {
    if (isDefined(dp.x) && !isNaN(new Date(dp.x).getDate())) {
      return this.dateFormatter.formatDate(dp.x as Date, desc.connectorView?.dateFormat);
    } else {
      return dp.x?.toString();
    }
  }
}

export function calculateNumberOfDataPointsToRequest(strategy: string): number {
  if (strategy === ClusterDisplayStrategies.ClusteredMultiPointBarChart) {
    return 50;
  }
  return 1;
}

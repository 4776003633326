import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, UrlSegment } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { DataExplorerSelector } from "../../../browsing/services/data-explorer.selector";
import { DisplayMode } from "../../../core/models/display-mode";
import { SidemenuComponents } from "../../../core/models/sidemenu-components.enum";
import { EnvironmentSelector } from "../../../environment/services/environment.selector";
import { ROOT_PADDING_LEFT_TOP } from "../../helpers/page-component.helper";
import { EQUIPMENT_MODEL, HELP_LANDING_PAGE, HISTORY_VIEW } from "../../models/help-constants";
import { ContainerRuntimeViewProperties } from "../../models/runtime-view-properties";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { SidebarService } from "../../services/sidebar.service";
import { ROOT_COMPONENT_ID } from "../../store/component-state/component-state.selectors";

export const CONTENT_WRAPPER_CLASS = "app-body__content-wrapper";
@Component({
  selector: "c-app-body",
  templateUrl: "./app-body.component.html",
  styleUrls: ["./app-body.component.scss"]
})
export class AppBodyComponent implements OnInit {
  isPropertySheetOpened: boolean = false;
  isFilterToolbarOpened: boolean = false;
  public displayMode: string;
  public DisplayMode = DisplayMode;
  public templateBuilderMode: boolean = false;
  public SidemenuComponents = SidemenuComponents;
  public contentWrapperClass = CONTENT_WRAPPER_CLASS;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  @Input() headerHidden = false;
  @Output() toggleReportBrowser: EventEmitter<any> = new EventEmitter<any>();
  isHistoryViewOpened!: boolean;
  isSignalTabSelected: boolean = false;
  isSidebarOpened: boolean = false;
  pagePadding: number = ROOT_PADDING_LEFT_TOP;

  constructor(
    private environmentSelector: EnvironmentSelector,
    private cdr: ChangeDetectorRef,
    private dataExplorerSelector: DataExplorerSelector,
    public sidebarService: SidebarService,
    public componentStateSelector: ComponentStateSelector
  ) {}

  ngOnInit(): void {
    this.subscribeToPropertySheetMode();
    this.subscribeToDisplayMode();
    this.subscribeToFilterToolbarMode();
    this.subscribeToTemplateBuilderMode();
    this.subscribeToHistoryView();
    this.subscribeToSignalTab();
    this.subscribeToSidebarVisibilityMode();
    this.registerKeyboardEvents();
    this.subscribeToPagePadding();
  }

  private subscribeToDisplayMode(): void {
    this.environmentSelector
      .selectDisplayMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((displayMode: string) => {
        this.displayMode = displayMode;
        this.cdr.detectChanges();
      });
  }

  private subscribeToPropertySheetMode(): void {
    this.environmentSelector
      .selectPropertySheetVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((propertySheetMode) => {
        this.isPropertySheetOpened = propertySheetMode;
        this.cdr.detectChanges();
      });
  }

  private subscribeToFilterToolbarMode(): void {
    this.environmentSelector
      .selectFilterToolbarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isFilterToolbarOpened) => {
        this.isFilterToolbarOpened = isFilterToolbarOpened;
      });
  }

  private subscribeToTemplateBuilderMode(): void {
    this.environmentSelector
      .selectTemplateBuilderMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((templateBuilderMode: boolean) => {
        this.templateBuilderMode = templateBuilderMode;
        this.cdr.detectChanges();
      });
  }

  private subscribeToHistoryView(): void {
    this.environmentSelector
      .selectHistoryViewVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isHistoryViewOpened: boolean) => {
        this.isHistoryViewOpened = isHistoryViewOpened;
      });
  }

  private subscribeToSignalTab(): void {
    this.dataExplorerSelector
      .selectSignalTabMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSignalTabSelected: boolean) => {
        this.isSignalTabSelected = isSignalTabSelected;
      });
  }

  private subscribeToSidebarVisibilityMode(): void {
    this.environmentSelector
      .selectSidebarVisibilityMode()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((isSidebarOpened: boolean) => {
        this.isSidebarOpened = isSidebarOpened;
      });
  }

  public toggleReportBrowserSidebar(): void {
    this.toggleReportBrowser.emit();
  }

  onRouterActivated(event: { activatedRoute: ActivatedRoute }): void {
    const urlObservable: Observable<UrlSegment[]> = event.activatedRoute.url;
  }

  private registerKeyboardEvents(): void {
    window.addEventListener("keydown", (ev) => {
      if (ev.key === "F1") {
        if (this.isHistoryViewOpened) {
          window.top?.open(HISTORY_VIEW);
        } else if (
          !this.isSignalTabSelected &&
          this.isSidebarOpened &&
          this.sidebarService.componentToShow.getValue() === SidemenuComponents.DataExplorer
        ) {
          window.top?.open(EQUIPMENT_MODEL);
        } else {
          window.top?.open(HELP_LANDING_PAGE);
        }
      }
    });
  }

  private subscribeToPagePadding(): void {
    this.componentStateSelector
      .selectComponentStateAvailability(ROOT_COMPONENT_ID)
      .pipe(
        filter((isAvailable) => isAvailable),
        switchMap(() =>
          this.componentStateSelector
            .selectComponentStateById(ROOT_COMPONENT_ID)
            .pipe(
              map(
                (component) =>
                  (component?.view.runtimeView as ContainerRuntimeViewProperties)?.padding ?? 0
              )
            )
        ),
        takeUntil(this.unsubscribeSubject$)
      )
      .subscribe((padding) => {
        this.pagePadding = padding;
      });
  }
}


import { Injectable } from "@angular/core";
import { EntityState } from "@ngrx/entity";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { ComponentStateDto } from "../../elements/models/component-state";
import { DisplayFormatDto } from "../../elements/models/display-format";
import { EntityId } from "../../meta/models/entity";
import { isDefined } from "../../ts-utils/helpers/predicates.helper";
import { UpgradeStep, UpgradeStepResult } from "../models/upgrade-step";
import { Version } from "../models/version";

@Injectable()
export class RelocateDisplayFormat implements UpgradeStep {
  name = "RelocateDisplayFormat";
  fromVersion: Version = new Version(4, 0, 17);

  perform(oldConfig: any): UpgradeStepResult {
    const componentStates = oldConfig.componentStates;
    const dataConnectorViewsState = oldConfig.dataConnectorViews;
    let isComponentStatesModified: boolean = false;
    let isDataConnectorViewsStateModified: boolean = false;
    if (isDefined(componentStates)) {
      for (const componentState of Object.values(componentStates.entities)) {
        if (
          isDefined((componentState as any).view) &&
          isDefined((componentState as any).view.displayFormat)
        ) {
          isComponentStatesModified =
            this.relocateDisplayFormat(componentStates, (componentState as any).id) ||
            isComponentStatesModified;
        }
      }
    }
    if (isDefined(dataConnectorViewsState)) {
      for (const dataConnectorView of Object.values(dataConnectorViewsState.entities)) {
        isDataConnectorViewsStateModified =
          this.upgradeConnectorViewDisplayFormat(
            dataConnectorViewsState,
            dataConnectorView as any
          ) || isDataConnectorViewsStateModified;
      }
    }
    return {
      result: oldConfig,
      modified: isComponentStatesModified || isDataConnectorViewsStateModified,
      warning: null
    };
  }

  relocateDisplayFormat(
    componentStates: EntityState<ComponentStateDto>,
    componentId: EntityId
  ): boolean {
    const component = componentStates.entities[componentId];
    let modified: boolean = false;

    if (isDefined(component) && isDefined(component.view.displayFormat)) {
      const oldDisplayFormat: string = component.view.displayFormat as any;
      component.view.displayFormat = new DisplayFormatDto({ numberFormat: oldDisplayFormat });
      modified = true;
    }
    return modified;
  }

  upgradeConnectorViewDisplayFormat(
    dataConnectorViewsState: EntityState<DataConnectorViewDto>,
    dataConnectorView: any
  ): boolean {
    let modified: boolean = false;
    if (
      isDefined(dataConnectorView) &&
      isDefined(dataConnectorView.column) &&
      isDefined(dataConnectorView.column.displayFormat)
    ) {
      const oldDisplayFormat: string = dataConnectorView.column.displayFormat;
      delete dataConnectorView.column.displayFormat;
      dataConnectorView.column = { ...dataConnectorView.column, numberFormat: oldDisplayFormat };
      dataConnectorViewsState.entities[dataConnectorView.id] = dataConnectorView;
      modified = true;
    }
    return modified;
  }
}

import { CONNECTOR_DATA_AGGREGATION_DTO } from "../../core/models/dto-type.constants";
import {
  DATA_SOURCE,
  InheritableAggregationFunction,
  isEmptyApiSource,
  isEmptyEquipmentSource
} from "../../data-connectivity";
import { getConnectorViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { sortConnectorViews } from "../../data-connectivity/helpers/data-connector-view.helper";
import { switchDataSource } from "../../data-connectivity/helpers/data-source-type.helper";
import { ConnectorsDictionaryIndexedById } from "../../data-connectivity/models/connectors-dictionary-indexed-by-id";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { DataPointDto } from "../../data-connectivity/models/data-point";
import { DataSourceDto } from "../../data-connectivity/models/data-source/data-source";
import { isEmptyGroupedSource } from "../../data-connectivity/models/data-source/grouped-data-source";
import { isEmptyTabularSource } from "../../data-connectivity/models/data-source/tabular-data-source";
import { RefreshInterval } from "../../data-connectivity/models/refresh-interval";
import { Dispatcher } from "../../dispatcher";
import { EntityId, InheritableCutOffStrategy } from "../../meta";
import { createUpdatedComponentsInfo } from "../../meta/helpers/updated-entities-info.helper";
import { Dictionary, isDefined, isEmpty, isNotDefined, Maybe } from "../../ts-utils";
import { DataStatus } from "../models/data-status";
import { DATA_CONNECTOR_DTO, DATA_CONNECTOR_VIEW_DTO } from "../models/entity-type.constants";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";
import { DataConnectorViewSelector } from "../services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../services/entity-selectors/data-connector.selector";
import { DataConnectorActions } from "../store/data-connector/data-connector.actions";

export const PSEUDO_CONNECTOR_ID_SUFIX = "-pseudo";
// FIXME This file name is too general. Rename/split this file.

export function guessDataStatusFromPoints(dataPoints: Maybe<DataPointDto[]>): DataStatus {
  if (isNotDefined(dataPoints)) {
    return DataStatus.NoDataDefined;
  } else if (isEmpty(dataPoints)) {
    return DataStatus.NoDataReceived;
  } else {
    return DataStatus.DataReceived;
  }
}

export function setDataStatusFromPoints(connector: DataConnectorDto): void {
  connector.dataStatus = guessDataStatusFromPoints(connector.dataPoints);
}

export function createConnectorsDictionaryWithEmptyDataPoints(
  connectors: Dictionary<DataConnectorDto[]>,
  dataPointStatus: DataStatus,
  isIncremental: Maybe<boolean> = null
): ConnectorsDictionaryIndexedById {
  const connectorsArray = Object.values(connectors).flat();
  return connectorsArray.reduce(
    (acc: ConnectorsDictionaryIndexedById, connector: DataConnectorDto) => {
      acc[connector.id] = new DataConnectorDto({
        ...connector,
        dataPoints: [],
        dataStatus: dataPointStatus,
        isIncrementalUpdate: isIncremental ?? connector.isIncrementalUpdate
      });
      return acc;
    },
    {}
  );
}

export function clearDataPoints(connectors: DataConnectorDto[]): DataConnectorDto[] {
  return connectors.map((connector: DataConnectorDto) => {
    connector.dataPoints = null;
    connector.dataStatus = DataStatus.NoDataDefined;
    return connector;
  });
}

export function combineAllConnectors(
  updatedConnectors: ConnectorsDictionaryIndexedById,
  allConnectors: Dictionary<DataConnectorDto[]>
): ConnectorsDictionaryIndexedById {
  const updatedConnectorsArray = Object.values(updatedConnectors).flat();
  const allConnectorsArray = Object.values(allConnectors).flat();
  return allConnectorsArray.reduce(
    (acc: ConnectorsDictionaryIndexedById, connector: DataConnectorDto) => {
      const connectorFound = updatedConnectorsArray.find((conn) => connector.id === conn.id);
      if (isNotDefined(connectorFound)) {
        acc[connector.id] = connector;
      }
      return acc;
    },
    updatedConnectors
  );
}

export function addConnectorToStore(
  dataConnectorEntity: DataConnectorDto,
  dispatcher: Dispatcher,
  parentEntityId: EntityId
): void {
  dispatcher.dispatch(
    DataConnectorActions.setContext({
      componentId: parentEntityId,
      connector: dataConnectorEntity
    }),
    {
      withSnapshot: true,
      updatedEntitiesInfo: createUpdatedComponentsInfo([parentEntityId])
    }
  );
}

export function isPseudoConnector(connectorId: EntityId): boolean {
  return connectorId.toString().includes(PSEUDO_CONNECTOR_ID_SUFIX);
}

export function getPseudoConnectorId(componentId: EntityId): EntityId {
  return componentId + PSEUDO_CONNECTOR_ID_SUFIX;
}

export function isDataSourceNotEmpty(dataConnectorQuery: DataSourceDto): boolean {
  if (dataConnectorQuery == null) {
    return false;
  }

  return switchDataSource<boolean>(dataConnectorQuery, {
    EmptyDataSourceDto: () => false,
    EquipmentDataSourceDto: (equipmentSource) => !isEmptyEquipmentSource(equipmentSource),
    GroupedDataSourceDto: (groupedSource) => !isEmptyGroupedSource(groupedSource),
    TabularDataSourceDto: (tabularSource) => !isEmptyTabularSource(tabularSource),
    ApiDataSourceDto: (apiSource) => !isEmptyApiSource(apiSource),
    ValueDataSourceDto: () => false,
    SignalDataSourceDto: () => false
  });
}

export function getMaxNumberOfRequestedDataPoints(descriptors: DataConnectorDescriptor[]): number {
  return descriptors.reduce((acc, descriptor) => {
    const numberOfRequestedDataPoints = descriptor?.connector?.numberOfRequestedDataPoints ?? 0;
    return numberOfRequestedDataPoints > acc ? numberOfRequestedDataPoints : acc;
  }, 0);
}

export function sortDataConnectorDescriptors(
  dataConnectorDescriptors: DataConnectorDescriptor[]
): void {
  const connectorViews: DataConnectorViewDto[] = dataConnectorDescriptors.map(
    (connectorDescriptor: DataConnectorDescriptor) => connectorDescriptor.connectorView
  );
  sortConnectorViews(connectorViews);
  dataConnectorDescriptors.sort(
    (a, b) => connectorViews.indexOf(a.connectorView) - connectorViews.indexOf(b.connectorView)
  );
}

export function sortConnectors(
  connectors: DataConnectorDto[],
  dataConnectorViewSelector: DataConnectorViewSelector
): void {
  const connectorViewIds = connectors.map((connector: DataConnectorDto) =>
    getConnectorViewId(connector.id)
  );
  const connectorViews = dataConnectorViewSelector.getManyByIdAsArray(connectorViewIds);
  sortConnectorViews(connectorViews);

  connectors.sort(
    (a, b) =>
      connectorViews.findIndex((view) => view.id === getConnectorViewId(a.id)) -
      connectorViews.findIndex((view) => view.id === getConnectorViewId(b.id))
  );
}

export function sumDataPointValues(
  connectorDescriptors: DataConnectorDescriptor[],
  startIndex: number
): number {
  if (!Array.isArray(connectorDescriptors) || connectorDescriptors.length <= startIndex) {
    return 0;
  }
  return connectorDescriptors.slice(startIndex).reduce((totalSum, connector) => {
    return (
      totalSum + (connector.connector?.dataPoints?.reduce((sum, point) => sum + point.y, 0) ?? 0)
    );
  }, 0);
}

export function createDefaultConnector(): DataConnectorDescriptor {
  return {
    connector: {
      typeName: DATA_CONNECTOR_DTO,
      dataPoints: [],
      role: "Value",
      refreshInterval: RefreshInterval.ThirtySeconds,
      title: "Other",
      filterId: null,
      cutOffStrategy: InheritableCutOffStrategy.Inherited,
      properties: {},
      isTimeSeries: false,
      id: "id",
      isDynamicallyCreated: true,
      numberOfRequestedDataPoints: 0,
      dataSource: {
        typeName: DATA_SOURCE,
        aggregationConfig: {
          typeName: CONNECTOR_DATA_AGGREGATION_DTO,
          materialAggregationFunction: InheritableAggregationFunction.Inherited,
          locationAggregationFunction: InheritableAggregationFunction.Inherited,
          timeAggregationFunction: InheritableAggregationFunction.Inherited,
          periodType: "",
          arrayAggregationFunction: InheritableAggregationFunction.Inherited
        }
      },
      dataStatus: DataStatus.DataReceived
    },
    connectorView: {
      typeName: DATA_CONNECTOR_VIEW_DTO,
      groupId: "",
      color: "",
      timeSeriesConfig: {
        typeName: "TimeSeriesConnectorView",
        seriesType: "Default",
        lineStyle: "Solid",
        lineWidth: 0,
        showMarker: false,
        markerRadius: 0,
        markerStyle: "Circle"
      },
      scatterSeriesConfig: {
        typeName: "ScatterConnectorView",
        seriesType: "Default",
        lineWidth: 0,
        backgroundColor: ""
      },
      column: {
        typeName: "TableConnectorView",
        width: "",
        numberFormat: "",
        isHidden: false
      },
      dateFormat: "",
      isLine: false,
      showDataLabel: false,
      id: getConnectorViewId("id"),
      axisId: "",
      order: 0,
      title: "title",
      dataValue: "",
      role: "Value"
    }
  };
}

export function getConnectorForComponentTarget(
  componentId: EntityId,
  dataConnectorSelector: DataConnectorSelector,
  dataConnectorViewSelector: DataConnectorViewSelector,
  connectorIndex: number = 0
): Maybe<DataConnectorDto> {
  const componentConnectors: DataConnectorDto[] =
    dataConnectorSelector.getForComponent(componentId);
  sortConnectors(componentConnectors, dataConnectorViewSelector);
  return componentConnectors[connectorIndex];
}

export function shouldAssignRoleToConnector(
  connectorView: Maybe<DataConnectorViewDto>,
  isDynamicConnector: boolean
): boolean {
  return isDefined(connectorView) && isDefined(connectorView.role) && isDynamicConnector;
}

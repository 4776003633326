import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { Update } from "@ngrx/entity";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { DraggedItem } from "../../../core/models/drag/dragged-item";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { isGroupable } from "../../../core/models/groupable";
import { DataConnectorDto } from "../../../data-connectivity";
import { getConnectorViewId } from "../../../data-connectivity/helpers/connector-view-id.helper";
import { switchDataSource } from "../../../data-connectivity/helpers/data-source-type.helper";
import { addConnectorToStore } from "../../../elements/helpers/connectors.helper";
import { ComponentStateDto } from "../../../elements/models/component-state";
import { isWaterfallWidget } from "../../../elements/models/component-type.helper";
import {
  COMPONENT_STATE_DTO,
  DATA_CONNECTOR_DTO
} from "../../../elements/models/entity-type.constants";
import { ComponentStateActions } from "../../../elements/store/component-state/component-state.actions";
import { DataConnectorViewActions } from "../../../elements/store/data-connector-view/data-connector-view.actions";
import { DataConnectorActions } from "../../../elements/store/data-connector/data-connector.actions";
import { OfType } from "../../../meta/decorators";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { EditorType, Entity, EntityId, TypeDescriptor } from "../../../meta/models";
import { isEntity } from "../../../meta/models/entity";
import { ConnectorGroupDto } from "../../../shared/models/connector-group";
import {
  DeepUpdate,
  Dictionary,
  Maybe,
  isDefined,
  isEmptyOrNotDefined,
  isNotDefined
} from "../../../ts-utils";
import { CriticalError } from "../../../ts-utils/models/critical-error";
import {
  addOrMoveItemToGroup,
  getItemGroupId,
  insertItemsIntoGroups,
  removeItemFromGroup,
  resolveNewGroupName
} from "../../helpers/groups.helper";
import { BaseEditorComponentParams } from "../../models/base-editor-component-params";
import { ArrayEditorComponent, swapArrayItems } from "../array-editor/array-editor.component";
import { GroupUpdate, ItemType } from "../array-editor/models";

@Component({
  selector: "entity-array-editor",
  templateUrl: "../array-editor/array-editor.component.html",
  styleUrls: ["../array-editor/array-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@OfType(EditorType.EntityArray)
export class EntityArrayEditorComponent extends ArrayEditorComponent {
  protected parentEntityId: EntityId = "";

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    protected params: BaseEditorComponentParams
  ) {
    super(elementRef, cdr, params);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (isDefined(this.itemOwner)) {
      this.setParentEntity(this.itemOwner.id);
    } else {
      throw new CriticalError("Undefined owner entity.");
    }
    this.subscribeToGroups();
    this.subscribeToGroupNameChanges();
    this.createOrRefreshArrayItemsSub();
    this.subscribeToItemOrderChanges();
  }

  private subscribeToGroups(): void {
    this.componentSelector
      .selectGroupsForComponent(this.parentEntityId)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((groups: Maybe<ConnectorGroupDto[]>) => {
        this.groups = insertItemsIntoGroups(groups ?? [], this.value, this.connectorViewSelector);
        this.cdr.markForCheck();
      });
  }

  subscribeToGroupNameChanges(): void {
    this.groupNameObserver$
      .pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.unsubscribeSubject$))
      .subscribe((groupToUpdate: GroupUpdate) => {
        const target = groupToUpdate.event.target as HTMLTextAreaElement;
        const groupIndex: number = groupToUpdate.index;
        const newGroupName: string = target.value;
        this.updateEntityGroupName(groupIndex, newGroupName);
      });
  }

  private updateEntityGroupName(index: number, newGroupName: string): void {
    if (this.isGroupable) {
      this.groups[index].name = newGroupName;
      this.updateGroups();
    }
  }

  createOrRefreshArrayItemsSub(): void {
    this.connectorSelector
      .selectForComponent(this.parentEntityId)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribeSubject$))
      .subscribe((entities) => {
        this.groups = insertItemsIntoGroups(
          this.groups ?? [],
          entities,
          this.connectorViewSelector
        );
        this.value = [...entities];
        this.onValueChanged(this.value);
      });
  }

  private subscribeToItemOrderChanges(): void {
    this.connectorViewSelector
      .selectReorderedViewsByConnectors(this.value)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((itemViews) => {
        if (!isEmptyOrNotDefined(itemViews)) {
          this.groups = insertItemsIntoGroups(
            this.groups ?? [],
            this.value,
            this.connectorViewSelector
          );
          this.cdr.markForCheck();
        }
      });
  }

  initDraggedTargetsArray(): void {
    this.groups.forEach(() => {
      this.draggedOverTargets.push(false);
    });
  }

  setDraggedOverTarget(index: number): void {
    this.draggedOverTargets[index] = true;
  }

  addArrayItem(event: Event): void {
    if (!this.typeDescriptor) {
      throw new CriticalError("Undefined type descriptor");
    }
    this.createNewEntity(this.typeDescriptor);
    event.stopPropagation();
  }

  addCustomArrayItem(event: Event): void {
    if (isNotDefined(this.typeDescriptor)) {
      throw new CriticalError("Undefined type descriptor");
    }
    this.createNewEntity(this.typeDescriptor, true);
    event.stopPropagation();
  }

  createNewEntity(entityType: TypeDescriptor, isCustom: boolean = false): Entity {
    //NOTE: currently paramterless constructor will work only for DC
    const basicEntity: Entity = new entityType.constructorFunction();
    const arrayItemTypeName: string = this.getArrayItemType().name;
    switch (arrayItemTypeName) {
      case DATA_CONNECTOR_DTO:
        const parentComponent: Maybe<ComponentStateDto> = this.findParentComponent();
        if (isDefined(parentComponent)) {
          const dataConnectorEntity: DataConnectorDto = !isCustom
            ? this.connectorFactory.createForComponentType(
                parentComponent.type,
                (basicEntity as DataConnectorDto).dataSource
              )
            : this.connectorFactory.createSumConnectorForComponentType(
                parentComponent.type,
                (basicEntity as DataConnectorDto).dataSource
              );
          addConnectorToStore(dataConnectorEntity, this.dispatcher, this.parentEntityId);
          this.propertySheetService.openOrReplaceTarget(dataConnectorEntity, parentComponent.id);
        } else {
          throw new Error("Parent component not found, " + this.parentEntityId);
        }

      default:
        return basicEntity;
    }
  }

  editArrayItem(entityToEdit: Entity, index?: number): void {
    const parentComponent: Maybe<ComponentStateDto> = this.findParentComponent();
    if (isDefined(parentComponent)) {
      this.propertySheetService.openOrReplaceTarget(entityToEdit, parentComponent.id);
    }
  }

  findParentComponent(): Maybe<ComponentStateDto> {
    return this.componentSelector.getById(this.parentEntityId);
  }

  getIconBasedOnDataSourceType(arrayItem: ItemType): string {
    switch (this.getArrayItemType().name) {
      case DATA_CONNECTOR_DTO:
        return switchDataSource<string>((arrayItem as DataConnectorDto).dataSource, {
          ApiDataSourceDto: () => "abb-icon abb-icon--small Database_new",
          EmptyDataSourceDto: () => "",
          EquipmentDataSourceDto: () => "dashboard-icon icon-Equipment",
          GroupedDataSourceDto: () => "",
          SignalDataSourceDto: () => "dashboard-icon icon-Trend",
          TabularDataSourceDto: () => "",
          ValueDataSourceDto: () => "abb-icon abb-icon--small Numericals"
        });
      default:
        return "";
    }
  }

  getItemDisplayRole(arrayItem: ItemType): string {
    return this.getArrayItemType().name === DATA_CONNECTOR_DTO
      ? (arrayItem as DataConnectorDto).role
      : "";
  }

  setParentEntity(id: EntityId): void {
    this.parentEntityId = id;
  }

  remove(entityToRemove: Entity, indexOfItemToRemove: number): void {
    super.remove(entityToRemove, indexOfItemToRemove);
    this.removeEntityFromStore(entityToRemove);
  }

  removeEntityFromStore(entityToRemove: Entity): void {
    const groupId = getItemGroupId(entityToRemove, this.connectorViewSelector);
    const group = this.getGroupById(groupId);
    if (isDefined(group)) {
      removeItemFromGroup(group, entityToRemove);
    }

    this.dispatcher.dispatch(
      DataConnectorActions.deleteOne({
        componentId: this.parentEntityId,
        connector: entityToRemove as DataConnectorDto
      }),
      {
        withSnapshot: true,
        updatedEntitiesInfo: createUpdatedComponentsInfo([this.parentEntityId])
      }
    );
  }

  areEntitiesGroupable(items: Maybe<any[]>): boolean {
    return isDefined(items) && isEntity(items[0]) && isGroupable(items[0]);
  }

  updateConnectorGroupId(item: Entity, groupId: string): void {
    this.dispatcher.dispatch(
      DataConnectorViewActions.updateOne({
        connectorViewUpdate: {
          id: getConnectorViewId(item.id).toString(),
          changes: {
            groupId
          }
        }
      })
    );
  }

  canAcceptDrop(): boolean {
    if (isNotDefined(this.dragDropService.target)) {
      return false;
    }
    switch (this.dragDropService.target.type) {
      case DraggedItemType.Component:
        return false;
      case DraggedItemType.Signal:
      case DraggedItemType.DataConnector:
      default:
        return true;
    }
  }

  drop(event: DragEvent | TouchEvent, groupId: string, index?: number): void {
    this.undoRedoService.createSnapshot({
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.parentEntityId])
    });
    this.resetDraggedOverTarget();
    const target = this.dragDropService.target;
    const groupItems = this.getGroupItemsById(groupId);
    if (isDefined(groupItems)) {
      const oldIndex: number = groupItems.findIndex((item) => item.id === target?.item.id);
      if (
        this.canSwapItems(oldIndex, index) &&
        this.getArrayItemType().name === DATA_CONNECTOR_DTO
      ) {
        swapArrayItems(groupItems, oldIndex, index);
        this.updateConnectorOrder(groupItems);
      }
    }
    if (target?.type === DraggedItemType.DataConnector) {
      this.addEntityToGroup(groupId, target.item);
    } else if (isDraggedFromSidebar(target)) {
      if (isDefined(index)) {
        this.updateConnectorOrder(groupItems);
      }
      this.dropHelper.dropConnectorOnComponent(target, this.itemOwner, groupId, index);
    }

    event.stopPropagation();
    this.dragDropService.clear();
  }

  addEntityToGroup(groupId: string, entity: DataConnectorDto): void {
    if (isDefined(entity)) {
      if (!isEntity(entity)) {
        throw new CriticalError("Item is not entity!");
      }

      this.addItemToGroup(groupId, entity);
      if (this.getArrayItemType().name === DATA_CONNECTOR_DTO) {
        this.updateConnectorGroupId(entity, groupId);
      }
    }
  }

  addItemToGroup(groupId: string, entity: DataConnectorDto): void {
    this.groups = addOrMoveItemToGroup(this.groups, groupId, entity, this.connectorViewSelector);
  }

  updateConnectorOrder(groupItems: ItemType[]): void {
    const connectorViewUpdates: Update<ItemType>[] = groupItems.map(
      (item: ItemType, index: number) => {
        return {
          id: getConnectorViewId(item.id),
          changes: { order: index }
        };
      }
    );
    this.dispatcher.dispatch(DataConnectorViewActions.updateMany({ connectorViewUpdates }));
  }

  dragStart(event: Event, item: ItemType, index?: number): void {
    const dragTarget: Maybe<DraggedItem> = this.getDragTarget(item);
    if (isDefined(dragTarget)) {
      this.dragDropService.enableDrag = true;
      this.dragDropService.setDragTarget(dragTarget, "");
    } else {
      this.dragDropService.enableDrag = false;
    }
  }

  protected getDragTarget(item: ItemType): DraggedItem {
    switch (this.getArrayItemType().name) {
      case COMPONENT_STATE_DTO: {
        return {
          type: DraggedItemType.Component,
          item: item
        };
      }
      case DATA_CONNECTOR_DTO: {
        return {
          type: DraggedItemType.DataConnector,
          item: item
        };
      }
      default:
        return {
          type: DraggedItemType.Signal,
          item: item
        };
    }
  }

  protected createNewGroup(event: Event): void {
    if (this.isGroupable && isDefined(this.defaultGroupName)) {
      event.stopPropagation();
      const newGroupName = resolveNewGroupName(this.groups, this.defaultGroupName);
      const newGroup = new ConnectorGroupDto({ name: newGroupName, order: this.groups.length });
      this.groups = [...this.groups, newGroup];
      this.updateGroups();
    }
  }

  protected onGroupDragStart(_event: Event, group: ItemType): void {
    const dragTarget: DraggedItem = { type: DraggedItemType.Group, item: group };
    this.dragDropService.enableDrag = true;
    this.dragDropService.setDragTarget(dragTarget, "");
  }

  protected onGroupDrop(_event: DragEvent | TouchEvent, index: number): void {
    this.undoRedoService.createSnapshot({
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.parentEntityId])
    });
    this.resetDraggedOverTarget();
    const target = this.dragDropService.target;
    const oldIndex: number = this.groups.findIndex((group) => group.id === target?.item.id);
    if (this.canSwapItems(oldIndex, index)) {
      swapArrayItems(this.groups, oldIndex, index);
      this.updateGroupOrder();
    }
  }

  canSwapItems(oldIndex: number, newIndex: Maybe<number>): boolean {
    return isDefined(newIndex) && oldIndex !== -1;
  }

  private updateGroupOrder(): void {
    this.groups = this.groups.map((group, index) => ({
      ...group,
      order: index
    }));
    this.updateGroups();
  }

  private updateGroups(): void {
    this.undoRedoService.createSnapshot({
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.parentEntityId])
    });
    const componentUpdate: DeepUpdate<ComponentStateDto> = {
      id: this.parentEntityId.toString(),
      changes: {
        view: { groups: this.groups }
      }
    };
    this.dispatcher.dispatch(ComponentStateActions.updateOne({ componentUpdate }));
  }

  protected removeGroup(group: ItemType, groupIndex: number): void {
    this.groups.splice(groupIndex, 1);
    this.updateGroups();
    const connectorDict: Dictionary<DataConnectorDto[]> = {};
    connectorDict[this.parentEntityId] = group.items;

    this.dispatcher.dispatch(
      DataConnectorActions.deleteMany({
        connectorsByComponent: connectorDict
      })
    );
  }

  refreshValue(value: any): void {
    this.groups = insertItemsIntoGroups(this.groups ?? [], this.value, this.connectorViewSelector);
  }

  resolveAddingCustomItem(): void {
    this.canAddCustomItem = isWaterfallWidget(this.itemOwner.type);
  }
}

function isDraggedFromSidebar(target: Maybe<DraggedItem>): boolean {
  return target?.type === DraggedItemType.Signal || target?.type === DraggedItemType.Equipment;
}

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { Actions } from "@ngrx/effects";
import { ReportBrowserSelector } from "../../browsing/services/report-browser.selector";
import { WebServicesConfiguration } from "../../core/services/api.config";
import { FilterFactory } from "../../core/services/filter/filter-factory.service";
import { IDragDropService } from "../../core/services/i-drag-drop.service";
import { IDropHelper } from "../../core/services/i-drop-helper";
import { ComponentMetadataService } from "../../data-connectivity/services/component-metadata.service";
import { DataService } from "../../data-connectivity/services/data.service";
import { DataConnectorFactory } from "../../data-connectivity/services/deserializers/data-connector-factory.service";
import { DataSourceDeserializer } from "../../data-connectivity/services/deserializers/data-source.deserializer";
import { StatusDisplayTypeDeserializer } from "../../data-connectivity/services/deserializers/status-display-type.deserializer";
import { Dispatcher } from "../../dispatcher";
import { ComponentStateSelector } from "../../elements/services/entity-selectors/component-state.selector";
import { DataConnectorViewSelector } from "../../elements/services/entity-selectors/data-connector-view.selector";
import { DataConnectorSelector } from "../../elements/services/entity-selectors/data-connector.selector";
import { LinkingWidgetService } from "../../elements/services/linking-widget.service";
import { LocalizationService } from "../../i18n/localization.service";
import { OfType, UserHelpFactory } from "../../meta/decorators";
import { Access } from "../../meta/models/access";
import { EditorType } from "../../meta/models/editor-type";
import { defaultValidationFunction } from "../../meta/models/property-descriptor";
import { PropertyInfo } from "../../meta/models/property-info";
import { TypeDescriptor } from "../../meta/models/type-descriptor";
import { ValidationFunction } from "../../meta/models/validation-function";
import { TypeProvider } from "../../meta/services/type-provider";
import { BaseTemplateDirective } from "../../shared/directives/base-template.directive";
import { UndoRedoService } from "../../shared/services/undo-redo.service";
import { isEmptyOrNotDefined2 } from "../../ts-utils/helpers/is-empty.helper";
import { isDefined, isUndefined } from "../../ts-utils/helpers/predicates.helper";
import { CriticalError } from "../../ts-utils/models/critical-error";
import { BaseEditorComponentParams } from "../models/base-editor-component-params";
import { PropertySheetService } from "../services/property-sheet.service";

@Component({
  selector: "base-editor",
  template: ""
})
@OfType(EditorType.Hidden)
export class BaseEditorComponent implements OnInit {
  private _value: any;
  private _isValid: boolean = true;
  @ViewChild("editorInput")
  editorInput?: ElementRef<HTMLInputElement>;
  @Input() public propertyInfo: PropertyInfo<unknown>;
  @Input() itemOwner: any;
  @Input() public parentInfo: any;
  public typeDescriptor: TypeDescriptor;
  private allowEmpty = true;
  public isReadOnly = false;
  public tooltip = "";
  public validationFunction: ValidationFunction = defaultValidationFunction;
  public userHelp: string | UserHelpFactory = null;
  @HostBinding("attr.BaseTemplateDirective")
  templateDirective: BaseTemplateDirective;

  @Output() public propertyValueChanged: EventEmitter<any>;
  @Output() public validateInput: EventEmitter<any>;

  @HostBinding(`class.editor__wrapper--inline`) inlineAlignment: boolean = false;

  public typeProvider: TypeProvider;
  public translationService: LocalizationService;
  public dragDropService: IDragDropService;
  public dropHelper: IDropHelper;
  public dispatcher: Dispatcher;
  public actions$: Actions;
  public componentSelector: ComponentStateSelector;
  public metadataService: ComponentMetadataService;
  public connectorViewSelector: DataConnectorViewSelector;
  public propertySheetService: PropertySheetService;
  public connectorFactory: DataConnectorFactory;
  public connectorSelector: DataConnectorSelector;
  public undoRedoService: UndoRedoService;
  public webConfigService: WebServicesConfiguration;
  public dataService: DataService;
  public reportBrowserSelector: ReportBrowserSelector;
  public linkingWidgetService: LinkingWidgetService;
  public dataSourceDeserializer: DataSourceDeserializer;
  public statusDisplayTypeDeserializer: StatusDisplayTypeDeserializer;
  public filterFactory: FilterFactory;

  constructor(
    protected hostElemet: ElementRef,
    protected params: BaseEditorComponentParams,
    protected cdr: ChangeDetectorRef
  ) {
    this.typeProvider = params.typeProvider;
    this.translationService = params.translationService;
    this.dragDropService = params.dragDropService;
    this.dropHelper = params.dropHelper;
    this.dispatcher = params.dispatcher;
    this.actions$ = params.actions$;
    this.componentSelector = params.componentSelector;
    this.metadataService = params.metadataService;
    this.connectorViewSelector = params.connectorViewSelector;
    this.propertySheetService = params.propertySheetService;
    this.connectorFactory = params.connectorFactory;
    this.connectorSelector = params.connectorSelector;
    this.undoRedoService = params.undoRedoService;
    this.webConfigService = params.webConfigService;
    this.dataService = params.dataService;
    this.reportBrowserSelector = params.reportBrowserSelector;
    this.linkingWidgetService = params.linkingWidgetService;
    this.dataSourceDeserializer = params.dataSourceDeserializer;
    this.statusDisplayTypeDeserializer = params.statusDisplayTypeDeserializer;
    this.filterFactory = params.filterFactory;
    this.propertyValueChanged = new EventEmitter<any>();
    this.validateInput = new EventEmitter<any>();
  }

  public get value(): any {
    return this._value;
  }

  @Input()
  public set value(value) {
    this._value = value;
    this.cdr.detectChanges();
  }

  public get isValid(): boolean {
    return this._isValid;
  }

  ngOnInit(): void {
    const { value, descriptor } = this.propertyInfo;
    if (isDefined(descriptor)) {
      const {
        defaultValue,
        access,
        validationFunction,
        tooltipKey,
        allowEmpty,
        inlineAlignment,
        userHelp,
        hostDirectiveType
      } = descriptor;

      this.value = isUndefined(value) ? defaultValue : value;
      this.allowEmpty = allowEmpty;
      this.isReadOnly = access === Access.ReadOnly;
      this.typeDescriptor = this.typeProvider.getDerivedType(value, descriptor);
      this.validationFunction = validationFunction;
      this.tooltip = tooltipKey !== "" ? this.translationService.get(tooltipKey) : "";
      this.inlineAlignment = inlineAlignment;
      this.userHelp = userHelp;
      if (isDefined(hostDirectiveType)) {
        this.templateDirective = new hostDirectiveType(this.hostElemet, this.params);
        this.templateDirective.init(this.itemOwner, this.params);
      }

      this.cdr.detectChanges();
    }
  }

  public onValueChanged(newValue: any): void {
    if (!this.typeDescriptor) {
      throw new CriticalError("Undefined type descriptor");
    }
    this._isValid = this.validateValue(newValue);
    if (this._isValid) {
      this.propertyValueChanged.emit(newValue);
    }
    this.cdr.markForCheck();
  }

  refreshValue(value): void {
    this.value = value;
  }

  private validateValue(value: any): boolean {
    const isEmpty = isEmptyOrNotDefined2(value);
    const isValid = this.validationFunction(value, {
      itemOwner: this.itemOwner,
      parentInfo: this.parentInfo
    });
    const validationResult = !this.allowEmpty && isEmpty ? false : isValid;
    this.validateInput.emit(validationResult);
    return validationResult;
  }

  focus(): void {
    this.editorInput?.nativeElement.focus();
  }

  generatePlaceholderText(): string {
    if (isDefined(this.propertyInfo.placeholder)) {
      if (isDefined(this.propertyInfo.placeholder.placeholderFunction)) {
        return this.propertyInfo.placeholder.placeholderFunction(this.parentInfo);
      }
      return this.propertyInfo.placeholder.combineWithRuntimeValue
        ? this.concatRuntimeValueAndPlaceholder()
        : this.propertyInfo.placeholder.text;
    }
    if (isDefined(this.propertyInfo.runtimeValue)) {
      return this.propertyInfo.runtimeValue as string;
    }

    return "";
  }

  private concatRuntimeValueAndPlaceholder(): string {
    return (
      (this.propertyInfo.runtimeValue ?? "") +
      " (" +
      this.translationService.get(this.propertyInfo.placeholder.text) +
      ")"
    );
  }
}

import { ChangeDetectorRef, Component, ElementRef, isDevMode, ViewChild } from "@angular/core";
import * as Highcharts from "highcharts";
import HC_More from "highcharts/highcharts-more";
import HC_Accessibility from "highcharts/modules/accessibility";
import HC_Boost from "highcharts/modules/boost";
import HC_Bullet from "highcharts/modules/bullet";
import HC_HeatMap from "highcharts/modules/heatmap";
import HC_NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HC_SolidGauge from "highcharts/modules/solid-gauge";
import HC_StockModule from "highcharts/modules/stock";
import HC_Tilemap from "highcharts/modules/tilemap";
import HC_Variwide from "highcharts/modules/variwide";
import { takeUntil } from "rxjs/operators";
import { ErrorCatchingActions } from "../../../core";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { HighchartsChartExtendedComponent } from "../../../shared/components/highcharts-chart-extended/highcharts-chart-extended.component";
import { isDefined } from "../../../ts-utils";
import { SizeInPx } from "../../models/size-in-px";
import {
  getCommonOptions,
  mergeChartOptions,
  setTransparentChartBackground
} from "../../services/highcharts/base-highcharts-options.helper";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { LegendComponent } from "../legend/legend.component";

const HIGHCHARTS_SIZE_CORRECTION = 2;

@Component({
  selector: "c-chart",
  template: "",
  providers: [{ provide: BaseComponent, useExisting: ChartComponent }]
})
@EditableWidget({ fullName: "ChartComponent", virtual: true })
export abstract class ChartComponent extends BaseComponent {
  @ViewChild(LegendComponent)
  public chartLegend: LegendComponent | null = null;

  @ViewChild(HighchartsChartExtendedComponent)
  public chartComponent: HighchartsChartExtendedComponent | null = null;

  protected chartObject: Highcharts.Chart | null = null;
  public Highcharts = Highcharts;
  private _chartOptions: Highcharts.Options = {};
  public constructorType = "chart";

  constructor(
    params: ComponentConstructorParams,
    hostElementRef: ElementRef<any>,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);

    HC_More(this.Highcharts);
    HC_Bullet(this.Highcharts);
    HC_HeatMap(this.Highcharts);
    HC_SolidGauge(this.Highcharts);
    HC_StockModule(this.Highcharts);
    HC_Variwide(this.Highcharts);
    HC_NoDataToDisplay(this.Highcharts);
    HC_Tilemap(this.Highcharts);
    HC_Accessibility(this.Highcharts);
    HC_Boost(this.Highcharts);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._chartOptions = this.getCommonOptions();
    // QUESTION is it needed for this to be initialized here, after super.ngOnInit?
  }

  protected initSubscriptions(): void {
    super.initSubscriptions();

    this.componentStateSelector
      .selectComponentRuntimeSize(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((componentSize: SizeInPx) => {
        if (this.hasValidChartObject() && isDefined(componentSize)) {
          this.manuallySetChartSize(componentSize);
        }
      });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.chartComponent?.errorCaught.pipe(takeUntil(this.unsubscribeSubject$)).subscribe((err) =>
      this.dispatch(
        ErrorCatchingActions.catchError({
          messageToDisplay: "Highcharts error in " + this.currentState.view.title,
          error: err,
          autoClose: true
        })
      )
    );
  }

  private static clickCallback(e: Highcharts.PointerEventObject): void {
    const chart = this as any as Highcharts.Chart;
    if (e.shiftKey) {
      console.log(JSON.stringify(chart.userOptions));
    } else {
      console.log(chart.userOptions);
    }
  }

  private getCommonOptions(): Highcharts.Options {
    const component = this;
    const runtimeSize = this.runtimeSize;
    const opt = getCommonOptions(
      {
        runtimeSize,
        title: this.currentState.view.title,
        titleFormat: this.currentState.view.titleFormat,
        dataStatus: this.currentState.componentDataStatus,
        foregroundColor: this.currentState.view.foregroundColor,
        exportingEnabled: this.currentState.view.exporting,
        disableChartAnimations: this.disableChartAnimations,
        horizontalAlignment: this.currentState.view.horizontalAlignment
      },
      this.colorService
    );

    opt.chart!.events!.load = function () {
      component.chartObject = this; // expose chart object to component in order to properly add and remove series
      if (runtimeSize != null) {
        component.manuallySetChartSize(runtimeSize);
      }
    };

    if (isDevMode()) {
      opt.chart!.events!.click = ChartComponent.clickCallback;
    }
    return opt;
  }

  protected updateDisplay(callerInfo?: string): void {
    super.updateDisplay(callerInfo);
    this.updateChartData();

    this.setChartOptions();
    setTransparentChartBackground(this.chartOptions);

    this.cdr.detectChanges();
  }

  protected hasValidChartObject(): boolean {
    return this.chartObject != null;
  }

  protected manuallySetChartSize(componentSize: SizeInPx): void {
    const animation = false;
    const { widthInPx, heightInPx } = this.getHighchartsSize(componentSize);
    this.chartObject?.setSize(
      widthInPx - HIGHCHARTS_SIZE_CORRECTION,
      heightInPx - HIGHCHARTS_SIZE_CORRECTION,
      animation
    );
  }

  // NOTE override in derivatives where chart control is smaller than component itself
  protected getHighchartsSize(componentSize: SizeInPx): SizeInPx {
    return componentSize;
  }

  public get chartOptions(): Highcharts.Options {
    return this._chartOptions;
  }

  public set chartOptions(options: any) {
    this._chartOptions = options;
  }

  protected abstract updateChartData(): void;

  protected abstract setChartOptions(): void;

  protected updateLegendItem(itemName: string, newValue: string): void {
    if (this.chartLegend != null) {
      const itemIndex: number = this.chartLegend.itemList.findIndex(
        (legendItem) => legendItem.name === itemName
      );
      if (itemIndex && itemIndex > -1) {
        this.chartLegend.itemList[itemIndex].value = newValue;
        this.chartLegend.refresh();
      }
    }
  }

  public mergeChartOptions(options: Highcharts.Options): void {
    this.chartOptions = mergeChartOptions(this.getCommonOptions(), options);
  }
}

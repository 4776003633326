import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { isEqual as _isEqual } from "lodash";
import { debounceTime, distinctUntilChanged, fromEvent, Subject, takeUntil } from "rxjs";
import { ViewMode } from "../../../../core/models/view-mode";
import { ValueFormatterService } from "../../../../core/services/value-formatter.service";
import { getConnectorIdByViewId } from "../../../../data-connectivity/helpers/connector-view-id.helper";
import { EntityId } from "../../../../meta/models/entity";
import { SimpleSingleValueViewConfig } from "../../../../shared/components/simple-single-value/view-config";
import { SimpleViewConfig } from "../../../../shared/models/simple-view-config";
import {
  Dictionary,
  first,
  isDefined,
  isEmpty,
  isEmptyDict,
  isEmptyOrNotDefined,
  isNotDefined,
  Maybe
} from "../../../../ts-utils";
import {
  calculateTableDataRange,
  extractColumnIdFromHeaderOrFooter
} from "../../../helpers/column.helper";
import { resolveFooterValue } from "../../../helpers/footer-function.helper";
import { TableCellType } from "../../../models/table/table-cell-type";
import { ColumnType, TableColumnConfig } from "../../../models/table/table-column-config";
import { TableDataRange } from "../../../models/table/table-data-range";
import { FooterRow, HeaderRow, TableRow } from "../../../models/table/table-row";
import {
  CSS_SORT_HEADER_CONTAINER,
  CSS_TABLE_PAGINATOR_CONTAINER,
  MIN_COLUMN_WIDTH,
  OFFSET_TO_HEADER
} from "../../../models/table/table.constants";
import {
  LABEL_COLUMN,
  X_COLUMN_ID
} from "../table-for-connectors/table-rendering-strategies/connector-per-column-strategy";

@Component({
  selector: "c-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {
        overlayPanelClass: CSS_TABLE_PAGINATOR_CONTAINER
      }
    }
  ]
})
export class TableComponent implements AfterViewInit, OnChanges, OnDestroy {
  _tableRows: TableRow[][] = [];
  matTableSource: MatTableDataSource<TableRow[]> = new MatTableDataSource<TableRow[]>([]);
  @Input() set tableRows(rows: TableRow[][]) {
    this._tableRows = rows;
    this.matTableSource.data = this.extractRangeData();
  }

  get tableRows(): TableRow[][] {
    return this._tableRows;
  }

  @Input() tableColumns: TableColumnConfig[] = [];
  @Input() tableColumnIds: string[] = [];
  @Input() pageSizeOptions: number[] = [];
  @Input() pageSize: number = 0;
  @Input() headerBackgroundColor: string = "#ccc";
  @Input() viewMode: ViewMode = ViewMode.EditMode;
  @Input() isFilterEnabled: boolean = false;
  @Input() alternateRowColors: boolean = false;
  @Input() tableComponentId!: EntityId;
  @Input() numberOfRequestedDataPoints: number = 0;
  @Input() showFooter: boolean = false;
  @Input() showHeader: boolean = false;
  @Input() footerRowDict: Dictionary<FooterRow> = {};
  @Input() headerRowDict: Dictionary<HeaderRow> = {};
  @Input() showPagination: boolean = false;
  @Input() tableHeight: number = 0;

  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output() sortDirectionChange: EventEmitter<any> = new EventEmitter();
  @Output() storePreviewAndInlineChanges: EventEmitter<TableColumnConfig[]> = new EventEmitter();
  @Output() loadMoreData: EventEmitter<any> = new EventEmitter();

  @ViewChild("paginator", { static: true }) paginator: Maybe<MatPaginator> = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @ViewChild("tableComponent") tableComponent: ElementRef;
  @ViewChildren("rowElement", { read: ElementRef, emitDistinctChangesOnly: true })
  rowElements: Maybe<QueryList<ElementRef>> = null;

  hidePaginator: boolean = false;
  resizeInProgress = false;
  resizeStartPoint: number = 0;
  initialColumnWidth: number = 0;
  headerRow!: HTMLTableRowElement;
  resizedColumnIndex: number = -1;
  columnToResize: Maybe<Element> = null;
  TableCellType = TableCellType;
  isSortDisabled: boolean = false;
  footerIds: string[] = [""];
  labelColumn: string = LABEL_COLUMN;
  headerIds: string[] = [""];

  private sliceOffset: number = 0;
  private currentDataRange: Maybe<TableDataRange> = null;
  private unsubscribeSubject$: Subject<void> = new Subject<void>();
  private intersectionObserver: Maybe<IntersectionObserver> = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    protected valueFormatter: ValueFormatterService
  ) {}

  ngAfterViewInit(): void {
    this.matTableSource.sort = this.sort;
    if (isDefined(this.paginator)) {
      this.matTableSource.paginator = this.paginator;
    }
    this.matTableSource.sortingDataAccessor = (row: any, headerId: string): string => {
      return isDefined(row.cells[headerId]) &&
        isDefined(row.cells[headerId].rowValue) &&
        isDefined(row.cells[headerId].cellType) &&
        row.cells[headerId].cellType !== this.TableCellType.HTML
        ? (row.cells[headerId].rowValue as string)
        : "";
    };
    this.filterTableData();
    this.determinePaginatorRangeLabel();
    this.setupNonPaginatedTable();
  }

  ngOnDestroy(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
    if (isDefined(this.intersectionObserver)) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = null;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isDefined(changes["tableColumns"])) {
      this.showOrHidePaginator();
    }

    if (!this.isFilterEnabled && this.viewMode === ViewMode.EditMode) {
      this.checkForAllDataWhenFilterIsDisabled();
    }

    if (this.checkForFooterIdsUpdate(changes)) {
      this.footerIds = Object.keys(this.footerRowDict);
      if (isEmpty(this.footerIds)) {
        this.footerIds = [""];
      }
    }

    if (this.checkForHeaderIdsUpdate(changes)) {
      const extraHeaderIds = Object.keys(this.headerRowDict);
      extraHeaderIds.shift();
      this.headerIds = isEmpty(extraHeaderIds) ? [""] : extraHeaderIds;
    }

    if (this.checkForPaginatorVisibilityUpdate(changes)) {
      this.showOrHidePaginator();
      this.matTableSource.data = this.extractRangeData();
    }

    if (isDefined(changes["tableHeight"])) {
      this.currentDataRange = calculateTableDataRange(this.tableHeight);
    }
  }

  private filterTableData(): void {
    this.matTableSource.filterPredicate = (data: any, filter: string): boolean => {
      let isMatching: boolean = false;
      let isFilterIncluded: boolean = false;
      this.tableColumns.forEach((column: TableColumnConfig) => {
        if (isDefined(data.cells[column.id]) && isDefined(data.cells[column.id].value)) {
          const value: string = this.getValue(column, data).toString();
          if (value.trim().toLocaleLowerCase().includes(filter)) {
            isFilterIncluded = true;
          }
        }
        isMatching = isFilterIncluded;
      });
      return isMatching;
    };
  }

  private determinePaginatorRangeLabel(): void {
    if (isDefined(this.paginator)) {
      this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        const start: number = page * pageSize + 1;
        const end: number = Math.min((page + 1) * pageSize, length);
        const totalCount: string = length < this.numberOfRequestedDataPoints ? `of ${length}` : "";
        return `${start} - ${end} ${totalCount}`;
      };
    }
  }

  private setupNonPaginatedTable(): void {
    this.subscribeToScrollEvent();
    this.initIntersectionObserver();
  }

  private subscribeToScrollEvent(): void {
    fromEvent(this.tableComponent.nativeElement, "scroll")
      .pipe(distinctUntilChanged(), debounceTime(200), takeUntil(this.unsubscribeSubject$))
      .subscribe(() => this.trackScrollingUpdates());
  }

  private trackScrollingUpdates(): void {
    if (this.shouldTrackLastRowVisibility()) {
      this.intersectionObserver.observe(this.rowElements.last.nativeElement);
    }
  }

  private shouldTrackLastRowVisibility(): boolean {
    return (
      !this.showPagination &&
      isDefined(this.rowElements) &&
      isDefined(this.rowElements.last) &&
      isDefined(this.intersectionObserver)
    );
  }

  private initIntersectionObserver(): void {
    const options: IntersectionObserverInit = {
      root: this.tableComponent.nativeElement,
      threshold: 0.5
    };
    this.intersectionObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.adjustRowsInNonPaginatedTable();
      }
    }, options);
  }

  private adjustRowsInNonPaginatedTable(): void {
    this.sliceOffset++;
    this.currentDataRange = calculateTableDataRange(this.tableHeight, this.sliceOffset);
    this.matTableSource.data = this.tableRows.slice(0, this.currentDataRange.endIndex - 1);
    this.cdr.detectChanges();
  }

  private checkForAllDataWhenFilterIsDisabled(): void {
    if (!_isEqual(this.matTableSource.data, this.matTableSource.filteredData)) {
      this.matTableSource.filter = "";
      this.showOrHidePaginator();
    }
  }

  private checkForFooterIdsUpdate(changes: SimpleChanges): boolean {
    return (
      isDefined(changes["footerRowDict"]) &&
      isDefined(changes["footerRowDict"].previousValue) &&
      isDefined(changes["footerRowDict"].currentValue) &&
      !_isEqual(changes["footerRowDict"].previousValue, changes["footerRowDict"].currentValue)
    );
  }

  private checkForHeaderIdsUpdate(changes: SimpleChanges): boolean {
    return (
      isDefined(changes["headerRowDict"]) &&
      isDefined(changes["headerRowDict"].previousValue) &&
      isDefined(changes["headerRowDict"].currentValue) &&
      !_isEqual(changes["headerRowDict"].previousValue, changes["headerRowDict"].currentValue)
    );
  }

  private checkForPaginatorVisibilityUpdate(changes: SimpleChanges): boolean {
    return (
      isDefined(changes["showPagination"]) &&
      isDefined(changes["showPagination"].previousValue) &&
      isDefined(changes["showPagination"].currentValue)
    );
  }

  private extractRangeData(): TableRow[][] {
    if (this.showPagination) {
      return this.tableRows;
    }
    if (isDefined(this.currentDataRange)) {
      const data =
        isDefined(this.matTableSource.sort) && isDefined(this.sort)
          ? this.matTableSource.sortData(this.tableRows, this.sort)
          : this.tableRows;

      return data.slice(0, this.currentDataRange.endIndex);
    }
    return [];
  }

  public mergeConfigs(column: TableColumnConfig, row: TableRow): Partial<SimpleViewConfig> {
    let columnConfig;
    let cellConfig;

    if (isNotDefined(column) || isNotDefined(column.cellConfig)) {
      columnConfig = {};
    } else {
      columnConfig = column.cellConfig;
    }

    if (
      isNotDefined(row) ||
      isNotDefined(row.cells[column.id]) ||
      isNotDefined(row.cells[column.id].config)
    ) {
      cellConfig = {};
    } else {
      cellConfig = row.cells[column.id].config;
    }
    return { ...columnConfig, ...cellConfig };
  }

  public getValue(column: TableColumnConfig, row: TableRow): any {
    if (isNotDefined(column) || isNotDefined(row) || isNotDefined(row.cells[column.id])) {
      return "";
    } else {
      const value = row.cells[column.id].value;
      const cellConfig: SimpleSingleValueViewConfig =
        column.cellConfig as SimpleSingleValueViewConfig;
      return this.valueFormatter.formatValue(value, cellConfig.displayFormat);
    }
  }

  public pageChanged({ pageSize }: PageEvent): void {
    this.pageSizeChange.emit(pageSize);
    if (this.isLastPage()) {
      this.loadMoreData.emit();
    }
  }

  private isLastPage(): boolean {
    return !this.paginator?.hasNextPage();
  }

  public sortDirectionChanged(): void {
    this.tableRows = this.matTableSource.sortData(this.tableRows, this.sort);
    this.isSortDisabled = false;
  }

  public drop(event: CdkDragDrop<TableRow[][]>): void {
    moveItemInArray(this.tableColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.tableColumnIds, event.previousIndex, event.currentIndex);
    this.storePreviewAndInlineChanges.emit(this.tableColumns);
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matTableSource.filter = filterValue.trim().toLocaleLowerCase();
    if (this.matTableSource.paginator) {
      this.matTableSource.paginator.firstPage();
    }
    this.showOrHidePaginator();
  }

  private showOrHidePaginator(): void {
    if (this.showPagination && isDefined(this.paginator)) {
      this.hidePaginator = this.isRowCountBelowPageLimit();
      this.matTableSource.paginator = this.paginator;
    } else {
      this.matTableSource.paginator = null;
      this.hidePaginator = true;
    }
  }

  private isRowCountBelowPageLimit(): boolean {
    const items: number = this.isFilterEnabled
      ? this.matTableSource.filteredData.length
      : this.matTableSource.data.length;
    return items / this.pageSize <= 1;
  }

  public onResizeStart(event: MouseEvent, index: number): void {
    event.preventDefault();
    const resizeHandler: any = event.target;
    this.columnToResize = resizeHandler.closest("th");
    this.resizedColumnIndex = index;
    this.resizeInProgress = true;
    this.resizeStartPoint = event.pageX;

    if (isDefined(this.columnToResize)) {
      this.headerRow = this.columnToResize.closest("tr");
      this.initialColumnWidth = this.columnToResize.clientWidth;

      this.headerRow.addEventListener("mousemove", this.onMouseMove);
      this.headerRow.addEventListener("mouseup", this.onMouseUp);
    }
  }

  onMouseMove = (event: MouseEvent) => {
    if (isDefined(this.columnToResize) && this.resizeInProgress && event.buttons) {
      const widthResizeOffset: number = event.pageX - this.resizeStartPoint;
      const newWidth: number = this.initialColumnWidth + widthResizeOffset;
      this.setAutoWidthForLastColumn();

      if (newWidth >= MIN_COLUMN_WIDTH) {
        const sortHeaderContainer = this.columnToResize.querySelector(
          "." + CSS_SORT_HEADER_CONTAINER
        );
        this.renderer.setStyle(
          sortHeaderContainer,
          "max-width",
          `${newWidth - OFFSET_TO_HEADER}px`
        );

        this.adjustColumnWidth(this.resizedColumnIndex, newWidth);
        this.cdr.detectChanges();
      }
    }
  };

  private setAutoWidthForLastColumn(): void {
    const allColumnsResized = this.tableColumns.every((column: TableColumnConfig) =>
      isDefined((column.cellConfig as SimpleSingleValueViewConfig).columnWidth)
    );
    if (allColumnsResized) {
      this.adjustColumnWidth(this.tableColumns.length - 1);
    }

    const lastColumn = this.headerRow.lastElementChild;
    if (isDefined(lastColumn)) {
      this.renderer.removeStyle(lastColumn, "width");
    }
  }

  private adjustColumnWidth(index: number, width: Maybe<number> = null): void {
    (this.tableColumns[index].cellConfig as SimpleSingleValueViewConfig).columnWidth = width;
  }

  onMouseUp = (event) => {
    if (this.resizeInProgress) {
      this.resizeInProgress = false;
      this.headerRow.removeEventListener("mousemove", this.onMouseMove);
      this.headerRow.removeEventListener("mouseup", this.onMouseUp);
      this.columnToResize = null;
      this.isSortDisabled = true;
      this.storePreviewAndInlineChanges.emit(this.tableColumns);
    }
  };

  getConnectorId(columnId: string): Maybe<string> {
    return columnId === X_COLUMN_ID || columnId === this.labelColumn
      ? null
      : getConnectorIdByViewId(columnId);
  }

  processFooterValue(footerId: string, footerColumnId: string): number | string {
    const footerRow: Maybe<FooterRow> = this.footerRowDict[footerId];

    if (
      isNotDefined(footerRow) ||
      (isDefined(footerRow) && isEmptyOrNotDefined(footerRow.function))
    ) {
      return "";
    }

    if (footerColumnId.includes(LABEL_COLUMN)) {
      return footerRow.label;
    }

    const columnId = extractColumnIdFromHeaderOrFooter(footerColumnId);
    const columnType: Maybe<ColumnType> = this.tableColumns.find(
      (column) => column.id === columnId
    )?.columnType;
    const isNumericData: boolean = isDefined(columnType) && columnType === ColumnType.Number;

    if (!isNumericData) {
      return "";
    }

    const values = this.extractCurrentPageData()
      .map((tableRow: any) => {
        return tableRow.cells[columnId]?.value ?? null;
      })
      .filter(isDefined);

    if (isEmpty(values)) {
      return "";
    }

    return Number(resolveFooterValue(footerRow.function, values).toFixed(2));
  }

  extractCurrentPageData(): TableRow[][] {
    if (isNotDefined(this.matTableSource.paginator)) {
      return this.isFilterEnabled ? this.matTableSource.filteredData : this.tableRows;
    }

    const startIndex: number =
      this.matTableSource.paginator.pageSize * this.matTableSource.paginator.pageIndex;
    const endIndex: number = this.matTableSource.paginator.pageSize + startIndex;

    let tableRows: TableRow[][] = [];
    if (this.matTableSource.filteredData.length !== 0) {
      tableRows = this.matTableSource.filteredData;
    } else {
      tableRows = this.matTableSource.data;
    }
    return tableRows.slice(startIndex, endIndex);
  }

  getHeaderFooterColumnConfig(columnId: string): SimpleSingleValueViewConfig {
    const column = this.tableColumns.find(
      (column) => column.id === extractColumnIdFromHeaderOrFooter(columnId)
    );
    return column?.cellConfig as SimpleSingleValueViewConfig;
  }

  trackItemsByIndex(i: number): number {
    return i;
  }

  isColumnSortingDisabled(columnId: string): boolean {
    return this.isSortDisabled || columnId.includes(LABEL_COLUMN);
  }

  shouldHideFooter(): boolean {
    return !this.showFooter || this.matTableSource.data.length === 0 || this.footerIds.includes("");
  }

  shouldHideHeader(): boolean {
    return this.headerIds.includes("") || !this.showHeader;
  }

  shouldHideDefaultHeader(): boolean {
    return isEmptyDict(this.headerRowDict) || !this.showHeader;
  }

  getDefaultHeaderTitle(columnId: string): string {
    const defaultHeader: Maybe<HeaderRow> = first(Object.values(this.headerRowDict));
    if (isNotDefined(defaultHeader)) {
      return "";
    }
    return (
      defaultHeader.columnIdAndTitlePairs.find(
        (idTitlePair) => extractColumnIdFromHeaderOrFooter(idTitlePair.key) === columnId
      )?.value ?? ""
    );
  }

  getHeaderRowDefinition(headerId: string): string[] {
    if (isEmpty(headerId)) {
      return [];
    }
    return this.headerRowDict[headerId].columnIdAndTitlePairs.map((idTitlePair) => idTitlePair.key);
  }
}

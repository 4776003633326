import { HttpParams } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from "@angular/core";
import { Store } from "@ngrx/store";
import { merge as _merge } from "lodash";
import moment from "moment";
import { first, take, takeUntil } from "rxjs/operators";
import { DraggedItemType, TimeRange, TimeService } from "../../../core";
import { EquipmentDragInfo } from "../../../core/models/drag/equipment-drag-info";
import { Filter } from "../../../core/models/filter/filter";
import { FilterConfigurationDto } from "../../../core/models/filter/filter-configuration";
import { KmTrendConfig, KmTrendConfigToken } from "../../../core/models/km-trend-config";
import { WebServicesConfiguration } from "../../../core/services/api.config";
import { FilterFactory } from "../../../core/services/filter/filter-factory.service";
import {
  DataConnectorDto,
  DataSourceDto,
  EQUIPMENT_DATA_SOURCE,
  EquipmentDataSourceDto,
  SignalDataSourceDto,
  VALUE_DATA_SOURCE
} from "../../../data-connectivity";
import { isSignalBased } from "../../../data-connectivity/helpers/data-source-type.helper";
import { EnvironmentName } from "../../../environment/models/environment-name";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { EntityId } from "../../../meta/models/entity";
import { isDefined } from "../../../ts-utils/helpers";
import { Maybe } from "../../../ts-utils/models";
import { ConnectorRoles } from "../../decorators/connector-roles.decorator";
import { MaxConnectors } from "../../decorators/max-connectors.decorator";
import { View } from "../../decorators/view.decorator";
import { KmGridLineStyle } from "../../models/km-grid";
import { QueryParamsResolverService } from "../../services";
import { ReportInfoState } from "../../store/report-info";
import { selectReportInfo } from "../../store/report-info/report-info.selectors";
import { BaseComponent } from "../base/base.component";
import { ComponentConstructorParams } from "../base/component-constructor-params";
import { KmTrendPublicApi } from "./km-trend-public-api";
import { Roles } from "./roles";
import { KmTrendViewConfig } from "./view-config";

@Component({
  selector: "app-km-trend",
  templateUrl: "./km-trend.component.html",
  styleUrls: ["./km-trend.component.scss"]
})
@LayoutBuilder(
  ComponentCategory.TimeSeries,
  "KmTrendComponent",
  "icon-trend",
  "dashboard-widgets",
  null,
  LOCALIZATION_DICTIONARY.layoutEditor.KmTrend
)
@ConnectorRoles(Roles)
@MaxConnectors(50)
@EditableWidget({ fullName: "KmTrendComponent", title: "km-trend" })
export class KmTrendComponent extends BaseComponent implements OnInit, AfterViewInit {
  get dateTimeFormat(): string {
    return this.dateFormatter.getDateFormatString();
  }

  private environmentName: EnvironmentName = EnvironmentName.None;
  private isReadOnly: boolean = false;

  @ViewChild("trendIframe", { static: true }) trendIframe: ElementRef<HTMLIFrameElement>;
  private trendContainer: Maybe<HTMLIFrameElement> = null;

  private alreadyAddedConnectorIds: EntityId[] = [];
  public src: string = "";
  private serverOffset: Maybe<number> = null;
  private periodType: string = "";

  constructor(
    params: ComponentConstructorParams,
    private filterFactory: FilterFactory,
    hostElementRef: ElementRef<any>,
    @Inject(KmTrendConfigToken) private kmTrendConfig: KmTrendConfig,
    private webServicesConfiguration: WebServicesConfiguration,
    private store$: Store<any>,
    private queryParamsResolver: QueryParamsResolverService,
    private timeService: TimeService,
    protected cdr: ChangeDetectorRef
  ) {
    super(params, hostElementRef, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.trendContainer = this.trendIframe.nativeElement;
  }

  ngAfterViewInit(): void {
    this.getIsReadOnly();
    this.getTrendDataSource();
    super.ngAfterViewInit();
    this.generateTrendURL();
    this.subscribeToViewAfterViewInit();
    this.subscribeToFilter();
    this.subscribeToServerOffset();
  }

  private getIsReadOnly(): void {
    this.store$
      .select(selectReportInfo)
      .pipe(take(1))
      .subscribe((reportInfo: ReportInfoState) => {
        this.isReadOnly = reportInfo.readOnly;
      });
  }

  private getTrendDataSource(): void {
    this.environmentSelector
      .selectEnvironmentName()
      .pipe(first())
      .subscribe((environmentName) => (this.environmentName = environmentName as EnvironmentName));
  }

  private generateTrendURL(): void {
    const filter = this.filterFactory.createFilterFromConfiguration(this.filter);
    if (filter == null) {
      return;
    }
    const params = this.getURLParams(filter);
    this.src = `./assets/ui-core/km-trend/trend.html?` + params.toString();

    // console.log(`KmTrend URL: ${this.src}`, params);
  }

  private getPMGUrl(rootPath: string): string {
    const customer = rootPath.split("/")[0];
    let motor = rootPath.split("/")[2];

    if (!motor) {
      motor = "all";
    }

    return `${this.webServicesConfiguration.logDataServiceUrl}/customers/${customer}/motors/${motor}/data`;
  }

  private getURLParams(filter: Filter): HttpParams {
    const endDate: string = this.shouldTrendBeInLiveMode
      ? ""
      : moment(filter.timeRange.to).format(this.dateTimeFormat);
    const startDate = moment(filter.timeRange.from).format(this.dateTimeFormat);
    const pType = this.queryParamsResolver.getDCQPeriodType(this.currentState.dataConnectorQuery);
    const isRdsEnvironment = this.environmentName === EnvironmentName.Rds;
    const rootPath = this.runtimeSettingsSelector.getCurrentRootPath();
    const rootPathMotorParamIndex = 2;
    const rootPathCustomerParamIndex = 0;
    const params = new HttpParams()
      .set("baseHref", this.kmTrendConfig.baseHRef)
      .set("dateTimeFormat", this.dateTimeFormat)
      .set("TrendType", "1")
      .set("EnvironmentName", "dashboards")
      .set("ShouldDisplayRemoveLogBtn", String(this.isReadOnly))
      .set("DataSource", isRdsEnvironment ? "azure" : "km")
      .set(
        "DataSourceUrl",
        isRdsEnvironment ? this.getPMGUrl(rootPath) : this.kmTrendConfig.dataSourceUrl
      )
      .set("Customer", rootPath ? rootPath.split("/")[rootPathCustomerParamIndex] : "")
      .set("Frequency", "PRI")
      .set("Motor", rootPath ? rootPath.split("/")[rootPathMotorParamIndex] : "")
      .set("Order", "Asc")
      .set("Top", "100")
      .set("PredicateOperator", "or")
      .set("AggregationType", "Avg")
      .set("StartDate", startDate)
      .set("EndDate", endDate)
      .set("PTypes", pType)
      .set("ServerTimeOffset", this.timeService.getCurrentOffset() ?? "");
    return params;
  }

  /** NOTE: This method will be called on 'dataConnector change' (look into BaseComponent) */
  protected updateDisplay(): void {
    super.updateDisplay();
    const environmentName = this.environmentName;
    const alreadyAddedConnectorIds = this.alreadyAddedConnectorIds;
    const allConnectorIds = this.currentState.dataConnectorIds;

    const removedConnectorIds: string[] = alreadyAddedConnectorIds
      .filter((id) => allConnectorIds.indexOf(id) < 0)
      .map((id) => String(id));

    const toMaybeAddConnectorIds: EntityId[] = allConnectorIds.filter(
      (id) => alreadyAddedConnectorIds.indexOf(id) < 0
    );

    const { logIdentifiersToBeAdded, clientIds, connectorIdsToBeAdded, properties } =
      this.extractLogsInfoToBeAdded(toMaybeAddConnectorIds);

    this.alreadyAddedConnectorIds = [
      ...alreadyAddedConnectorIds.filter((id) => !removedConnectorIds.includes(String(id))),
      ...connectorIdsToBeAdded
    ];

    this.updateTrendOrUpdateOnLoad(() =>
      this.updateTrendLogs(
        removedConnectorIds,
        logIdentifiersToBeAdded,
        clientIds,
        environmentName,
        properties
      )
    );
  }

  private extractLogsInfoToBeAdded(newConnectorIds: EntityId[]) {
    const addedConnectors: DataConnectorDto[] = this.dataConnectors.filter(
      (dataConnector: DataConnectorDto) =>
        newConnectorIds.includes(dataConnector.id) &&
        !isConstantValueConnector(dataConnector.dataSource)
    );
    const connectorIdsToBeAdded: EntityId[] = [];
    const properties: any[] = [];
    const logIdentifiersToBeAdded: (string | number)[] = [];
    const clientIds: string[] = [];
    addedConnectors
      .filter((dataConnector: DataConnectorDto) => isSignalBased(dataConnector.dataSource))
      .forEach((dataConnector) => {
        logIdentifiersToBeAdded.push((dataConnector.dataSource as SignalDataSourceDto).signal.id);
        connectorIdsToBeAdded.push(dataConnector.id);
        properties.push(dataConnector.dataSource.aggregationConfig);
        clientIds.push(String(dataConnector.id));
      });
    return { logIdentifiersToBeAdded, clientIds, connectorIdsToBeAdded, properties };
  }

  private removeLogs(removedConnectorIds: EntityId[]): void {
    removedConnectorIds.map((id) => id.toString()).forEach((id) => this.trendModule?.removeLog(id));
  }

  /** needs to be called in after view init, as it does not work in on init... */
  private subscribeToViewAfterViewInit(): void {
    this.componentStateSelector
      .selectComponentView(this.id)
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((newView: KmTrendViewConfig) => {
        this.updateTrendOrUpdateOnLoad(() => this.updateTrendConfig(newView));
      });
  }

  private subscribeToFilter(): void {
    this.componentStatePropertySelector.subscribeOnFilter((filterConfig) => {
      this.updateTrendOrUpdateOnLoad(() => this.updateTrendFilter(filterConfig));
    });
  }

  protected subscribeToPeriodType(): void {
    this.componentStatePropertySelector.subscribeOnPeriodType((periodType) => {
      this.periodType = periodType;
      this.updateTrendOrUpdateOnLoad(() => {
        const trendFilter = this.filterSelector.getByIdOrDefault(this.currentState.filterId);
        this.updateTrendFilter(trendFilter, true);
      });
      this.updateDisplay();
    });
  }

  private subscribeToServerOffset(): void {
    this.timeService
      .getCurrentOffsetObservable()
      .pipe(takeUntil(this.unsubscribeSubject$))
      .subscribe((currentOffset) => {
        if (isDefined(this.currentState) && isDefined(this.trendModule)) {
          this.serverOffset = currentOffset === 0 ? null : currentOffset;
          this.updateServerOffset(this.serverOffset);
          this.updateTrendFilter(this.filterSelector.getByIdOrDefault(this.currentState.filterId));
        }
      });
  }

  private updateServerOffset(newServerOffset: Maybe<number>): void {
    if (isDefined(this.trendModule)) {
      this.trendModule.toggleUseServerTime(newServerOffset);
    }
  }

  private updateTrendOrUpdateOnLoad(callback: () => void): void {
    if (isDefined(this.trendModule)) {
      callback();
    } else {
      // what if trendContainer is not defined...
      this.trendContainer?.addEventListener("load", callback);
    }
  }

  private updateTrendFilter(
    filterConfig: FilterConfigurationDto,
    shouldFetchData: boolean = false
  ): void {
    const newRange: TimeRange | null =
      this.filterFactory.createTimeRangeFromFilterConfig(filterConfig);

    if (isDefined(newRange) && isDefined(this.trendModule)) {
      this.trendModule.setGlobalTimeRange(
        newRange.from,
        newRange.to,
        this.shouldTrendBeInLiveMode,
        this.periodType
      );
      if (shouldFetchData) {
        this.trendModule.fetchLogData();
      }
    }
  }

  @View(KmTrendViewConfig)
  public get view(): KmTrendViewConfig {
    return this.currentState.view as KmTrendViewConfig;
  }

  private get shouldTrendBeInLiveMode(): boolean {
    const shouldBeInLiveMode = this.filterFactory.isInLiveMode(this.filter.id);
    return shouldBeInLiveMode;
  }

  private get trendModule(): Maybe<KmTrendPublicApi> {
    return (this.trendContainer?.contentWindow as any)?.trendModule as KmTrendPublicApi;
  }

  public get draggingActive(): boolean {
    return this.draggedComponentService.target != null;
  }

  private updateTrendLogs(
    removedLogClientIds: string[],
    addedLogIdentifiers: (string | number)[],
    clientIds: string[],
    environmentName: EnvironmentName,
    properties: any[]
  ): void {
    const shouldRemoveLogs: boolean = removedLogClientIds.length > 0;
    if (shouldRemoveLogs) {
      this.removeLogs(removedLogClientIds);
    }
    const shouldAddLogsToTrend = addedLogIdentifiers.length > 0;
    if (shouldAddLogsToTrend) {
      this.addLogsToTrend(addedLogIdentifiers, clientIds, environmentName, properties);
    }
    const shouldFetchData = shouldRemoveLogs || shouldAddLogsToTrend;
    if (shouldFetchData) {
      this.trendModule?.fetchLogData();
    }
  }

  private addLogsToTrend(
    logIdentifiers: (string | number)[],
    clientIds: string[],
    environmentName: EnvironmentName,
    properties: any[]
  ): void {
    switch (environmentName) {
      case EnvironmentName.Rds:
        this.trendModule?.addLogsByNameWithClientIds(logIdentifiers, clientIds, properties);
        break;
      case EnvironmentName.Km:
        this.trendModule?.addLogsByRiCodeWithClientIds(logIdentifiers, clientIds);
        break;
      default:
        console.warn("Environment is not defined.");
        break;
    }
  }

  shouldAllowDrop(): boolean {
    return this.canAcceptDrop();
  }

  canAcceptDrop(): boolean {
    const target = this.draggedComponentService.target;
    if (target != null) {
      const targetType = target.type;
      const isEquipmentProperty =
        targetType === DraggedItemType.Equipment &&
        (target.item as EquipmentDragInfo).property != null;
      if (isEquipmentProperty) {
        const equipmentProperty = (target.item as EquipmentDragInfo).property;
        const isConstantEquipmentProperty = equipmentProperty.logId == null;
        const shouldAcceptDrop = !isConstantEquipmentProperty;
        return super.canAcceptDrop() && shouldAcceptDrop;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  updateTrendConfig(view: KmTrendViewConfig): void {
    const trendModule = this.trendModule;
    let oldConfig: Object = {};
    if (isDefined(trendModule)) {
      oldConfig = trendModule.getTrendConfiguration() as Object;
    }
    const newConfig = {
      Columns: view.columns,
      Theme: view.theme,
      numberValueDisplayFormat: view.displayFormat.numberFormat,
      grid: {
        color: view.gridLineColor,
        show: view.gridShow,
        // NOTE as enum is not symmetric (key!=value) we need to send the value of enum
        style: KmGridLineStyle[view.gridLineStyle],
        width: view.gridLineWidth
      }
    };
    const updatedConfig = _merge(oldConfig, newConfig);
    this.trendModule?.updateTrendConfig(updatedConfig);
  }
}

function isConstantValueConnector(dataSource: DataSourceDto): boolean {
  const isConstantValueConnector =
    isConstantValueSource(dataSource) ||
    isConstantEquipmentSource(dataSource) ||
    isConstantSignalSource(dataSource);
  return isConstantValueConnector;
}

function isConstantValueSource(dataSource: DataSourceDto): boolean {
  return dataSource.typeName === VALUE_DATA_SOURCE;
}

function isConstantEquipmentSource(dataSource: DataSourceDto): boolean {
  return (
    dataSource.typeName === EQUIPMENT_DATA_SOURCE &&
    (dataSource as EquipmentDataSourceDto).value != null
  );
}

function isConstantSignalSource(dataSource: DataSourceDto): boolean {
  return (
    isSignalBased(dataSource) &&
    ((dataSource as SignalDataSourceDto).signal == null ||
      (dataSource as SignalDataSourceDto).signal.id == null)
  );
}

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { IName, ViewMode } from "../../core";
import { Theme } from "../../theme";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { AppSettings } from "../models/app-settings";
import {
  getEnvironmentFeature,
  selectAdvancedMode,
  selectAliasMode,
  selectAppliedAppTitle,
  selectAppSettings,
  selectClientNow,
  selectCurrentLanguage,
  selectDateFormat,
  selectDisplayMode,
  selectEnvironmentName,
  selectFilterToolbarPinMode,
  selectFilterToolbarVisibilityMode,
  selectHistoryViewVisibilityMode,
  selectPropertySheetPinMode,
  selectPropertySheetVisibilityMode,
  selectReportLoadingState,
  selectReportSavingState,
  selectServerOffset,
  selectShiftStartTime,
  selectSidebarVisibilityMode,
  selectStandalone,
  selectTemplateBuilderMode,
  selectTheme,
  selectUserName,
  selectViewMode
} from "../store/feature.selector";
import { EnvironmentState } from "../store/state";

export interface IEnvironmentSelector {
  selectEnvironmentState(): Observable<EnvironmentState>;
  selectViewMode(): Observable<ViewMode>;
  selectDisplayMode(): Observable<string>;
  selectSidebarVisibilityMode(): Observable<boolean>;
  selectPropertySheetVisibilityMode(): Observable<boolean>;
  selectFilterToolbarVisibilityMode(): Observable<boolean>;
  selectCurrentLanguage(): Observable<string>;
  selectAppSettings(): Observable<AppSettings>;
  getCurrentLanguage(): string;
  getViewMode(): ViewMode;
  selectClientNow(): Observable<Date>;
  selectServerOffset(): Observable<number>;
  selectReportLoadingState(): Observable<boolean>;
  selectEnvironmentName(): Observable<string>;
  getReportLoadingState(): boolean;
  getServerOffset(): number;
  selectReportSavingState(): Observable<boolean>;
  selectAppliedAppTitle(): Observable<IName>;
  selectPropertySheetPinMode(): Observable<boolean>;
  selectFilterToolbarPinMode(): Observable<boolean>;
  selectHistoryViewVisibilityMode(): Observable<boolean>;
  selectAdvancedMode(): Observable<boolean>;
}

@Injectable()
export class EnvironmentSelector implements IEnvironmentSelector {
  constructor(private store$: Store<any>) {}

  selectEnvironmentState(): Observable<EnvironmentState> {
    return this.store$.select(getEnvironmentFeature);
  }

  selectViewMode(): Observable<ViewMode> {
    return this.store$.select(selectViewMode);
  }

  selectDisplayMode(): Observable<string> {
    return this.store$.select(selectDisplayMode);
  }

  selectPropertySheetVisibilityMode(): Observable<boolean> {
    return this.store$.select(selectPropertySheetVisibilityMode);
  }

  selectHistoryViewVisibilityMode(): Observable<boolean> {
    return this.store$.select(selectHistoryViewVisibilityMode);
  }

  selectSidebarVisibilityMode(): Observable<boolean> {
    return this.store$.select(selectSidebarVisibilityMode);
  }

  selectFilterToolbarVisibilityMode(): Observable<boolean> {
    return this.store$.select(selectFilterToolbarVisibilityMode);
  }

  selectTheme(): Observable<Theme> {
    return this.store$.select(selectTheme);
  }

  selectAppSettings(): Observable<AppSettings> {
    return this.store$.select(selectAppSettings);
  }

  selectReportLoadingState(): Observable<boolean> {
    return this.store$.select(selectReportLoadingState);
  }

  selectReportSavingState(): Observable<boolean> {
    return this.store$.select(selectReportSavingState);
  }

  selectClientNow(): Observable<Date> {
    return this.store$.select(selectClientNow);
  }

  selectServerOffset(): Observable<number> {
    return this.store$.select(selectServerOffset);
  }

  selectEnvironmentName(): Observable<string> {
    return this.store$.select(selectEnvironmentName);
  }

  selectDateFormat(): Observable<string> {
    return this.store$.select(selectDateFormat);
  }

  selectUserName(): Observable<string> {
    return this.store$.select(selectUserName);
  }

  selectCurrentLanguage(): Observable<string> {
    return this.store$.select(selectCurrentLanguage);
  }

  selectStandalone(): Observable<boolean> {
    return this.store$.select(selectStandalone);
  }

  selectAppliedAppTitle(): Observable<IName> {
    return this.store$.select(selectAppliedAppTitle);
  }

  selectPropertySheetPinMode(): Observable<boolean> {
    return this.store$.select(selectPropertySheetPinMode);
  }

  selectFilterToolbarPinMode(): Observable<boolean> {
    return this.store$.select(selectFilterToolbarPinMode);
  }

  selectAdvancedMode(): Observable<boolean> {
    return this.store$.select(selectAdvancedMode);
  }

  selectAliasMode(): Observable<boolean> {
    return this.store$.select(selectAliasMode);
  }

  selectTemplateBuilderMode(): Observable<boolean> {
    return this.store$.select(selectTemplateBuilderMode);
  }

  selectShiftStartTime(): Observable<Maybe<string>> {
    return this.store$.select(selectShiftStartTime);
  }

  getCurrentLanguage(): string {
    let currentLanguage: string;
    this.selectCurrentLanguage()
      .pipe(first())
      .subscribe((language: string) => (currentLanguage = language));
    return currentLanguage;
  }

  getReportLoadingState(): boolean {
    let reportLoadingState: boolean = false;
    this.selectReportLoadingState()
      .pipe(first())
      .subscribe((state: boolean) => (reportLoadingState = state));
    return reportLoadingState;
  }

  getServerOffset(): number {
    let serverOffset: number;
    this.selectServerOffset()
      .pipe(first())
      .subscribe((offset) => {
        serverOffset = offset;
      });
    return serverOffset;
  }

  getViewMode(): ViewMode {
    let viewMode: ViewMode;
    this.selectViewMode()
      .pipe(first())
      .subscribe((mode) => {
        viewMode = mode;
      });
    return viewMode;
  }

  getHistoryViewMode(): boolean {
    let historyViewMode: boolean;
    this.selectHistoryViewVisibilityMode()
      .pipe(first())
      .subscribe((mode) => {
        historyViewMode = mode;
      });
    return historyViewMode;
  }

  getPropertySheetVisibilityMode(): boolean {
    let propertySheetVisibilityMode: boolean;
    this.selectPropertySheetVisibilityMode()
      .pipe(first())
      .subscribe((mode) => {
        propertySheetVisibilityMode = mode;
      });
    return propertySheetVisibilityMode;
  }

  getSidebarVisibilityMode(): boolean {
    let sidebarVisibilityMode: boolean;
    this.selectSidebarVisibilityMode()
      .pipe(first())
      .subscribe((mode) => {
        sidebarVisibilityMode = mode;
      });
    return sidebarVisibilityMode;
  }

  getPropertySheetPinMode(): boolean {
    let propertySheetPinMode: boolean;
    this.selectPropertySheetPinMode()
      .pipe(first())
      .subscribe((pinMode) => {
        propertySheetPinMode = pinMode;
      });
    return propertySheetPinMode;
  }

  getAliasMode(): boolean {
    let aliasMode: boolean = false;
    this.selectAliasMode()
      .pipe(first())
      .subscribe((mode) => {
        aliasMode = mode;
      });
    return aliasMode;
  }

  getTemplateBuilderMode(): boolean {
    let templateBuilderMode: boolean = false;
    this.selectTemplateBuilderMode()
      .pipe(first())
      .subscribe((mode) => {
        templateBuilderMode = mode;
      });
    return templateBuilderMode;
  }
}

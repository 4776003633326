import { HEARTBEAT_INTERVAL_IN_SECONDS } from "../../elements/helpers/heartbeat.helper";
import { RefreshInterval } from "../models/refresh-interval";

export const GENERIC_REFRESH_HEARTBEAT_RATE = 6;

export function getConnectorsRefreshIntervalRatio(
  connectorsRefreshInterval: RefreshInterval
): number {
  return getRefreshIntervalInSeconds(connectorsRefreshInterval) / HEARTBEAT_INTERVAL_IN_SECONDS;
}

function getRefreshIntervalInSeconds(refreshInterval: RefreshInterval): number {
  switch (refreshInterval) {
    case RefreshInterval.FiveSeconds:
      return 5;
    case RefreshInterval.FifteenSeconds:
      return 15;
    case RefreshInterval.ThirtySeconds:
      return 30;
    case RefreshInterval.OneMinute:
      return 60;
    case RefreshInterval.TwoMinutes:
      return 120;
    case RefreshInterval.FiveMinutes:
      return 300;
    default:
      throw new Error(`Refresh interval ${refreshInterval} not supported.`);
  }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { cloneDeep as _cloneDeep, difference as _difference } from "lodash";
import { combineLatest, of, zip } from "rxjs";
import { distinctUntilChanged, filter, map, mergeMap, startWith, switchMap } from "rxjs/operators";
import { IFilterSelector } from "../../../core/services/filter/i-filter.selector";
import {
  ComponentMetadataService,
  ConnectorsDictionaryIndexedById,
  DataConnectorDto,
  EquipmentDataSourceDto,
  SignalInfoDto
} from "../../../data-connectivity";
import { DataSourceDto } from "../../../data-connectivity/models/data-source/data-source";
import { ConnectorContextService } from "../../../data-connectivity/services/connector-context.service";
import { EnvironmentSelector } from "../../../environment";
import { EntityId } from "../../../meta/models/entity";
import {
  DeepPartial,
  Dictionary,
  Maybe,
  assertIsDefined,
  isDefined,
  isEmpty,
  isEmptyDict,
  isNotDefined,
  isNotEmptyDict,
  toDictionary
} from "../../../ts-utils";
import { debugLog } from "../../../ts-utils/helpers/conditional-logging";
import { Roles } from "../../components/single-value/roles";
import { SingleValueViewConfig } from "../../components/single-value/view-config";
import { equipmentDcqNeedsUpdate } from "../../helpers/connector-resolution.helper";
import { getComponentStatusUpdatesIfNeeded } from "../../helpers/resolve-data-status.helper";
import { ComponentStateDto } from "../../models/component-state";
import { isSingleValue } from "../../models/component-type.helper";
import { DataStatus } from "../../models/data-status";
import { isMultiDialGauge } from "../../models/display-strategies/display-strategy-type.helper";
import { RequestScope } from "../../models/request-scope";
import { ConnectorsReplaceInfo } from "../../models/store/connectors-replace-info";
import {
  getConnectorWithContext,
  getConnectorsWithContext
} from "../../services/connector-context.helper";
import {
  ConnectorResolverService,
  DataConnectorQueryResolutionDetail
} from "../../services/connector-resolver.service";
import { DataConnectorQueryService } from "../../services/data-connector-query.service";
import { DataConnectorReplacementService } from "../../services/data-connector-replacement.service";
import { ComponentStateSelector } from "../../services/entity-selectors/component-state.selector";
import { DataConnectorSelector } from "../../services/entity-selectors/data-connector.selector";
import { RuntimeSettingsSelector } from "../../services/entity-selectors/runtime-settings.selector";
import { CommonActions } from "../common/common.actions";
import { ComponentStateActions } from "../component-state/component-state.actions";
import { DataConnectorActions } from "./data-connector.actions";

@Injectable()
export class DataConnectorEffects {
  constructor(
    private actions$: Actions,
    private componentStateSelector: ComponentStateSelector,
    private dataConnectorSelector: DataConnectorSelector,
    private filterSelector: IFilterSelector,
    private connectorResolver: ConnectorResolverService,
    private dcqService: DataConnectorQueryService,
    private connectorContextService: ConnectorContextService,
    private dataConnectorReplacementService: DataConnectorReplacementService,
    private environmentSelector: EnvironmentSelector,
    private runtimeSettingsSelector: RuntimeSettingsSelector,
    private metadataProvider: ComponentMetadataService
  ) {}

  // #region ADD/REPLACE ONE

  $resolveEquipmentConnector = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.resolveEquipmentConnector),
      switchMap(({ componentId, connector, groupId, order }) => {
        const resolvingConnector = this.connectorResolver.resolveEquipmentConnectors(
          [connector],
          this.runtimeSettingsSelector.getCurrentRootPath()
        );
        return resolvingConnector.queries$.pipe(
          map((resolvedConnectors) =>
            DataConnectorActions.setContext({
              componentId,
              connector: resolvedConnectors[0],
              groupId,
              order
            })
          )
        );
      })
    )
  );

  $setContext = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.setContext),
      map(({ componentId, connector, groupId, order }) => {
        const ownerComponent: ComponentStateDto = this.componentStateSelector.getById(componentId);
        const connectorWithContext = getConnectorWithContext(
          connector,
          ownerComponent,
          this.connectorContextService
        );

        const connectorToReplace = isDefined(order)
          ? this.getConnectorAfterDropOnPropertySheet(order, ownerComponent)
          : this.getConnectorAfterDropOnWidget(groupId, ownerComponent);
        if (isDefined(connectorToReplace)) {
          return DataConnectorActions.replaceOne({
            componentId: componentId,
            oldConnector: connectorToReplace,
            newConnector: connectorWithContext,
            order
          });
        } else {
          return DataConnectorActions.addOne({
            componentId: componentId,
            connector: connectorWithContext,
            groupId: groupId
          });
        }
      })
    )
  );

  getConnectorAfterDropOnPropertySheet(
    order: number,
    ownerComponent: ComponentStateDto
  ): Maybe<DataConnectorDto> {
    const existingConnectorId = ownerComponent.dataConnectorIds[order];
    const connectorToReplace: Maybe<DataConnectorDto> = isDefined(existingConnectorId)
      ? this.dataConnectorSelector.getById(existingConnectorId)
      : findConnectorToReplace(ownerComponent, this.dataConnectorSelector);
    return connectorToReplace;
  }

  getConnectorAfterDropOnWidget(
    groupId: string,
    ownerComponent: ComponentStateDto
  ): Maybe<DataConnectorDto> {
    if (this.shoudReplaceConnector(ownerComponent, groupId)) {
      return findConnectorToReplace(ownerComponent, this.dataConnectorSelector);
    }
  }

  shoudReplaceConnector(componentState: ComponentStateDto, groupId: string): boolean {
    const maxConnectors = this.metadataProvider.getMaxConnectors(componentState.type);
    const dataConnectorsCount = componentState.dataConnectorIds.length;
    if (isMultiDialGauge((componentState.view as SingleValueViewConfig).displayStrategy)) {
      return false;
    }
    return (
      (isNotDefined(groupId) && maxConnectors === 1 && dataConnectorsCount > 0) ||
      (!isSingleValue(componentState.type) && dataConnectorsCount >= maxConnectors)
    );
  }

  addOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.addOne),
      map(({ connector }) => CommonActions.getFullRangeSignalData({ connectors: [connector] }))
    )
  );

  replaceOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.replaceOne),
      map(({ newConnector }) =>
        CommonActions.getFullRangeSignalData({ connectors: [newConnector] })
      )
    )
  );

  // #endregion

  // #region UPDATE ONE

  updateOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.updateOne),
      map(({ connectorUpdate }) => {
        const updatedConnector: DataConnectorDto = this.getConnectorFromUpdate(connectorUpdate);
        const updatedDataSource: DataSourceDto = updatedConnector.dataSource;
        const dataSourceUpdate: DeepPartial<DataSourceDto> = _cloneDeep(
          connectorUpdate.changes.dataSource
        ) as DeepPartial<DataSourceDto>;

        const updatedTitle: Maybe<string> = connectorUpdate.changes.title;
        if (isDefined(updatedTitle)) {
          return CommonActions.doNothing();
        }

        if (isDefined(dataSourceUpdate)) {
          dataSourceUpdate.typeName = updatedDataSource.typeName;
        }

        const equipmentDcqNeedsResolution: boolean =
          isDefined(dataSourceUpdate) &&
          equipmentDcqNeedsUpdate(dataSourceUpdate, updatedDataSource);

        if (equipmentDcqNeedsResolution) {
          return DataConnectorActions.resolveUpdatedEquipmentConnector({
            connector: updatedConnector
          });
        } else {
          return CommonActions.getFullRangeSignalData({ connectors: [updatedConnector] });
        }
      })
    )
  );

  resolveUpdatedEquipmentConnector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.resolveUpdatedEquipmentConnector),
      switchMap(({ connector }) => {
        const resolvingEquipmentConnectors = this.connectorResolver.resolveEquipmentConnectors(
          [connector],
          this.runtimeSettingsSelector.getCurrentRootPath()
        );
        const setLoadingStatus = DataConnectorActions.updateDataStatusMany({
          dataConnectorStatusDic: toDictionary(
            resolvingEquipmentConnectors.beingQueried,
            (conn) => conn.id,
            (_conn) => DataStatus.WaitingForData
          ),
          dataConnectorQueryStatusDic: {}
        });
        return resolvingEquipmentConnectors.queries$.pipe(
          map((connectors) => {
            const connector = connectors[0];
            const newSignalInfo: SignalInfoDto = (connector.dataSource as EquipmentDataSourceDto)
              .signal;

            connector.dataSource = {
              ...connector.dataSource,
              signal: newSignalInfo
            } as EquipmentDataSourceDto;

            return DataConnectorActions.updateMany({
              connectorUpdates: [{ id: connector.id.toString(), changes: connector }]
            });
          }),
          startWith(setLoadingStatus)
        );
      })
    )
  );

  // #endregion

  // #region UPDATE MANY

  $setContextMany = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.setContextMany),
      map(({ componentId, connectors }) => {
        const ownerComponent = this.componentStateSelector.getById(componentId);
        assertIsDefined(ownerComponent);
        const connectorsWithContext = getConnectorsWithContext(
          connectors,
          ownerComponent,
          this.connectorContextService
        );
        const connectorUpdates = getUpdateObjects(connectorsWithContext);
        return DataConnectorActions.updateMany({ connectorUpdates });
      })
    )
  );

  updateMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.updateMany),
      map(({ connectorUpdates }) => {
        const connectors = this.getConnectorsFromUpdates(connectorUpdates);
        return CommonActions.getFullRangeSignalData({ connectors });
      })
    )
  );

  // #endregion
  // #region REPLACE MANY DYNAMIC WITH GROUPID

  resolveDraggedEquipmentToGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.resolveDraggedEquipmentToGroup),
      map(({ componentUpdate, groupId }) => {
        const component: Maybe<ComponentStateDto> = this.componentStateSelector.getById(
          componentUpdate.id
        );
        return DataConnectorActions.resolveEquipmentQuery({ component, groupId });
      })
    )
  );

  // #endregion
  // #region REPLACE MANY DYNAMIC

  resolveEquipmentQueryToConnectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.resolveEquipmentQuery),
      switchMap(({ component, groupId }) => {
        const rootPath = this.runtimeSettingsSelector.getCurrentRootPath();
        const resolvingEquipmentQueryDetail = this.connectorResolver.resolveEquipmentQueries(
          [component],
          rootPath
        );
        const setLoadingStatus = DataConnectorActions.updateDataStatusMany({
          dataConnectorStatusDic: {},
          dataConnectorQueryStatusDic: toDictionary(
            resolvingEquipmentQueryDetail.beingQueried,
            (compId) => compId,
            (_compId) => DataStatus.WaitingForData
          )
        });
        return resolvingEquipmentQueryDetail.queries$.pipe(
          map((resolvedConnectorDict) => {
            const updatedConnectors: DataConnectorDto[] =
              resolvedConnectorDict[component.id.toString()];

            return DataConnectorActions.setContextManyDynamic({
              componentId: component.id,
              connectors: updatedConnectors,
              groupId
            });
          }),
          startWith(setLoadingStatus)
        );
      })
    )
  );

  setContextManyDynamic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.setContextManyDynamic),
      map(({ componentId, connectors, groupId }) => {
        const connectorsReplaceInfo =
          this.dataConnectorReplacementService.createComponentDynamicConnectorReplacementInfo(
            componentId,
            _cloneDeep(connectors),
            this.connectorContextService
          );
        connectorsReplaceInfo.dcqDataStatus = DataStatus.DataReceived;
        return DataConnectorActions.replaceMany({
          componentId,
          connectorsReplaceInfo,
          groupId
        });
      })
    )
  );

  replaceDynamic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.replaceMany),
      map(({ componentId, connectorsReplaceInfo }) => {
        const connectors = connectorsReplaceInfo.newConnectors;
        if (isEmpty(connectors)) {
          const componentStatusDict: Dictionary<DataStatus> = {};
          componentStatusDict[componentId] = DataStatus.NoDataReceived;
          return ComponentStateActions.updateComponentStatusMany({
            componentStates: componentStatusDict
          });
        } else {
          return CommonActions.getFullRangeSignalData({
            connectors
          });
        }
      })
    )
  );

  // TODO Check if replacing connectors is needed; if not, just update data
  $resolveFullGenericQueries = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.resolveFullGenericQueries),
      mergeMap(({ componentsWithQuery: componentStates }) => {
        const resolvingDynamicGenericConnectors = this.resolveGenericQueries(
          componentStates,
          RequestScope.FullRange
        );
        const setLoadingStatusDict = toDictionary(
          resolvingDynamicGenericConnectors.beingQueried,
          (id) => id,
          (_id) => DataStatus.WaitingForData
        );
        const actions: Action[] = [];
        if (isNotEmptyDict(setLoadingStatusDict)) {
          actions.push(
            DataConnectorActions.updateDataStatusMany({
              dataConnectorStatusDic: {},
              dataConnectorQueryStatusDic: setLoadingStatusDict
            })
          );
        }
        return resolvingDynamicGenericConnectors.queries$.pipe(
          mergeMap((connectorDict) =>
            this.getActionsOnGenericQueryResponse(
              connectorDict,
              resolvingDynamicGenericConnectors.beingQueried
            )
          ),
          startWith(...actions)
        );
      })
    )
  );

  private getActionsOnGenericQueryResponse(
    connectorDict: Dictionary<DataConnectorDto[]>,
    queriedComponentIds: EntityId[]
  ): Action[] {
    if (isEmptyDict(connectorDict) && !isEmpty(queriedComponentIds)) {
      const setRequestFailedStatusDict = toDictionary(
        queriedComponentIds,
        (id) => id,
        (_id) => DataStatus.RequestFailed
      );
      return [
        DataConnectorActions.updateDataStatusMany({
          dataConnectorStatusDic: {},
          dataConnectorQueryStatusDic: setRequestFailedStatusDict
        })
      ];
    } else {
      const componentIdsWithFailedResponse = _difference(
        queriedComponentIds,
        Object.keys(connectorDict)
      );
      const actions: Action[] = [];
      if (!isEmpty(componentIdsWithFailedResponse)) {
        const setRequestFailedStatusDict = toDictionary(
          componentIdsWithFailedResponse,
          (id) => id,
          (_id) => DataStatus.RequestFailed
        );
        actions.push(
          DataConnectorActions.updateDataStatusMany({
            dataConnectorStatusDic: {},
            dataConnectorQueryStatusDic: setRequestFailedStatusDict
          })
        );
      }

      const connectorReplacementDict =
        this.dataConnectorReplacementService.createDynamicConnectorReplacementInfo(
          connectorDict,
          this.connectorContextService
        );

      if (isNotEmptyDict(connectorReplacementDict)) {
        actions.push(
          DataConnectorActions.replaceGenericConnectors({
            connectorReplacementDict
          })
        );
      }

      return actions;
    }
  }

  $resolveIncrementalGenericQueries = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.resolveIncrementalGenericQueries),
      mergeMap(({ componentsWithQuery, queryFilter }) => {
        const response$ = this.resolveGenericQueries(
          this.filterOutComponentsForIncrementalQuery(componentsWithQuery),
          RequestScope.Incremental
        ).queries$;
        return combineLatest([response$, of(queryFilter.timeRange.from)]);
      }),
      map(([responseConnectorDict, startCutoffDate]) => {
        const connectorsById = flattenConnectorDict(responseConnectorDict);
        return isEmptyDict(responseConnectorDict)
          ? CommonActions.doNothing()
          : DataConnectorActions.updateData({
              connectorDict: connectorsById,
              startCutoffDate
            });
      })
    )
  );

  private filterOutComponentsForIncrementalQuery(
    components: ComponentStateDto[]
  ): ComponentStateDto[] {
    return components.filter(
      (component: ComponentStateDto) => component.componentDataStatus !== DataStatus.RequestFailed
    );
  }

  private resolveGenericQueries(
    componentStates: ComponentStateDto[],
    requestScope: RequestScope
  ): DataConnectorQueryResolutionDetail {
    const filterConfigs = this.filterSelector.getAllAsArray();
    return this.connectorResolver.resolveGenericQueries(
      componentStates,
      filterConfigs,
      requestScope
    );
  }

  //#endregion

  addMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.addMany),
      map(({ connectors }) => CommonActions.getFullRangeSignalData({ connectors: connectors }))
    )
  );

  // #region ROOT PATH CHANGE
  rootPathChange$ = createEffect(() =>
    // todo: why not listen to GeteralSettings.setRootPath action?
    this.runtimeSettingsSelector.selectCurrentRootPath().pipe(
      distinctUntilChanged(),
      switchMap((rootPath) => {
        const componentsWithEquipmentQuery = this.dcqService.getComponentsWithEquipmentQuery();
        const resolvingEquipmentQueryDetail = this.connectorResolver.resolveEquipmentQueries(
          componentsWithEquipmentQuery,
          rootPath
        );

        const equipmentPropertyConnectors =
          this.dataConnectorSelector.getEquipmentPropertyConnectors();
        const resolvingEquipmentConnectors = this.connectorResolver.resolveEquipmentConnectors(
          equipmentPropertyConnectors,
          rootPath
        );

        const setLoadingStatus = DataConnectorActions.updateDataStatusMany({
          dataConnectorStatusDic: toDictionary(
            resolvingEquipmentConnectors.beingQueried,
            (conn) => conn.id,
            (_conn) => DataStatus.WaitingForData
          ),
          dataConnectorQueryStatusDic: toDictionary(
            resolvingEquipmentQueryDetail.beingQueried,
            (compId) => compId,
            (_compId) => DataStatus.WaitingForData
          )
        });
        return zip(
          resolvingEquipmentQueryDetail.queries$,
          resolvingEquipmentConnectors.queries$
        ).pipe(
          filter(([resolvedEquipmentQueries, resolvedEquipmentConnectors]) => {
            const isReportLoading = this.environmentSelector.getReportLoadingState();
            const noConnectorsToUpdate: boolean =
              isEmpty(resolvedEquipmentQueries) && isEmpty(resolvedEquipmentConnectors);
            return !(isReportLoading || noConnectorsToUpdate);
          }),
          map(([resolvedEquipmentQueries, resolvedEquipmentConnectors]) =>
            DataConnectorActions.updateRootPath({
              rootPath,
              resolvedEquipmentQueries,
              resolvedEquipmentConnectors
            })
          ),
          startWith(setLoadingStatus)
        );
      })
    )
  );

  updateRootPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.updateRootPath),
      map(({ resolvedEquipmentQueries, resolvedEquipmentConnectors }) => {
        debugLog("updateRootPath$", { resolvedEquipmentQueries, resolvedEquipmentConnectors });
        const dynamicConnectorsReplaceInfo =
          this.dataConnectorReplacementService.createDynamicConnectorReplacementInfo(
            resolvedEquipmentQueries,
            this.connectorContextService
          );
        const equipmentConnectorUpdates = resolvedEquipmentConnectors.map((connector) =>
          createSignalInfoUpdate(connector)
        );
        return DataConnectorActions.updateAllEquipmentSources({
          connectorReplacementDict: dynamicConnectorsReplaceInfo,
          equipmentConnectorUpdates: equipmentConnectorUpdates
        });
      })
    )
  );

  //#endregion
  updateAllEquipmentSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.updateAllEquipmentSources),
      mergeMap(({ connectorReplacementDict, equipmentConnectorUpdates }) => {
        const newOrModifiedDynamicConnectors =
          this.getValidConnectorsFromReplaceDict(connectorReplacementDict);
        const equipmentConnectors = this.getConnectorsFromUpdates(equipmentConnectorUpdates);
        const connectorsToQuery = newOrModifiedDynamicConnectors.concat(equipmentConnectors);
        const getDataAction = CommonActions.getFullRangeSignalData({
          connectors: connectorsToQuery
        });

        const componentsWithModifiedDcq = Object.keys(connectorReplacementDict);
        // could optimize and remove components involved in getDataAction
        if (componentsWithModifiedDcq.length > 0) {
          const calculateAction = ComponentStateActions.calculateComponentStatusMany({
            modifiedComponents: componentsWithModifiedDcq,
            modifiedDataConnector: []
          });
          return [calculateAction, getDataAction];
        }
        return [getDataAction];
      })
    )
  );

  private getValidConnectorsFromReplaceDict(
    connectorReplacementDict: Dictionary<ConnectorsReplaceInfo>
  ): DataConnectorDto[] {
    return Object.values(connectorReplacementDict).reduce((acc, connectorsArray) => {
      acc = acc.concat(connectorsArray.newConnectors);
      return acc;
    }, [] as DataConnectorDto[]);
  }
  //#endregion

  private getConnectorFromUpdate(connectorUpdate: Update<DataConnectorDto>): DataConnectorDto {
    return this.dataConnectorSelector.getById(connectorUpdate.id);
  }

  private getConnectorsFromUpdates(
    connectorUpdates: Update<DataConnectorDto>[]
  ): DataConnectorDto[] {
    if (connectorUpdates == null) {
      return [];
    }
    const connectorIds = connectorUpdates.map((connectorUpdate) => connectorUpdate.id);
    return Object.values(this.dataConnectorSelector.getManyById(connectorIds));
  }

  updateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.updateData),
      map(({ connectorDict }) => {
        return ComponentStateActions.calculateComponentStatusMany({
          modifiedComponents: [],
          modifiedDataConnector: Object.keys(connectorDict)
        });
      })
    )
  );

  deleteMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.deleteMany),
      map(({ connectorsByComponent }) => {
        const componentIds = Object.keys(connectorsByComponent);
        return ComponentStateActions.calculateComponentStatusMany({
          modifiedComponents: componentIds,
          modifiedDataConnector: []
        });
      })
    )
  );

  deleteOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.deleteOne),
      map(({ componentId }) => {
        return ComponentStateActions.calculateComponentStatusMany({
          modifiedComponents: [componentId],
          modifiedDataConnector: []
        });
      })
    )
  );

  calculateComponentStatusOnAddOrReplace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DataConnectorActions.addOrReplaceData,
        DataConnectorActions.appendAdditionalDataPoints
      ),
      map((action) =>
        ComponentStateActions.calculateComponentStatusMany({
          modifiedComponents: [],
          modifiedDataConnector: []
        })
      )
    )
  );

  calculateComponentStatusOnUpdateDataStatusMany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.updateDataStatusMany),
      map((action) => {
        if (
          isEmptyDict(action.dataConnectorQueryStatusDic) &&
          isEmptyDict(action.dataConnectorStatusDic)
        ) {
          return CommonActions.doNothing();
        }
        return ComponentStateActions.calculateComponentStatusMany({
          modifiedComponents: Object.keys(action.dataConnectorQueryStatusDic),
          modifiedDataConnector: Object.keys(action.dataConnectorStatusDic)
        });
      })
    )
  );

  calculateComponentStatusOnReplaceGenericConnectors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataConnectorActions.replaceGenericConnectors),
      map(({ connectorReplacementDict }) => {
        const componentIds = Object.keys(connectorReplacementDict);
        return ComponentStateActions.calculateComponentStatusMany({
          modifiedComponents: componentIds,
          modifiedDataConnector: []
        });
      })
    )
  );

  aggregateComponentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentStateActions.calculateComponentStatusMany),
      switchMap((action) => {
        const updatedComponentsState = getComponentStatusUpdatesIfNeeded(
          this.componentStateSelector,
          this.dataConnectorSelector
        );
        const actions: Action[] = [];
        if (isNotEmptyDict(updatedComponentsState)) {
          actions.push(
            ComponentStateActions.updateComponentStatusMany({
              componentStates: updatedComponentsState
            })
          );
        }
        return actions;
      })
    )
  );
}

function getUpdateObjects(connectors: DataConnectorDto[]): Update<DataConnectorDto>[] {
  const connectorUpdates = connectors.map((connector: DataConnectorDto) =>
    getUpdateObject(connector)
  );
  return connectorUpdates;
}

function getUpdateObject(connector: DataConnectorDto): Update<DataConnectorDto> {
  return {
    id: connector.id.toString(),
    changes: connector
  };
}

function createSignalInfoUpdate(connector: DataConnectorDto): Update<DataConnectorDto> {
  const newSignalInfo: SignalInfoDto = (connector.dataSource as EquipmentDataSourceDto).signal;
  return {
    id: connector.id.toString(),
    changes: {
      dataSource: {
        ...connector.dataSource,
        signal: newSignalInfo
      } as EquipmentDataSourceDto
      // dataPoints: connector.dataPoints
    }
  } as Update<DataConnectorDto>;
}

function flattenConnectorDict(
  connectorsByComponent: Dictionary<DataConnectorDto[]>
): ConnectorsDictionaryIndexedById {
  const flattenedDict = Object.values(connectorsByComponent).reduce(
    (acc: Dictionary<DataConnectorDto>, connectorArray: DataConnectorDto[]) => {
      const flattenedEntry = connectorArray.reduce(
        (innerAcc: Dictionary<DataConnectorDto>, connector: DataConnectorDto) => {
          innerAcc[connector.id] = connector;
          return innerAcc;
        },
        {}
      );
      acc = { ...acc, ...flattenedEntry };
      return acc;
    },
    {}
  );
  return flattenedDict;
}

function findConnectorToReplace(
  ownerComponent: ComponentStateDto,
  dataConnectorSelector: DataConnectorSelector
): Maybe<DataConnectorDto> {
  const componentConnectors: DataConnectorDto[] = dataConnectorSelector.getForComponent(
    ownerComponent.id
  );
  return isSingleValue(ownerComponent.type)
    ? componentConnectors.find((conn) => conn.role === Roles.Value.name)
    : componentConnectors[0];
}

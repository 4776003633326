import { EntityId } from "../../meta/models/entity";
import { isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { DomMapper } from "../services/dom-mapper.service";

export function isComponentVisibleInViewport(componentId: EntityId): boolean {
  const element: Maybe<HTMLElement> = getDomElementByComponentId(componentId);
  return isDomElementVisibleInViewport(element);
}

export function getDomElementByComponentId(componentId: EntityId): Maybe<HTMLElement> {
  const id: string = DomMapper.getHostId(componentId);
  return document.getElementById(id);
}

export function isDomElementVisibleInViewport(element: Maybe<HTMLElement>): boolean {
  if (isNotDefined(element)) {
    return false;
  }
  const rect: DOMRect = element.getBoundingClientRect();
  const { top, left } = rect;
  return top >= 0 && top < window.innerHeight && left >= 0 && left < window.innerWidth;
}

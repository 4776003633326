import { AlignValue, Options, YAxisPlotLinesOptions } from "highcharts";
import { ValueFormatterService } from "../../../core/services/value-formatter.service";
import { ColorListService } from "../../../environment/services/color-list.service";
import { Maybe, first } from "../../../ts-utils";
import { parseAndRoundToNumber } from "../../../ts-utils/helpers/number.helper";
import { isDefined } from "../../../ts-utils/helpers/predicates.helper";
import { getTextColorForNoDataStatus } from "../../helpers/color.helper";
import { getSingleValueFromConnector } from "../../helpers/component-data-accessor.helper";
import { getLimitsDisplayOptions, isPrimaryLimitsMode } from "../../helpers/limit-modes.helper";
import { DEFAULT_CHART_TEXT_COLOR_HEX } from "../../models/colors.constants";
import { DataStatus } from "../../models/data-status";
import { IGaugeDisplayConfig } from "../../models/i-view-config/i-gauge-display-config";
import { DataConnectorDescriptor } from "../../models/store/data-connector-descriptor";
import { LimitMarkerHelper } from "../limit.helper";
import { PRIMARY_X_AXIS_ID, PRIMARY_Y_AXIS_ID } from "./base-highcharts-options.helper";
import { GaugeDisplayService } from "./gauge-display.service";
import { getDataLabelColor } from "./single-value-display.helper";

export abstract class LinearGaugeDisplayService extends GaugeDisplayService {
  protected colorListService: ColorListService;
  constructor(protected valueFormatter: ValueFormatterService, colorListService: ColorListService) {
    super(valueFormatter);
    this.isTargetAsAxis = false;
    this.colorListService = colorListService;
  }

  protected getSpecificGaugeOptions(
    viewConfig: IGaugeDisplayConfig,
    dataConnectorDescriptors: Maybe<DataConnectorDescriptor>[],
    dataStatus: DataStatus
  ): Options {
    const value = getSingleValueFromConnector(first(dataConnectorDescriptors)?.connector);
    const min = parseAndRoundToNumber(viewConfig.min);
    const max = parseAndRoundToNumber(viewConfig.max);
    const { showLimitsAsBars } = getLimitsDisplayOptions(viewConfig.limitsMode);

    const linearOptions: Options = {
      title: {
        text: getDisplayHTML(value, viewConfig, this.valueFormatter, this.colorListService),
        useHTML: true,
        verticalAlign: "bottom",
        style: {
          textTransform: "none"
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: "normal",
          pointWidth: getPointWidth(showLimitsAsBars, viewConfig.runtimeView.runtimeSize.widthInPx)
        } as any
      },
      xAxis: [
        {
          id: PRIMARY_X_AXIS_ID,
          visible: false,
          title: {
            text: null
          },
          labels: {
            enabled: false
          },
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          ...getXAxisMinAndMax(showLimitsAsBars)
        }
      ],
      yAxis: [
        {
          id: PRIMARY_Y_AXIS_ID,
          title: {
            text: ""
          },
          labels: { enabled: false },
          tickPositions: [min, max],
          minorTickInterval: null,
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          minorTickLength: 0,
          tickLength: 0,
          opposite: this.areYAxisLabelsRightAligned(viewConfig),
          plotLines: this.getPlotLines(viewConfig),
          plotBands: [
            {
              id: PRIMARY_Y_AXIS_ID,
              from: min,
              to: max,
              color: "transparent",
              label: {
                useHTML: true,
                verticalAlign: "middle",
                textAlign: this.getTextAlignStyle(),
                align: "center",
                text: dataStatus !== DataStatus.DataReceived ? dataStatus : "",
                y: this.getYOffset(),
                style: {
                  color: getTextColorForNoDataStatus(dataStatus, DEFAULT_CHART_TEXT_COLOR_HEX),
                  fontSize: "12px",
                  fontWeight: "600",
                  fill: "#666666",
                  fontFamily: "ABB Font"
                }
              }
            },
            ...(showLimitsAsBars ? this.getYAxisPlotBands(viewConfig) : [])
          ]
        }
      ],
      tooltip: {
        enabled: false
      },
      series: []
    };

    return linearOptions;
  }

  protected areYAxisLabelsRightAligned(_viewConfig: IGaugeDisplayConfig): boolean {
    return true;
  }

  private getPlotLines(viewConfig: IGaugeDisplayConfig): YAxisPlotLinesOptions[] {
    const yAxisPlotLines = this.getYAxisPlotLines(viewConfig);
    const limitPlotLines = this.getLimitPlotLines(viewConfig);
    return yAxisPlotLines.concat(limitPlotLines);
  }

  private getYAxisPlotLines(viewConfig: IGaugeDisplayConfig): YAxisPlotLinesOptions[] {
    const { showYAxisLabels, min, max } = viewConfig;
    const extremeValues = [parseAndRoundToNumber(min), parseAndRoundToNumber(max)];
    const definedExtremeValues: number[] = extremeValues.filter((value) => isDefined(value));
    return showYAxisLabels
      ? definedExtremeValues.map((value) => ({
          value,
          width: 0,
          label: this.getYAxisPlotLineLabel(viewConfig, value)
        }))
      : [];
  }

  private getLimitPlotLines(viewConfig: IGaugeDisplayConfig): YAxisPlotLinesOptions[] {
    const { limitsMode, limits } = viewConfig;
    const { showLimitValues } = getLimitsDisplayOptions(limitsMode);
    if (isPrimaryLimitsMode(limitsMode) || showLimitValues) {
      const markerHelper = new LimitMarkerHelper(limits);
      const markers = markerHelper.getMarkers(0, 10);
      return markers.map(
        (marker) =>
          ({
            value: marker.value,
            color: marker.color,
            zIndex: marker.zIndex,
            label: this.getLimitPlotLineLabel(viewConfig, marker.value)
          } as YAxisPlotLinesOptions)
      );
    }
    return [];
  }

  protected abstract getYOffset(): number;
  protected abstract getTextAlignStyle(): AlignValue;
  protected abstract getLimitPlotLineLabel(viewConfig: IGaugeDisplayConfig, value: number);
  protected abstract getYAxisPlotLineLabel(viewConfig: IGaugeDisplayConfig, value: number);
}

function getDisplayHTML(
  value: number,
  viewConfig: IGaugeDisplayConfig,
  valueFormatter: ValueFormatterService,
  colorListService: ColorListService
): string {
  const titleHTML = viewConfig.showTitle ? getStyledTitleHTML(viewConfig) : "";
  const valueHTML = viewConfig.shouldShowDataLabel
    ? getStyledValueHTML(viewConfig, value, valueFormatter, colorListService)
    : "";
  return `
    <div>
      ${titleHTML}
      ${valueHTML}
    <div>
  `.trim();
}

function getStyledTitleHTML(viewConfig: IGaugeDisplayConfig): string {
  return `<div>${viewConfig.title ?? ""}</div>`;
}

function getStyledValueHTML(
  viewConfig: IGaugeDisplayConfig,
  value: number,
  valueFormatter: ValueFormatterService,
  colorListService: ColorListService
): string {
  const fontSize: string = isDefined(viewConfig.fontSize) ? `${viewConfig.fontSize}px` : "inherit";
  const textColor: string = getDataLabelColor(value, viewConfig, colorListService);
  return `
    <div style="
      color: ${textColor};
      font-size: ${fontSize};
      ">
      ${isDefined(value) ? valueFormatter.formatValue(value, viewConfig.displayFormat) : ""} ${
    viewConfig.unit || ""
  }
    </div>
  `.trim();
}

function getPointWidth(showLimitsAsBars: Maybe<boolean>, width: number): Maybe<number> {
  return showLimitsAsBars ? width * 2 : undefined;
}

function getXAxisMinAndMax(showLimitsAsBars: Maybe<boolean>) {
  const xAxisLeftOffset: number = -0.2;
  const xAxisRightOffset: number = 0.1;
  return showLimitsAsBars
    ? {
        min: xAxisLeftOffset,
        max: xAxisRightOffset
      }
    : { min: undefined, max: undefined };
}

import { Component } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { TooltipFormatterContextObject } from "highcharts";
import { RDS_LOCALIZATION_DICTIONARY } from "projects/rds/src/assets/i18n/models/rds-localization-dictionary";
import {
  BaseComponent,
  ChartComponent,
  ConnectorRoles,
  EditableType,
  formatNumberByStringFormat,
  HorizontalAlignment,
  LayoutBuilder,
  SignalDataSourceDto,
  View
} from "ui-core";
import { Extremes } from "../../../types";
import { RdsComponentCategory } from "../../rds-component-category";
import { Roles } from "./roles";
import { PoleAlignmentChartViewConfig } from "./view-config";

const POLE_SCALING_CONSTANT = 30;
const basicPoleColor = "rgb(63,182,204)";
const peakPoleColor = "red";
const minimumPoleColor = "rgb(77, 184, 44)";

@Component({
  selector: "rds-pole-alignment-chart",
  templateUrl: "./pole-alignment-chart.component.html",
  styleUrls: ["./pole-alignment-chart.component.scss"],
  providers: [{ provide: BaseComponent, useExisting: PoleAlignmentChartComponent }]
})
@LayoutBuilder(
  RdsComponentCategory.RDS,
  "PoleAlignmentChartComponent",
  "Plugin",
  "abb-icon",
  undefined,
  RDS_LOCALIZATION_DICTIONARY.layoutEditor.PoleAlignmentChart
)
@ConnectorRoles(Roles)
@EditableType({ fullName: "PoleAlignmentChartComponent", title: "pole-alignment-chart" })
export class PoleAlignmentChartComponent extends ChartComponent {
  HorizontalAlignment = HorizontalAlignment;
  chartObject: any;
  private renderedShapes: any[] = [];

  smallestAirgap: number;
  averageAirgap: number;
  largestAirgap: number;
  enablePoolNumbers: boolean = true;

  currentPoleValues: number[] = [];
  smallestAirgapValues: number[] = [];
  averageAirgapValues: number[] = [];
  largestAirgapValues: number[] = [];

  @View(PoleAlignmentChartViewConfig)
  public get view(): PoleAlignmentChartViewConfig {
    return this.currentState.view as PoleAlignmentChartViewConfig;
  }

  protected updateDisplay(): void {
    super.updateDisplay();
    if (this.chartObject != null) {
      this.clear();
      this.render();
    }
  }

  private clear(): void {
    this.renderedShapes.forEach((shape) => {
      if (!!shape.element) {
        shape.destroy();
      }
    });
  }

  private render(): void {
    if (this.currentPoleValues.length === 0) {
      return;
    }

    const center = {
      x: this.chartObject.plotBox.width / 2,
      y: this.chartObject.plotBox.height / 2
    };
    const radius = Math.abs(-this.smallestAirgap - POLE_SCALING_CONSTANT);

    if (isNaN(radius)) {
      console.warn("No values provided for svg renderer!");
      return;
    }

    const millShell = this.chartObject.renderer
      .circle(center.x + this.chartObject.plotBox.x, center.y + this.chartObject.plotBox.y, radius)
      .attr({ fill: "#fff", stroke: "black", "stroke-width": 1, zIndex: 3000 })
      .add();

    this.renderedShapes.push(millShell);
  }

  protected updateChartData(): void {
    const poleValues: number[] = this.dataAccessor.getValueForRole(Roles.PoleValues.name);

    const keyphasorConnector = this.dataAccessor.getAllConnectors().find((conn) => {
      const signalId = (conn.dataSource as SignalDataSourceDto).signal.id!.toString();
      return signalId.split(".").slice(-1)[0] === "Keyphasor";
    });

    if (keyphasorConnector) {
      const value = keyphasorConnector.properties.value.toString();
      this.enablePoolNumbers = ["true", "1", "yes"].includes(value.toLowerCase());
    }

    if (!poleValues) {
      this.currentPoleValues = [];
      this.smallestAirgapValues = [];
      this.averageAirgapValues = [];
      this.largestAirgapValues = [];
      return;
    }

    this.currentPoleValues = poleValues.map((pole: number) => {
      return POLE_SCALING_CONSTANT - pole;
    });

    this.smallestAirgap = Math.min(...this.currentPoleValues);
    this.largestAirgap = Math.max(...this.currentPoleValues);
    const numberOfPoles: number = this.currentPoleValues.length;
    const sumOfPoleValues: number = this.currentPoleValues.reduce((a, b) => a + b, 0);
    this.averageAirgap = sumOfPoleValues / numberOfPoles;
    this.smallestAirgapValues = Array(numberOfPoles).fill(this.smallestAirgap);
    this.averageAirgapValues = Array(numberOfPoles).fill(this.averageAirgap);
    this.largestAirgapValues = Array(numberOfPoles).fill(this.largestAirgap);
  }

  /**
   * It gets the string to render as a tooltip for the polar graph
   * @param context
   * @returns
   */
  getTooltip(
    context: TooltipFormatterContextObject,
    component: PoleAlignmentChartComponent
  ): SafeHtml {
    const getIdxFn = (pos: number, fwd: boolean): number => {
      if (pos === 71 && fwd) {
        return 1;
      } else if (pos === 0 && !fwd) {
        return 72;
      } else if (fwd) {
        return pos + 2;
      } else {
        return pos;
      }
    };

    let html = "";

    const prev = getIdxFn(Number(context.x), false);
    const yPrev = (context.series as any).processedYData[prev - 1];
    const next = getIdxFn(Number(context.x), true);
    const yNext = (context.series as any).processedYData[next - 1];

    if (context.series.data.length > 0) {
      html =
        "Pole: <b>" +
        (Number(context.x) + 1) +
        "</b>" +
        " Value: <b>" +
        formatNumberByStringFormat(
          POLE_SCALING_CONSTANT - context.y,
          component.view.displayFormat.numberFormat
        ) +
        "</b>" +
        " Δ " +
        prev +
        "-" +
        (Number(context.x) + 1) +
        ": <b>" +
        formatNumberByStringFormat(yPrev - context.y, component.view.displayFormat.numberFormat) +
        "</b>" +
        " Δ " +
        (Number(context.x) + 1) +
        "-" +
        next +
        ": <b>" +
        formatNumberByStringFormat(context.y - yNext, component.view.displayFormat.numberFormat) +
        "</b>.";
    }

    return html;
  }

  protected setChartOptions(): void {
    const component = this;
    const chartData: any[] = [...this.currentPoleValues];

    if (this.currentPoleValues.length !== 0 && this.largestAirgap) {
      const extremes: Extremes = this.findExtremes(this.currentPoleValues);

      this.currentPoleValues.forEach((_, index) => {
        chartData[index] = this.colorPole(index, chartData, extremes);
      });
    }

    this.chartOptions = {
      lang: {
        noData: "No Data Available"
      },
      chart: {
        polar: true,
        events: {
          load: function () {
            component.chartObject = this;
            component.render();
          }
        }
      },
      title: { text: null },
      credits: { enabled: false },
      legend: { enabled: false },
      tooltip: {
        formatter: function () {
          return component.getTooltip(this as any, component);
        }
      },
      plotOptions: {
        area: {},
        series: {
          marker: {
            enabled: false,
            symbol: "circle",
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: [
        {
          type: "column",
          name: "Poles",
          data: chartData,
          color: this.view.polesColor
        },
        {
          type: "line",
          name: "Average Airgap",
          data: this.averageAirgapValues,
          dashStyle: "longdash",
          lineWidth: 1,
          enableMouseTracking: false,
          color: this.view.averageAirgapColor
        },
        {
          type: "line",
          name: "Smallest Airgap",
          data: this.smallestAirgapValues,
          dashStyle: "longdash",
          lineWidth: 1,
          enableMouseTracking: false,
          color: this.view.smallestAirgapColor
        },
        {
          type: "line",
          name: "Largest Airgap",
          data: this.largestAirgapValues,
          dashStyle: "longdash",
          lineWidth: 1,
          enableMouseTracking: false,
          color: this.view.largestAirgapColor
        }
      ],
      xAxis: {
        id: "xAxis",
        tickInterval: 1,
        max: this.currentPoleValues.length,
        labels: {
          enabled: this.enablePoolNumbers,
          formatter: function () {
            return this.value + 1;
          },
          style: {
            fontSize: "14px"
          },
          distance: -5
        },
        minorGridLineWidth: 0,
        gridLineColor: "transparent",
        lineWidth: 0
      },
      yAxis: {
        id: "yAxis",
        plotlines: [],
        ceiling: Math.max(...this.currentPoleValues),
        max: Math.max(...this.currentPoleValues),
        maxPadding: 0,
        gridLineColor: "transparent",
        // FIXME 908 find correct dependency instead of constant
        min: Math.min(...this.currentPoleValues) - 4,
        labels: {
          formatter: function () {
            return "";
          }
        }
      }
    };
  }

  private findExtremes(poles: number[]): Extremes {
    const extremes: Extremes = {
      min: 0,
      max: 0
    };

    poles.forEach((_, index) => {
      if (this.largestAirgap !== undefined && this.smallestAirgap !== undefined) {
        if (this.largestAirgap !== this.smallestAirgap) {
          if (this.currentPoleValues[index] === this.largestAirgap) {
            extremes.max = index;
          } else if (this.currentPoleValues[index] === this.smallestAirgap) {
            extremes.min = index;
          }
        }
      }
    });

    return extremes;
  }

  private colorPole(poleIndex: number, chartData: any, extremes: Extremes): any {
    let result = {};

    if (poleIndex === extremes.max) {
      result = { y: chartData[poleIndex], color: peakPoleColor };
    } else if (poleIndex === extremes.min) {
      result = { y: chartData[poleIndex], color: minimumPoleColor };
    } else {
      result = { y: chartData[poleIndex], color: basicPoleColor };
    }

    return result;
  }
}

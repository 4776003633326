import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren
} from "@angular/core";
import { cloneDeep as _cloneDeep } from "lodash";
import { DraggedItem } from "../../../core/models/drag/dragged-item";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { OfType } from "../../../meta/decorators/of-type.decorator";
import { EditorType } from "../../../meta/models/editor-type";
import { PropertyDescriptor } from "../../../meta/models/property-descriptor";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { resolveItemDisplayName } from "../../helpers/array-items.helper";
import { BaseEditorComponentParams } from "../../models/base-editor-component-params";
import { swapArrayItems } from "../array-editor/array-editor.component";
import { ItemType } from "../array-editor/models";
import { BaseEditorComponent } from "../base-editor.component";

const CSS_CUSTOM_ITEM = "extendable-checkbox-list__custom-item-wrapper";

@Component({
  selector: "extendable-checkbox-list",
  templateUrl: "./extendable-checkbox-list.component.html",
  styleUrls: ["./extendable-checkbox-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@OfType(EditorType.ExtendableCheckboxList)
export class ExtendableCheckboxListComponent
  extends BaseEditorComponent
  implements OnInit, AfterViewInit
{
  items: ItemType[] = [];
  itemTitleProperty: Maybe<PropertyDescriptor> = null;
  itemKeyProperty: Maybe<PropertyDescriptor> = null;
  draggedItemIndex!: number;
  draggedOverTargets: boolean[] = [];
  focusedItemIndex: number = -1;

  @ViewChildren("item") itemElements?: QueryList<ElementRef>;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected params: BaseEditorComponentParams,
    protected hostElemet: ElementRef
  ) {
    super(hostElemet, params, cdr);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.determineItemTitleProperty();
    this.determineItemKeyProperty();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.items = _cloneDeep(this.value);
      this.cdr.markForCheck();
    });
  }

  private determineItemTitleProperty(): void {
    if (!this.typeDescriptor.isPrimitive) {
      this.itemTitleProperty = this.typeDescriptor.properties.find((prop) => prop.isTitle);
    }
  }

  private determineItemKeyProperty(): void {
    if (!this.typeDescriptor.isPrimitive) {
      this.itemKeyProperty = this.typeDescriptor.properties.find((prop) => prop.isKey);
    }
  }

  refreshValue(value: any): void {
    this.focusedItemIndex = value.findIndex(
      (item, i) => JSON.stringify(item) !== JSON.stringify(this.value[i])
    );
    super.refreshValue(value);
    this.items = _cloneDeep(value);
    this.cdr.detectChanges();
  }

  focus(): void {
    if (this.focusedItemIndex > -1 && isDefined(this.itemElements)) {
      const element: Maybe<ElementRef> = this.itemElements.get(this.focusedItemIndex);
      if (isDefined(element)) {
        const elementSibling = element.nativeElement.nextElementSibling;
        const isCustomItem = elementSibling.classList.contains(CSS_CUSTOM_ITEM);
        if (isCustomItem) {
          elementSibling.scrollIntoView({ block: "nearest", behavior: "smooth" });
        } else {
          const input = elementSibling.querySelector("input");
          input.focus();
        }
      }
    }
  }

  trackItemsByIndex(index: number): number {
    return index;
  }

  hasDefaultValueChanged(index: number): boolean {
    const defaultItem: Maybe<ItemType> = this.propertyInfo.descriptor.defaultValue.find(
      (defaultItem: ItemType) =>
        this.getItemKeyValue(defaultItem) === this.getItemKeyValue(this.items[index])
    );
    if (isNotDefined(defaultItem)) {
      return this.items[index].isSelected;
    }
    return defaultItem.isSelected !== this.items[index].isSelected;
  }

  dragStart(event: Event, item: ItemType, index: number): void {
    event.stopPropagation();
    this.draggedItemIndex = index;
    const dragTarget: DraggedItem = this.getDragTarget(item);
    this.dragDropService.enableDrag = true;
    this.dragDropService.setDragTarget(dragTarget, "");
  }

  getDragTarget(item: ItemType): DraggedItem {
    return {
      type: DraggedItemType.ArrayEditorItemType,
      item
    };
  }

  onDragOver(event: DragEvent, targetIndex: number): void {
    if (this.canAcceptDrop()) {
      event.preventDefault();
    }
    event.stopPropagation();
    this.setDraggedOverTarget(targetIndex);
  }

  private canAcceptDrop(): boolean {
    if (isNotDefined(this.dragDropService.target)) {
      return false;
    }
    return this.dragDropService.target.type === DraggedItemType.ArrayEditorItemType;
  }

  setDraggedOverTarget(index: number): void {
    if (this.draggedItemIndex !== index) {
      this.draggedOverTargets[index] = true;
    }
  }

  onDragEnd(): void {
    this.resetDraggedOverTarget();
    this.dragDropService.dragEnd();
  }

  resetDraggedOverTarget(): void {
    const index = this.draggedOverTargets.findIndex(Boolean);
    if (isDefined(index)) {
      this.draggedOverTargets[index] = false;
      this.cdr.detectChanges();
    }
  }

  onDragLeave(event: DragEvent, targetIndex: number): void {
    this.draggedOverTargets[targetIndex] = false;
    event.stopPropagation();
  }

  drop(_event: DragEvent, index: number): void {
    if (this.draggedItemIndex === index) {
      return;
    }
    const items = _cloneDeep(this.items);
    swapArrayItems(items, this.draggedItemIndex, index);
    this.onValueChanged(items);
    this.items = [...items];
  }

  onSelectionChange(index: number): void {
    const items = _cloneDeep(this.items);
    items[index].isSelected = !items[index].isSelected;
    this.onValueChanged(items);
    this.items = [...items];
  }

  getSelectableItemLabelText(item: ItemType): string {
    return resolveItemDisplayName(item, this.itemTitleProperty, this.typeProvider);
  }

  determineCorrespondingItemIndex(sourceItems: ItemType[], targetItem: ItemType): number {
    return sourceItems.findIndex(
      (item: ItemType) => this.getItemKeyValue(item) === this.getItemKeyValue(targetItem)
    );
  }

  getItemKeyValue(item: Maybe<ItemType>): string {
    if (isDefined(this.itemKeyProperty) && isDefined(item)) {
      return item[this.itemKeyProperty.name];
    }
    return "";
  }

  addCustomItem(): void {
    const newItem = new this.typeDescriptor.constructorFunction();
    newItem.isSelected = true;
    this.items = [...this.items, newItem];
    this.onValueChanged(this.items);
  }

  editCustomItem(updatedItem: ItemType, updatedItemIndex: number): void {
    this.items[updatedItemIndex] = updatedItem;
    this.onValueChanged(this.items);
  }

  removeItem(index: number): void {
    const items = _cloneDeep(this.items);
    items.splice(index, 1);
    this.items = [...items];
    this.onValueChanged(items);
  }
}

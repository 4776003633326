import {
  BaseViewConfigDto,
  ComponentCssSize,
  construct,
  DeepPartial,
  EditableType
} from "ui-core";

const TYPE_NAME = "CustomerBreadcrumbsViewConfig";

@EditableType({ fullName: TYPE_NAME })
export class CustomerBreadcrumbsViewConfig extends BaseViewConfigDto {
  typeName = TYPE_NAME;

  constructor(viewConfigDto: DeepPartial<CustomerBreadcrumbsViewConfig> = {}) {
    super();
    construct(this, viewConfigDto, TYPE_NAME, { size: getDefaultSize() });
  }
}

function getDefaultSize(): ComponentCssSize {
  return new ComponentCssSize("600", "600");
}

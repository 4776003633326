import { EntityId } from "../../meta/models/entity";
import { Maybe, first } from "../../ts-utils";
import { Dictionary } from "../../ts-utils/models/dictionary.type";
import { Direction } from "../models/direction";
import { DraggabillyEventContext } from "../models/draggabilly-event-context";
import { DRAGGED_ELEMENT_CSS_CLASS } from "./widget-dragging.helper";

export const ROOT_PADDING_LEFT_TOP = 30;
export const ROOT_PADDING_LEFT_TOP_MINIMAL = 2;
export const ROOT_PADDING_BOTTOM = 15;

export function droppedOnRightHalf(dropPoint: MouseEvent | Touch, targetRect: ClientRect): boolean {
  return (dropPoint.clientX - targetRect.left) / (targetRect.right - targetRect.left) > 0.5;
}

export function getTargetComponentId(
  childRects: Dictionary<ClientRect>,
  dropPoint: MouseEvent | Touch
): Maybe<EntityId> {
  const dropX = dropPoint.clientX;
  const dropY = dropPoint.clientY;
  return first(
    Object.keys(childRects).filter((childId) => {
      const targetRect: ClientRect = childRects[childId];
      return (
        dropY > targetRect.top &&
        dropY < targetRect.bottom &&
        dropX < targetRect.right &&
        dropX > targetRect.left
      );
    })
  );
}

export function droppedOnUpperHalf(dropPoint: MouseEvent | Touch, targetRect: ClientRect): boolean {
  return dropPoint.clientY <= targetRect.top + targetRect.height / 2;
}

export function adjustRowDropOffset(
  orderedChildIds: EntityId[],
  childRects: Dictionary<ClientRect>,
  targetChildId: EntityId,
  offsetDirection: Direction
): number {
  const targetComponentIndex: number = orderedChildIds.indexOf(targetChildId);
  const targetRect: ClientRect = childRects[targetChildId];
  const step: number = offsetDirection === Direction.LEFT ? -1 : 1;

  let dropOffset: number = 0;
  let siblingIndex = targetComponentIndex;
  let siblingRect;

  siblingIndex += step;
  siblingRect = childRects[orderedChildIds[siblingIndex]];
  while (
    indexIsWithinBounds(siblingIndex, Object.keys(childRects).length) &&
    rectsOverlapVertically(siblingRect, targetRect)
  ) {
    dropOffset += step;
    siblingIndex += step;
    siblingRect = childRects[orderedChildIds[siblingIndex]];
  }

  return dropOffset;
}

export function indexIsWithinBounds(index: number, numberOfRects: number): boolean {
  return index >= 0 && index < numberOfRects;
}

export function rectsOverlapVertically(rectA: ClientRect, rectB: ClientRect): boolean {
  if (rectA == null) {
    return false;
  }

  if (rectA.height < rectB.height) {
    return rectOverlaps(rectA, rectB);
  } else {
    return rectOverlaps(rectB, rectA);
  }
}

export function rectOverlaps(shorterRect: ClientRect, tallerRect: ClientRect): boolean {
  const upperOverlap: boolean = upperBorderOverlaps(shorterRect, tallerRect);
  const lowerOverlap: boolean = lowerBorderOverlaps(shorterRect, tallerRect);
  return upperOverlap || lowerOverlap;
}

export function upperBorderOverlaps(shorterRect: ClientRect, tallerRect: ClientRect): boolean {
  return shorterRect.top >= tallerRect.top && shorterRect.top < tallerRect.top + tallerRect.height;
}

export function lowerBorderOverlaps(shorterRect: ClientRect, tallerRect: ClientRect): boolean {
  return (
    shorterRect.top + shorterRect.height > tallerRect.top &&
    shorterRect.top + shorterRect.height <= tallerRect.top + tallerRect.height
  );
}

export function resetDraggedCardPositioningCss(draggabillyContext: DraggabillyEventContext): void {
  const draggedHtmlElement = draggabillyContext.draggable.element as HTMLElement;
  draggedHtmlElement.style.top = draggabillyContext.initialTopOffset;
  draggedHtmlElement.style.left = draggabillyContext.initialLeftOffset;
  draggedHtmlElement.classList.remove(DRAGGED_ELEMENT_CSS_CLASS);
}

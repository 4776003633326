import { Injectable } from "@angular/core";
import { ComponentStateDto } from "../../elements/models/component-state";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { DraggedItem } from "../models/drag/dragged-item";

Injectable();
export class IDropHelper {
  dropEquipmentOnPropertySheet(
    target: Maybe<DraggedItem>,
    componentState: ComponentStateDto
  ): void {
    throw new Error("Not implemented");
  }

  dropConnectorOnComponent(
    target: Maybe<DraggedItem>,
    componentState: ComponentStateDto,
    groupId?: string,
    index?: number
  ): boolean {
    throw new Error("Not implemented");
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import { CustomOption, EditorChangeContent, EditorChangeSelection, QuillModules } from "ngx-quill";
import Quill, { Range } from "quill";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Dispatcher } from "../../../dispatcher";
import { LabelViewConfig } from "../../../elements/components/label/view-config";
import { fromPx } from "../../../elements/services/drop.helper";
import { ComponentStateActions } from "../../../elements/store/component-state/component-state.actions";
import { createUpdatedComponentsInfo } from "../../../meta/helpers/updated-entities-info.helper";
import { EntityId } from "../../../meta/models/entity";
import {
  CSS_TOOLBAR_CONTAINER,
  CUSTOM_EDITOR_CONFIG,
  FONT_SIZE_OPTIONS,
  QUILL_TOOLTIPS
} from "../../../property-sheet/models/text-editor-config";
import { isEmptyOrNotDefined } from "../../../ts-utils/helpers/is-empty.helper";
import { isDefined, isNotDefined } from "../../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../../ts-utils/models/maybe.type";
import { EDITOR_FOCUSED } from "../../directives/focus-visualization.directive";
import { UndoRedoService } from "../../services/undo-redo.service";

@Component({
  selector: "ngx-quill-editor",
  templateUrl: "ngx-quill-editor-wrapper.component.html",
  styleUrls: ["ngx-quill-editor-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgxQuillEditorWrapperComponent implements OnInit, OnDestroy {
  @ViewChild("editorWrapper") editorWrapper!: ElementRef;
  @Input() value: string = "";
  @Input() componentId: Maybe<EntityId> = null;
  @Input() topOffset: Maybe<string> = null;
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();
  inputChanged$: Subject<string> = new Subject<string>();
  unsubscribeSubject$: Subject<void> = new Subject<void>();
  quillEditor!: Quill;
  editorConfig: QuillModules = CUSTOM_EDITOR_CONFIG;
  customFontSizeOptions: CustomOption[] = FONT_SIZE_OPTIONS;
  shouldPreserveCursorPosition: boolean = false;

  constructor(
    private renderer: Renderer2,
    private dispatcher: Dispatcher,
    private undoRedoService: UndoRedoService
  ) {}

  ngOnInit(): void {
    this.inputChanged$
      .pipe(debounceTime(500), takeUntil(this.unsubscribeSubject$))
      .subscribe((newValue) => {
        this.onValueChange.emit(newValue);
        if (isDefined(this.componentId)) {
          this.value = newValue;
          this.updateText(newValue);
        }
      });
  }

  updateText(newValue: string): void {
    this.undoRedoService.createSnapshot({
      updatedEntitiesInfo: createUpdatedComponentsInfo([this.componentId])
    });
    this.dispatcher.dispatch(
      ComponentStateActions.updateOne({
        componentUpdate: {
          id: this.componentId?.toString(),
          changes: {
            view: { htmlText: newValue } as LabelViewConfig
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.inputChanged$.complete();
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  initializeEditor(quillEditor: Quill): void {
    this.quillEditor = quillEditor;
    this.quillEditor.root.innerHTML = this.value;
    this.addEditorTooltips();
    const toolbarElement = this.editorWrapper.nativeElement.querySelector(
      "." + CSS_TOOLBAR_CONTAINER
    );

    if (isDefined(toolbarElement)) {
      toolbarElement.addEventListener("mousedown", (event) => {
        event.preventDefault();
      });
      if (isDefined(this.componentId)) {
        this.resolveToolbarPosition(toolbarElement);
      }
    }
  }

  private addEditorTooltips(): void {
    const toolbar: any = this.quillEditor.getModule("toolbar");
    if (isDefined(toolbar)) {
      toolbar.container.querySelectorAll("button, .ql-picker").forEach((element) => {
        const key = isEmptyOrNotDefined(element.value)
          ? element.classList[0]?.split("-")[1]
          : element.value;
        if (isDefined(QUILL_TOOLTIPS[key])) {
          element.setAttribute("title", QUILL_TOOLTIPS[key]);
        }
      });
    }
  }

  private resolveToolbarPosition(toolbarElement: HTMLElement): void {
    const toolbarHeight = toolbarElement.clientHeight;

    if (isDefined(this.topOffset)) {
      this.renderer.setStyle(toolbarElement, "right", "0");
      this.renderer.setStyle(toolbarElement, "width", "100%");

      if (fromPx(this.topOffset) > toolbarHeight) {
        this.renderer.setStyle(toolbarElement, "bottom", "100%");
        this.renderer.setStyle(toolbarElement, "margin-bottom", "5px");
      } else {
        this.renderer.setStyle(toolbarElement, "top", "100%");
        this.renderer.setStyle(toolbarElement, "margin-top", "5px");
      }
    }
  }

  processEditorChange(change: EditorChangeContent | EditorChangeSelection): void {
    if (change.event === "selection-change" && change.source === "user") {
      this.shouldPreserveCursorPosition =
        change.range?.index !== this.quillEditor.getText().length - 1;
    }
    if (change.event === "text-change" && change.source === "user") {
      if (change.html !== this.value) {
        this.inputChanged$.next(change.html ?? "");
      }
    }
  }

  setFocus(): void {
    if (
      !this.quillEditor?.root.classList.contains(EDITOR_FOCUSED) &&
      isNotDefined(this.componentId)
    ) {
      this.renderer.addClass(this.quillEditor.root, EDITOR_FOCUSED);
    }
  }

  unsetFocus(): void {
    if (isDefined(this.quillEditor) && isNotDefined(this.componentId)) {
      this.renderer.removeClass(this.quillEditor.root, EDITOR_FOCUSED);
    }
  }

  preserveCursorPosition(): void {
    const selection: Maybe<Range> = this.quillEditor.getSelection(true);
    if (isNotDefined(selection) || this.shouldPreserveCursorPosition) {
      return;
    }

    let cursorPosition = selection.index ?? 0;
    if (cursorPosition + 1 < this.quillEditor.root.innerText.length) {
      cursorPosition = this.quillEditor.root.innerText.length;
    }
    this.quillEditor.setSelection(cursorPosition, selection.length);
  }
}

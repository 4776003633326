import { Directive } from "@angular/core";
import { IDragDropService } from "../../core/services/i-drag-drop.service";
import { IDropHelper } from "../../core/services/i-drop-helper";
import { ComponentStateDto } from "../../elements/models/component-state";

@Directive({
  selector: "[baseTemplate]"
})
export class BaseTemplateDirective {
  dragDropService?: IDragDropService;
  dropHelper?: IDropHelper;
  componentState?: ComponentStateDto;
  public init(componentState: ComponentStateDto, params: any): void {}
}

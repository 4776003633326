import { SimpleChanges } from "@angular/core";
import { isEqual as _isEqual } from "lodash";
import { getConnectorViewId } from "../../data-connectivity/helpers/connector-view-id.helper";
import { DataConnectorDto } from "../../data-connectivity/models/data-connector";
import { DataConnectorViewDto } from "../../data-connectivity/models/data-connector-view";
import { DataColumnType } from "../../data-connectivity/models/data-source/data-source-descriptors";
import { Dispatcher } from "../../dispatcher";
import { EntityId } from "../../meta/models/entity";
import { SimpleSingleValueViewConfig } from "../../shared/components/simple-single-value/view-config";
import { DeepUpdate, isEmpty } from "../../ts-utils";
import { isDefined, isNotDefined } from "../../ts-utils/helpers/predicates.helper";
import { Maybe } from "../../ts-utils/models/maybe.type";
import { X_COLUMN_ID } from "../components/simple-components/table-for-connectors/table-rendering-strategies/connector-per-column-strategy";
import { TimeSeriesViewConfig } from "../components/time-series/view-config";
import { SizeInPx } from "../models/size-in-px";
import { DataConnectorDescriptor } from "../models/store/data-connector-descriptor";
import { TableColumnConfig } from "../models/table/table-column-config";
import { TableDataRange } from "../models/table/table-data-range";
import { ROW_BUFFER, ROW_HEIGHT } from "../models/table/table.constants";
import { DataConnectorViewSelector } from "../services/entity-selectors/data-connector-view.selector";
import { DataConnectorActions } from "../store/data-connector/data-connector.actions";
import { UNIT_PERCENTAGE, UNIT_PX } from "./column-width-validation.helper";
import { getPseudoConnectorId, isPseudoConnector } from "./connectors.helper";

const OFFSET_FOR_TABLE_WIDTH = 27;
const HEADER_SUFIX = "-header";
const FOOTER_SUFIX = "-footer";

export function createPseudoConnector(dispatcher: Dispatcher, tableComponentId: EntityId): void {
  const pseudoConnector: DataConnectorDto = new DataConnectorDto({
    title: "Timestamp",
    isDynamicallyCreated: true,
    id: getPseudoConnectorId(tableComponentId),
    properties: { type: DataColumnType.Date.toString() }
  });
  dispatcher.dispatch(
    DataConnectorActions.addOne({ componentId: tableComponentId, connector: pseudoConnector })
  );
}

export function isPseudoConnectorInitiallyCreated(
  connectorViewSelector: DataConnectorViewSelector,
  tableComponentId: EntityId,
  dataDescriptors: DataConnectorDescriptor[],
  dispatcher: Dispatcher
): boolean {
  let isAddedPseudoConnector: boolean = false;
  const pseudoConnectorViewFromReport: Maybe<DataConnectorViewDto> = connectorViewSelector.getById(
    getConnectorViewId(getPseudoConnectorId(tableComponentId))
  );
  const doesPseudoConnectorExist: boolean = dataDescriptors.some(
    (connectorDescriptor: DataConnectorDescriptor) =>
      isPseudoConnector(connectorDescriptor.connector.id)
  );
  if (isDefined(pseudoConnectorViewFromReport) && !doesPseudoConnectorExist) {
    createPseudoConnector(dispatcher, tableComponentId);
    isAddedPseudoConnector = true;
  }
  return isAddedPseudoConnector;
}

export function isViewChanged(changes: SimpleChanges): boolean {
  return (
    isDefined(changes.viewConfig) &&
    isDefined(changes.viewConfig.previousValue) &&
    !_isEqual(changes.viewConfig.previousValue, changes.viewConfig.currentValue)
  );
}

export function areConnectorsChanged(changes: SimpleChanges): boolean {
  return (
    isDefined(changes.dataConnectors) &&
    isDefined(changes.dataConnectors.currentValue) &&
    (!_isEqual(changes.dataConnectors.previousValue, changes.dataConnectors.currentValue) ||
      isViewConfigFirstLoad(changes))
  );
}

function isViewConfigFirstLoad(changes: SimpleChanges): boolean {
  return isDefined(changes.viewConfig) && isNotDefined(changes.viewConfig.previousValue);
}

export function shouldRecreateRowsAndColumns(
  dataConnectors: Maybe<DataConnectorDescriptor[]>,
  viewConfig: Maybe<TimeSeriesViewConfig>
): boolean {
  return isDefined(dataConnectors) && !isEmpty(dataConnectors) && isDefined(viewConfig);
}

export function checkForDeletedDataConnector(
  previousDescriptors: DataConnectorDescriptor[],
  currentDescriptors: DataConnectorDescriptor[]
): boolean {
  return previousDescriptors.length > currentDescriptors.length;
}

export function getTableWidth(size: SizeInPx): number {
  return Number(size.widthInPx.toFixed(2)) - OFFSET_FOR_TABLE_WIDTH;
}

export function getColumnWidthInPx(width: Maybe<string>, tableWidth: number): number {
  if (isDefined(width)) {
    const widthPx: number = Number(width.split(UNIT_PX)[0]);
    if (isNaN(widthPx)) {
      const widthPercentage: number = Number(width.split(UNIT_PERCENTAGE)[0]);
      const widthPercentageToPx: number = Number(((tableWidth * widthPercentage) / 100).toFixed(2));
      return widthPercentageToPx;
    } else {
      return widthPx;
    }
  }
}

export function getUpdatedInlineColumns(
  columns: TableColumnConfig[],
  componentId: EntityId
): DeepUpdate<DataConnectorViewDto>[] {
  return columns.reduce(
    (acc: DeepUpdate<DataConnectorViewDto>[], column: TableColumnConfig, index: number) => {
      const connectorViewId: EntityId =
        column.id !== X_COLUMN_ID
          ? column.id
          : getConnectorViewId(getPseudoConnectorId(componentId));
      const columnWidth: Maybe<number> = (column.cellConfig as SimpleSingleValueViewConfig)
        .columnWidth;

      acc.push({
        id: connectorViewId.toString(),
        changes: {
          column: { width: columnWidth?.toString() ?? "" },
          order: index
        }
      });

      return acc;
    },
    []
  );
}

export function convertToFooterRowIds(columnIds: string[], prefix: string): string[] {
  return columnIds.map((columnId: string) => convertToHeaderOrFooterRowId(prefix, columnId));
}

export function convertToHeaderOrFooterRowId(prefix: string, columnId: string): string {
  return `${prefix}_${columnId}`;
}

export function extractColumnIdFromHeaderOrFooter(id: string): string {
  return id.split("_")[1] ?? "";
}

export function checkForFooterUpdate(changes: SimpleChanges): boolean {
  return (
    !_isEqual(
      changes.viewConfig.previousValue.footerDescriptors,
      changes.viewConfig.currentValue.footerDescriptors
    ) || changes.viewConfig.previousValue.showFooter !== changes.viewConfig.currentValue.showFooter
  );
}

export function generateIdForSelectableItem(label: string, isHeaderItem: boolean = true): string {
  const sufix: string = isHeaderItem ? HEADER_SUFIX : FOOTER_SUFIX;
  return label + sufix;
}

export function calculateTableDataRange(tableHeight: number, offset: number = 0): TableDataRange {
  const rowsToRender: number = Math.floor(tableHeight / ROW_HEIGHT) + ROW_BUFFER;
  return { startIndex: offset * rowsToRender, endIndex: (offset + 1) * rowsToRender - 1 };
}

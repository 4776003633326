import { Component } from "@angular/core";
import { DraggedItemType } from "../../../core/models/drag/dragged-item-type";
import { LOCALIZATION_DICTIONARY } from "../../../i18n/models/localization-dictionary";
import { EditableWidget } from "../../../meta/decorators/editable-widget.decorator";
import { LayoutBuilder } from "../../../meta/decorators/layout-builder.decorator";
import { ComponentCategory } from "../../../meta/models/component-category";
import { View } from "../../decorators/view.decorator";
import { getConfiguredOrForegroundColor } from "../../helpers/color.helper";
import { openLink } from "../../helpers/link-resolution.helper";
import { isContainerWidget } from "../../models/component-type.helper";
import { BUTTON } from "../../models/element-type.constants";
import { BaseComponent } from "../base/base.component";
import { ButtonViewConfig } from "./view-config";

@Component({
  selector: "c-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
@LayoutBuilder(
  ComponentCategory.Glitter,
  BUTTON,
  "icon-button",
  "dashboard-widgets",
  null,
  LOCALIZATION_DICTIONARY.layoutEditor.Button
)
@EditableWidget({ fullName: BUTTON, title: "button" })
export class ButtonComponent extends BaseComponent {
  buttonStyle: Partial<CSSStyleDeclaration> = {};

  @View(ButtonViewConfig)
  public get view(): ButtonViewConfig {
    return this.currentState.view as ButtonViewConfig;
  }

  protected onViewChange(view: ButtonViewConfig): void {
    super.onViewChange(view);
    this.buttonStyle = this.getButtonStyle(view);
  }

  private getButtonStyle(view: ButtonViewConfig): Partial<CSSStyleDeclaration> {
    const { bold, fontColor, css, foregroundColor, fontSize } = view;
    const style: Partial<CSSStyleDeclaration> = {};
    style["fontWeight"] = bold ? "bold" : "normal";
    style["fontSize"] = fontSize + "px";
    style["backgroundColor"] = css.backgroundColor;
    style["color"] = getConfiguredOrForegroundColor(fontColor, foregroundColor);
    return style;
  }

  resolveLink(): void {
    openLink(this);
  }

  canAcceptDrop(): boolean {
    const target = this.draggedComponentService.target;
    return (
      target?.type === DraggedItemType.Component &&
      !isContainerWidget(target?.item?.descriptor?.name)
    );
  }
}

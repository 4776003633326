<div class="customer-breadcrumbs-wrapper">
  <div class="breadcrumbs">
    <mat-icon>home</mat-icon>
    <div>{{ plantName }}</div>
    <mat-icon>chevron_right</mat-icon>
    <div>{{ motorName }}</div>
  </div>
  <div class="current-motor-wrapper">
    <div>{{ motorName }}</div>
  </div>
</div>
